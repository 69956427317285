<template>
    <v-row dense>
        <v-col cols="12" sm="6" lg="4">
            <PartnerCard
                :data="partnerData"
                :loading="partnerLoading"
            />
        </v-col>
        <v-col cols="12" sm="6" lg="4">
            <PartnerCommsCard
                :data="partnerData"
                :loading="partnerLoading"
            />
        </v-col>
        <v-col cols="12" sm="6" lg="4">
            <CustomerStatsCard :rep-id="parseInt($route.params.partnerId)" :loading="!partnerData || partnerLoading" />
        </v-col>
        <v-col cols="12" sm="6" lg="4">
            <AddressCard :data="partnerData" :loading="partnerLoading" />
        </v-col>
        <v-col cols="12" sm="6" lg="4">
            <ContactCard title="Primary Contact Details" :data="partnerData" :loading="partnerLoading" />
        </v-col>
        <v-col cols="12" sm="6" lg="4">
            <PartnerLoginCard :data="partnerData" :loading="partnerLoading" />
        </v-col>
    </v-row>
</template>

<script>
import PartnerCard from "../../components/cards/Partner/PartnerCard";
import PartnerCommsCard from '@/components/cards/Partner/PartnerCommsCard';
import AddressCard from "../../components/cards/AddressCard.vue";
import PartnerLoginCard from '@/components/cards/Partner/PartnerLoginCard';
import ContactCard from "../../components/cards/ContactCard.vue";
import CustomerStatsCard from '../../components/cards/Home/CustomerStatsCard';
import partnerDataMixin from '../../mixins/partnerDataMixin';
import refreshListenerMixin from "../../mixins/refreshListenerMixin";
export default {
    name: 'PartnerSummary',
    mixins: [partnerDataMixin],
    components: {
        CustomerStatsCard,
        ContactCard,
        PartnerLoginCard,
        AddressCard,
        PartnerCommsCard,
        PartnerCard,
    },
};
</script>
