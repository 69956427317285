<template>
    <BasicCard title="Sign-up Process Step" :loading="loading">
        <p class="title text--primary mt-1">Review and Approve New Request</p>
        <p class="text--primary">
            Please review the request and verify the business details provided
            by performing an ABN/ACN search.
        </p>
        <p class="text--primary" v-if="data.refnum">
            Please search for the dealer code in BUS to confirm they are an
            active reseller.
        </p>
        <p class="text--primary" v-if="!data.refnum">
            There is no dealer code with this request. Please search in BUS to
            confirm they are an active reseller.
        </p>
        <p class="text--primary">
            You will also need to verify if this is a legacy (Breeze 1.0)
            partner or not.
        </p>

        <template v-slot:actions>
            <ButtonWithTooltip
                text="Review and Approve"
                tooltip="Approve this sign-up request"
                icon="mdi-check"
                flat
                color="success"
                @click="$emit('approve')"
            />
        </template>
    </BasicCard>
</template>

<script>
import BasicCard from '@/components/cards/templates/BasicCard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
export default {
    name: 'SignupApprovalCard',
    components: { ButtonWithTooltip, BasicCard },
    props: ['data', 'loading'],
    data() {
        return {
            showDialog: false,
        };
    },
    methods: {
        closeModal(refresh) {
            this.showDialog = false;
            if (refresh) {
                this.$emit('update');
            }
        },
    },
};
</script>
