<template>
    <v-row no-gutters>
        <v-col cols="12">
            Hello
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'AdminPortingSummary'
    };
</script>

<style scoped>

</style>