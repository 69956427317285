<template>
    <ComplexDialog
        v-model="visible"
        :busy="busy"
        title="Update Address"
        persistent
    >
        <v-form v-model="isValid">
            <v-row>
                <v-col cols="12">
                    <vuetify-google-autocomplete
                        v-if="!busy"
                        label="Address search"
                        v-model="search"
                        id="id"
                        persistent-hint
                        hint="Type above to search for an address"
                        placeholder="Start typing to search for an address or fill out fields below"
                        enable-geolocation
                        v-on:placechanged="addressSelected"
                        country="au"
                        autofocus
                    >
                    </vuetify-google-autocomplete>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        label="Address line 1"
                        ref="address_line1"
                        name="address_line1"
                        v-model="formData.baddr1"
                        type="text"
                        :rules="rules.requiredOnly"
                        required
                    />
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        label="Address line 2"
                        ref="address_line2"
                        name="address_line2"
                        v-model="formData.address_line_2"
                        type="text"
                    />
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field
                        label="Suburb"
                        ref="suburb"
                        name="suburb"
                        v-model="formData.city"
                        type="text"
                        :rules="rules.requiredOnly"
                        required
                    />
                </v-col>
                <v-col cols="6" sm="3">
                    <v-select
                        :items="arrayData.states"
                        item-text="short"
                        item-value="short"
                        label="State"
                        v-model="formData.state"
                        :rules="rules.requiredOnly"
                    ></v-select>
                </v-col>
                <v-col cols="6" sm="3">
                    <v-text-field
                        label="Postcode"
                        ref="postcode"
                        name="postcode"
                        v-model="formData.zip"
                        type="text"
                        :rules="rules.postcode"
                        required
                    />
                </v-col>
                <!-- v-col cols="12" sm="6" md="6">
                <v-select
                        :items="data.countries"
                        item-text="name"
                        item-value="code"
                        label="Country"
                        v-model="country"
                        required
                        disabled
                ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
                <v-checkbox
                        :disabled="!isValid"
                        @change="confirmAddress($event)"
                        v-model="confirmed"
                        label="The address details above are correct"
                ></v-checkbox>
            </v-col-->
            </v-row>
        </v-form>
        <template v-slot:actions>
            <ButtonWithTooltip
                text="Update"
                class="primary"
                icon="mdi-save"
                flat
                :disabled="!isValid"
                @click="save"
            />
        </template>
    </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
export default {
    name: 'UpdateSignupAddress',
    components: { ButtonWithTooltip, ComplexDialog },
    mixins: [apiMixin, dialogMixin],
    props: {
        data: {},
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            busy: false,
            formData: JSON.parse(JSON.stringify(this.data)),
            isValid: false,
            confirmed: false,
            messageBody: '',
            search: '',
            arrayData: {
                countries: [{ name: 'Australia', code: 'AU' }],
                states: [
                    { name: 'Australian Capital Territory', short: 'ACT' },
                    { name: 'New South Wales', short: 'NSW' },
                    { name: 'Northern Territory', short: 'NT' },
                    { name: 'Queensland', short: 'QLD' },
                    { name: 'South Australia', short: 'SA' },
                    { name: 'Tasmania', short: 'TAS' },
                    { name: 'Victoria', short: 'VIC' },
                    { name: 'Western Australia', short: 'WA' },
                ],
            },
            rules: {
                postcode: [
                    (v) => !!v || 'Required',
                    (v) =>
                        /^(?:[1-9]\d|0[8-9])\d{2}$/.test(v) ||
                        'Invalid postcode',
                ],
                requiredOnly: [(v) => !!v || 'Required'],
            },
        };
    },
    methods: {
        addressSelected: function (addressData) {
            this.formData.baddr1 =
                (addressData.street_number
                    ? addressData.street_number + ' '
                    : '') + addressData.route;
            this.formData.address_line_2 = '';
            this.formData.city = addressData.locality;
            this.formData.state = addressData.administrative_area_level_1;
            this.formData.zip = addressData.postal_code;
        },
        edit: function () {},
        async save() {
            //this.$emit('busy', true);
            this.busy = true;
            const response = await this.Api.send('post',
                'signup/' + this.formData.signup_id + '/updateaddress',
                {
                    baddr1: this.formData.baddr1,
                    address_line_2: this.formData.address_line_2,
                    state: this.formData.state,
                    city: this.formData.city,
                    zip: this.formData.zip,
                }
            );
            if (response.success) {
                this.busy = false;
                this.$emit('save');
                this.visible = false;
            } else {
                this.busy = false;
            }
        },
    },
    watch: {
        visible: function (value) {
            if (this.visible!==value) this.visible = value;
            if (value) {
                this.formData = JSON.parse(JSON.stringify(this.data));
            }
        },
    },
};
</script>
