<template>
    <span>{{ value | bytes }}</span>
</template>

<script>
export default {
    name: 'Bytes',
    filters: {
        bytes: function (bytes) {
            if (bytes === 0) return '0 Bytes';

            const k = 1024;
            const sizes = [
                'Bytes',
                'KB',
                'MB',
                'GB',
                'TB',
                'PB',
                'EB',
                'ZB',
                'YB',
            ];

            const i = Math.floor(Math.log(bytes) / Math.log(k));

            return (
                parseFloat((bytes / Math.pow(k, i)).toFixed(1)) + ' ' + sizes[i]
            );
        },
    },
    props: {
        value: {
            type: Number,
            default: 0,
        },
    },
};
</script>
