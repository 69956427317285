<template>
    <BasicCard
        :loading="loading || busy"
        :no-skeleton="!!data"
    >
        <template v-slot:feature>
            <v-chip class="primary ml-2">User</v-chip>
            <v-icon right x-large>mdi-account</v-icon>
        </template>
        <p class="title text--primary mt-1" v-if="data.username">{{ data.username }}</p>
        <v-row dense v-for="(value, name) in data" :key="name">
          <v-col cols="5">
            <span class="font-weight-bold">{{titleCase(name)}}</span>
          </v-col>
          <v-col cols="7">
            {{value}}
          </v-col>
        </v-row>
      <template v-slot:actions>
        <ButtonWithTooltip
            :disabled="!data.twofactor_enabled_at"
            icon="mdi-minus-circle"
            text="Disable 2FA"
            tooltip="Disable two-factor authentication"
            flat
            @click="showDisable2FADialog=true"
        />
        <ButtonWithTooltip
            icon="mdi-lock-reset"
            text="Reset Password"
            tooltip="Reset user's password"
            flat
            @click="showPasswordResetDialog=true"
        />
      </template>
      <SimpleDialog @click="resetPassword(data)" title="Reset Password"  v-model="showPasswordResetDialog" :loading="busy">
        <p>This process will send the user a Password reset email.</p>
        <p>Do you wish to proceed?</p>
      </SimpleDialog>
      <SimpleDialog @click="showPasswordResetSuccessDialog=false" okOnly title="Reset Password successful"  v-model="showPasswordResetSuccessDialog">
        <p>Process completed. Reset password email has been sent to the user.</p>
      </SimpleDialog>

      <SimpleDialog @click="disable2FA(data)" title="Disable 2FA"  v-model="showDisable2FADialog" :loading="busy" yesColor="error">
        <p>This process will disabled the user's 2FA security.</p>
        <p>Do you wish to proceed?</p>
      </SimpleDialog>
      <SimpleDialog @click="showDisable2FASuccessDialog=false" okOnly title="Disable 2FA"  v-model="showDisable2FASuccessDialog">
        <p>Process completed. 2FA disabled successfully.</p>
      </SimpleDialog>

    </BasicCard>
</template>

<script>
import BasicCard from '@/components/cards/templates/BasicCard';
import ButtonWithTooltip from "../../pieces/ButtonWithTooltip";
import SimpleDialog from "../../dialogs/templates/SimpleDialog";
import apiMixin from "../../../mixins/apiMixin";
import refreshListenerMixin from "../../../mixins/refreshListenerMixin";
import dialogMixin from "../../../mixins/dialogMixin";
export default {
    name: 'UserCard',
    components: {SimpleDialog, ButtonWithTooltip, BasicCard },
    mixins: [apiMixin, refreshListenerMixin,dialogMixin],
    data: () => ({
      showPasswordResetDialog: false,
      showPasswordResetSuccessDialog: false,
      showDisable2FASuccessDialog: false,
      showDisable2FADialog: false,
      busy: false,
    }),
    props: {
        data: {
            type: Object,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
      titleCase(string) {
        let sentence = string.toLowerCase().split("_");
        for (let i = 0; i < sentence.length; i++) {
          sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
        }
        return sentence.join(" ");
      },
      async resetPassword(data)
      {
        this.showPasswordResetDialog = false;
        this.busy = true;
        let response = await this.Api.send('put', 'users/' + this.data.id + '/reset-password');

        if (response.success) {
          document.dispatchEvent(new CustomEvent('refreshRequested'));
          this.showPasswordResetSuccessDialog = true;
        }
        this.busy = false;
      },
      async disable2FA(data)
      {
        this.showDisable2FADialog = false;
        this.busy = true;
        let response = await this.Api.send('put', 'users/' + this.data.id + '/twofactor/disable');
        if (response.success) {
          document.dispatchEvent(new CustomEvent('refreshRequested'));
          this.showDisable2FASuccessDialog = true;
        }
        this.busy = false;
      }
    }
};
</script>

<style scoped></style>
