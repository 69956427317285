<template>
  <v-container fluid>
      <v-card class="pa-2 mb-2">
        <v-list class="pt-1">
          <h3>Customers</h3>
            <v-list-item link :to="{ name: 'ReportCustomerChurn'}">
              <v-list-item-content>
                <v-list-item-title>Customer Churn Report</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link :to="{ name: 'ReportNewCustomerGrowth'}">
              <v-list-item-content>
                <v-list-item-title>New Customer Growth Report</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          <v-list-item link :to="{ name: 'ReportNewNbnCustomerGrowth'}">
            <v-list-item-content>
              <v-list-item-title>New NBN Customer Growth Report</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'ReportFastestCustomerRevenueGrowth'}">
            <v-list-item-content>
              <v-list-item-title>Fastest Customer Revenue Growth Report</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{ name: 'CustomerReportRevenueByService'}">
            <v-list-item-content>
              <v-list-item-title>Customer Revenue By Service Report</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <h3>Partners</h3>

          <v-list-item link :to="{ name: 'ReportFastestPartnerRevenueGrowth'}">
            <v-list-item-content>
              <v-list-item-title>Fastest Partner Revenue Growth Report</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          
          <v-list-item link :to="{ name: 'PartnerReportRevenueByService'}">
            <v-list-item-content>
              <v-list-item-title>Partner Revenue By Service Report</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <h3>Other</h3>


          <v-list-item link :to="{ name: 'ReportRevenueByService'}">
            <v-list-item-content>
              <v-list-item-title>Revenue By Service Report</v-list-item-title>
            </v-list-item-content>
          </v-list-item>





         <!-- <v-list-item link :to="{ name: 'PaymentAnalysisReport'}">
            <v-list-item-content>
              <v-list-item-title>Payment Analysis Report</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->

            <v-divider />
        </v-list>
      </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'ReportList',
  mixins: [],
  data: () => ({

  }),
  components: {},
  created() {
    //triggered when the url is reloaded. Either via initial page or manually editing the URL.
    this.$store.commit('data', {name: 'breadCrumbsAddon', data:null});
  },
};
</script>
