<template>
    <BasicCard :loading="loading" title="Losing Carrier Info">
        <v-row dense v-if="data">
            <v-col cols="5">
                <span class="font-weight-bold">Losing Carrier</span>
            </v-col>
            <v-col cols="7">
                {{ data.losing_carrier }}
            </v-col>
            <v-col cols="5">
                <span class="font-weight-bold">Account Number</span>
            </v-col>
            <v-col cols="7">
                {{ data.losing_carrier_account }}
            </v-col>
            <v-col cols="5">
                <span class="font-weight-bold">Provider</span>
            </v-col>
            <v-col cols="7">
                {{ data.provider }}
            </v-col>
            <v-col cols="5">
                <span class="font-weight-bold">Provider Ref</span>
            </v-col>
            <v-col cols="7">
                {{ data.provider_ticketid }}
            </v-col>
        </v-row>
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
export default {
    name: 'PortingLosingCarrierCard',
    components: { BasicCard },
    props: ['data', 'loading'],
    data() {
        return {
            showDialog: false,
        };
    },
    methods: {
        closeModal(refresh) {
            this.showDialog = false;
            if (refresh) {
                this.$emit('update');
            }
        },
    },
};
</script>
