<template>
    <SimpleDialog
        v-model="visible"
        :loading="busy"
        title="Send Partner Application"
        persistent
        @close="$emit('close')"
        @click="sent"

    >
        <p>Mark this partner application as sent?</p>
    </SimpleDialog>
</template>

<script>
import SimpleDialog from '../templates/SimpleDialog';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '@/mixins/dialogMixin';
export default {
    name: 'SentPartnerAgreement',
    mixins: [apiMixin, dialogMixin],
    components: { SimpleDialog },
    data() {
        return {
            busy: false,
            formData: JSON.parse(JSON.stringify(this.data)),
            display: true,
            signup_id: null,
            Api: [],
        };
    },
    props: {
        data: {},
    },
    created() {
        if (this.data.signup_id) {
            this.signup_id = this.data.signup_id.toString();
        }
    },
    methods: {
        async sent() {
            this.busy = true;
            const response = await this.Api.send('post',
                'signup/' + this.signup_id + '/sent',
                {}
            );
            if (response.success) {
                this.busy = false;
                this.$emit('save');
                this.visible = false;
            } else {
                this.busy = false;
            }
        },
    },
    watch: {
        visible: function (value) {
            if (value) {
                this.formData = JSON.parse(JSON.stringify(this.data));
            }
        },
    },
};
</script>
