<template>
    <BasicCard>
        <p class="overline mb-1">Support Info</p>
        <p class="title text--primary">Breeze Connect</p>
        <p class="body-1">
            1300 1 BREEZE (1300 127 339)<br />
            08 7078 5300<br />
            <a href="mailto:help@breezeconnect.com.au"
                >help@breezeconnect.com.au</a
            >
        </p>
        <template v-slot:feature>
            <HelpButton @click="help" />
        </template>
        <template v-slot:actions>
            <v-btn icon href="tel:1300127339">
                <v-icon>mdi-phone</v-icon>
            </v-btn>

            <v-btn icon href="mailto:help@breezeconnect.com.au">
                <v-icon>mdi-email</v-icon>
            </v-btn>

            <v-btn
                icon
                href="https://help.breezeconnect.com.au/"
                target="_blank"
            >
                <v-icon>mdi-web</v-icon>
            </v-btn>
        </template>
    </BasicCard>
</template>

<script>
import HelpButton from '../pieces/HelpButton';
import BasicCard from './templates/BasicCard';
export default {
    name: 'SupportCard',
    components: { BasicCard, HelpButton },
    methods: {
        help: function () {
            console.log('clicked help!');
        },
    },
};
</script>

<style scoped></style>
