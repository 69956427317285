<template>
    <v-container fluid>
        <v-row dense>
            <!-- non-dismissable alert only shows if status is blocked -->
            <v-col cols="12" v-if="!loading && data">
                <Alert
                    v-if="data.current_status === 'Completed'"
                    type="success"
                    message="This port is completed."
                />
            </v-col>
            <!-- paf details card -->
            <v-col cols="12" sm="6" lg="4">
                <PortingCard :loading="loading || !data" :data="data" />
            </v-col>
            <v-col cols="12" sm="6" lg="4">
                <PortingLosingCarrierCard
                    :loading="loading || !data"
                    :data="data"
                />
            </v-col>
            <v-col cols="12" sm="6" lg="4">
                <PortingCustomerCard :loading="loading || !data" :data="data" />
            </v-col>
            <v-col cols="12">
                <PaginationCard
                    title="Number List"
                    :loading="loading || numbers.loading"
                    :data="numbers"
                    :limit="numbers.limit"
                    :page="numbers.page"
                    v-on:updatePage="updatePage"
                    v-on:updateLimit="updateLimit"
                >
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th v-if="$vuetify.breakpoint.smAndUp">
                                        ID
                                    </th>
                                    <th>Number</th>
                                    <th>Breeze Status</th>
                                    <th>Carrier Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, i) in numbers.data" :key="i">
                                    <td v-if="$vuetify.breakpoint.smAndUp">
                                        {{ item.id }}
                                    </td>
                                    <td><span v-html="item.number" /></td>
                                    <td>Unknown</td>
                                    <td>Unknown</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </PaginationCard>
            </v-col>
            <v-col cols="12">
                <BasicCard :loading="loading" title="Carrier Communications">
                    <v-timeline dense>
                        <v-timeline-item
                            v-for="(item, i) in communications"
                            :key="i"
                            :icon="getIcon(item)"
                            :color="getColor(item)"
                        >
                            <v-card>
                                <div
                                    v-if="item.status"
                                    class="float-right ma-2"
                                >
                                    <v-chip
                                        label
                                        :color="getColor(item)"
                                        v-if="item"
                                        >{{ item.status }}</v-chip
                                    >
                                </div>
                                <!-- v-card-title>{{item.status}}<span v-if="!item.status">Unknown</span></v-card-title -->
                                <v-card-text
                                    class="text--primary"
                                    v-if="item.message"
                                    v-html="item.message"
                                ></v-card-text>
                                <v-card-text v-if="!item.message"
                                    >No message</v-card-text
                                >
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <span class="text--secondary mx-2"
                                        >Added
                                        <LocalDateTime
                                            :value="item.occurred"
                                            relative
                                        /><span v-if="item.internal_person">
                                            by {{ item.internal_person }}</span
                                        ></span
                                    >
                                </v-card-actions>
                            </v-card>
                        </v-timeline-item>
                    </v-timeline>
                </BasicCard>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Alert from '@/components/pieces/Alert';
import BasicCard from '../../../components/cards/templates/BasicCard';
import LocalDateTime from '../../../components/pieces/LocalDateTime';
import PortingCard from '../../../components/cards/Porting/PortingCard';
import PortingLosingCarrierCard from '../../../components/cards/Porting/PortingLosingCarrierCard';
import PortingCustomerCard from '../../../components/cards/Porting/PortingCustomerCard';
import PaginationCard from '../../../components/cards/templates/PaginationCard';
import apiMixin from '../../../mixins/apiMixin';
export default {
    name: 'PortingDetail',
    mixins: [apiMixin],
    data: () => ({
        loading: true,
        data: [],
        numberData: [],
        numbers: {
            data: [],
            count: 0,
            pages: 1,
            limit: 10,
            page: 1,
            loading: false,
        },
        communications: [],
    }),
    components: {
        PaginationCard,
        PortingCustomerCard,
        PortingLosingCarrierCard,
        PortingCard,
        LocalDateTime,
        BasicCard,
        Alert,
    },
    methods: {
        async get(id) {
            this.loading = true;
            const response = await this.Api.send('post',
                'admin/porting/' + id + '/info'
            );
            this.loading = false;
            if (response.success) {
                this.updateData(response.data);
            } else {
                this.updateFailed(response.error, response.errorDetail);
            }
        },
        update() {
            this.get(this.$route.params.id);
        },
        updatePage(data) {
            this.numbers.page = data;
            this.updateNumbers();
        },
        updateLimit(data) {
            this.numbers.limit = data;
            this.numbers.page = 1;
            this.updateNumbers();
        },
        updateData(data) {
            this.data = data.paf_details;
            this.communications = data.paf_communications;
            this.numberData = data.paf_numbers;
            this.updateNumbers();
        },
        updateNumbers() {
            this.numbers.loading = true;
            this.numbers.count = this.numberData.length;
            this.numbers.pages = Math.ceil(
                this.numbers.count / this.numbers.limit
            );
            this.numbers.data = this.getNumbers(
                this.numbers.limit,
                this.numbers.page
            );
            this.numbers.loading = false;
        },
        getNumbers(limit, page) {
            var start = limit * (page - 1);
            var end = limit * page;
            var pageData = [];
            for (var i = 0; i < this.numbers.count; i++) {
                if (i >= start && i < end) {
                    pageData.push(this.numberData[i]);
                }
            }
            return pageData;
        },
        updateFailed() {
            this.data = [];
        },
        getIcon(item) {
            if (!item.status) return 'mdi-help';
            if (item.status === 'Completed') return 'mdi-check';
            if (item.status.indexOf('Date Assigned') !== -1)
                return 'mdi-calendar-check';
            if (item.status.indexOf('Awaiting') !== -1)
                return 'mdi-clock-outline';
            return 'mdi-alert';
        },
        getColor(item) {
            if (item.status === 'Completed') return 'success';
            if (item.status.indexOf('Date Assigned') !== -1) return 'primary';
            if (item.status.indexOf('Awaiting') !== -1) return 'warning';
            return 'error';
        },
    },
    created() {
        this.update();
    },
};
</script>
