var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"pa-2 mb-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('DatePicker',{attrs:{"label":"Start date","show-clear":"","hide-details":"","dense":"","loading":_vm.busy,"type":"month"},model:{value:(_vm.filter.start_date),callback:function ($$v) {_vm.$set(_vm.filter, "start_date", $$v)},expression:"filter.start_date"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('DatePicker',{attrs:{"label":"End date","show-clear":"","hide-details":"","dense":"","loading":_vm.busy,"type":"month"},model:{value:(_vm.filter.end_date),callback:function ($$v) {_vm.$set(_vm.filter, "end_date", $$v)},expression:"filter.end_date"}})],1),_c('v-col',{attrs:{"cols":"6","md":"2","lg":"1"}},[_c('v-btn',{attrs:{"outlined":"","block":"","height":"40","loading":_vm.busy},on:{"click":function($event){_vm.csvTog = false; _vm.runReport()}}},[_vm._v("Run")])],1),_c('v-col',{attrs:{"cols":"6","md":"2","lg":"1"}},[_c('v-btn',{attrs:{"outlined":"","block":"","height":"40","loading":_vm.busy},on:{"click":function($event){_vm.csvTog = true; _vm.runReport()}}},[_vm._v("CSV")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"fixed-header":"","height":"100vh","items":(_vm.responseData != null && _vm.responseData.records != null) ? _vm.responseData.records : [],"items-per-page":50,"item-key":"i_customer","loading":_vm.busy,"loading-text":"Processing results... Please wait"},scopedSlots:_vm._u([{key:"item.call-revenue",fn:function(ref){
var item = ref.item;
return [_c('Currency',{attrs:{"value":item['call-revenue']}})]}},{key:"item.subscription-revenue",fn:function(ref){
var item = ref.item;
return [_c('Currency',{attrs:{"value":item['subscription-revenue']}})]}},{key:"item.did-revenue",fn:function(ref){
var item = ref.item;
return [_c('Currency',{attrs:{"value":item['did-revenue']}})]}},{key:"item.total-revenue",fn:function(ref){
var item = ref.item;
return [_c('Currency',{attrs:{"value":item['total-revenue']}})]}},{key:"item.graph",fn:function(ref){
var item = ref.item;
return [_c('v-sparkline',{attrs:{"value":item['graph'],"width":"400","height":"70","padding":"20","line-width":"2","stroke-linecap":"round","smooth":""}})]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }