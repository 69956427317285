export default {
    data: () => ({
        linkBase: process.env.VUE_APP_API_BASE || '/api/',
        previousQueryString:null,
        responseData:null,
        // booleanFilterKeys:[], //set in parent.
    }),
    methods: {
        buildBreadCrumbs( ){
            let breadCrumbs = [
                {
                    name:"AdminReportList",
                    label: "Reports",
                    exact:true, //helpes with a disabled parent. Might be a better way to do this. ESP when the logic get more complicated.
                }
            ];
            this.$store.commit('data', {name: 'breadCrumbsAddon', data: breadCrumbs});
        },
        updateRouteWithFilters() {
            let query = {};
            let update = false;

            let filterKeys = Object.keys(this.filter);

            let self = this;
            let routeValue;
            let filterValue;
            let booleanToggle;
            let booleanKeys = this.booleanFilterKeys ?? [];

            filterKeys.forEach(function (key) {
                if (self.filter[key] && self.filter[key] != null){
                    if(typeof self.filter[key] == 'boolean'){
                        if(self.filter[key] === true){
                            query[key] = 'true';
                        }else if(self.filter[key] === false){
                            query[key] = 'false';
                        }
                    }else{
                        query[key] = self.filter[key];
                    }
                }

                routeValue = self.$route.query[key];
                filterValue = self.filter[key];
                booleanToggle = false;

                /*
                Convert URLs values from String to Boolean.
                Note that this is setup to handle null values as false.
                This would need to be updated if this logic was not desired.
                Also note that the reports are set in thos manner.
                EG boolean toggles are on or or off.
                 */

                if(booleanKeys.includes(key)) {
                    booleanToggle = true;
                }

                if(booleanToggle){
                    if (routeValue == 'true') {
                        routeValue = true;
                    } else {
                        routeValue = false;
                    }
                }

                if (filterValue !== null) {
                    if(filterValue !== routeValue) {
                        update = true;
                    }
                }else{ //filter is null
                    if(booleanToggle) {
                        if(routeValue === true) {
                            //filter is null and route value is true (boolean)
                            update = true;
                        }
                    }else{
                        if(routeValue != null) {
                            //filter is null and route value is not null
                            update = true;
                        }
                    }
                }
            });

            if (update) {
                //not this triggers the route water which will run the report.
                this.$router.replace({ ...this.$router.currentroute, query: query });
            }
        },
        updateFiltersFromQuery( ){
            let booleanKeys = this.booleanFilterKeys ?? [];
            for (const property in this.$route.query) {
                if(booleanKeys.includes(property)) {
                    if(this.$route.query[property] == 'true' || this.$route.query[property] == '1'){
                        this.filter[property] = true;
                    }else{
                        this.filter[property] = false;
                    }
                }else {
                    this.filter[property] = this.$route.query[property];
                }
            }
        },
        formatDate(date, withDate) {
            var d = new Date(date),
              month = '' + (d.getMonth() + 1),
              day = '' + d.getDate(),
              year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            if(withDate === true){
                return [year, month, day].join('-');
            }else{
                return [year, month].join('-');
            }
        },
        subtractMonth(date) {
            let d = date.getDate();
            date.setMonth(date.getMonth() - 1);
            if (date.getDate() != d) {
                date.setDate(0);
            }
            return date;
        },

        /**
         * This process will pull the report data and/or return the CSV file for download.
         * The idea here is if the filters have been updated when requesting a CSV the report is also run.
         * This prevents confusion when the report is showing different values than the CSV.
         * This can happen is the report is presented, then filters changed and the CSV button is pressed.
         * This will detect new filters and will also run the report as well as download the CSV.
         * @param params
         * @returns {Promise<void>}
         */
        async updateReport(params, url) {
            this.busy = true;

            //we run the query check here before we add in the csv specific values
            let queryString = Object.keys(params).map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
            }).join('&');

            let queryCheck = this.previousQueryString == queryString;
            this.previousQueryString = queryString;

            if(this.csvTog){
                params.csv = this.csvTog;
                params['session-token'] = this.$store.state.session.token;
            }

            let csvQueryString = Object.keys(params).map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
            }).join('&');

            //if the csvTog is not set or there is a new query string. We run the normal report.
            if(!this.csvTog || !queryCheck){
                this.responseData = []; //reset the values to support timeout. Else same values are shown on timeout.
                const response = await this.Api.send('get', url+'?'+queryString);
                if (response.success) {
                    this.responseData = response.data;
                }
            }

            this.busy = false;

            if(this.csvTog){
                this.busy = false;
                const csvurl = this.linkBase + url+'?'+csvQueryString;
                window.location.href = csvurl;
            }
        },
    },

    created() {
        //triggered when the url is reloaded. Either via initial page or manually editing the URL.
        this.buildBreadCrumbs( );
        this.updateFiltersFromQuery( );
        this.runReportOnNewPage( );
    },
};