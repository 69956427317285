<template>
    <BasicCard
        :loading="loading"
        class="background_accent"
    >
        <template v-slot:feature>
            <v-chip class="primary ml-2">Port</v-chip>
            <v-icon right x-large>mdi-phone-settings</v-icon>
        </template>
        <p class="title text--primary" v-if="data">{{ data.description }}</p>
        <v-row dense v-if="data">
            <v-col cols="5">
                <span class="font-weight-bold">PAF ID</span>
            </v-col>
            <v-col cols="7">
                {{ data.id }}
            </v-col>
            <v-col cols="5">
                <span class="font-weight-bold">Port Type</span>
            </v-col>
            <v-col cols="7">
                <span v-if="data.port_type === 'A'">Cat A</span>
                <span v-if="data.port_type === 'C'">Cat C</span>
                <span v-if="data.port_type === 'S'">Special</span>
            </v-col>
            <v-col cols="5">
                <span class="font-weight-bold">Status</span>
            </v-col>
            <v-col cols="7">
                {{ data.current_status }}
            </v-col>
        </v-row>
        <template v-slot:actions> </template>
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
export default {
    name: 'PortingCard',
    components: { BasicCard },
    props: ['data', 'loading'],
    data() {
        return {
            showDialog: false,
        };
    },
    methods: {
        closeModal(refresh) {
            this.showDialog = false;
            if (refresh) {
                this.$emit('update');
            }
        },
    },
};
</script>
