<template>
    <ComplexDialog
        v-model="visible"
        :busy="busy"
        title="Upload Partner Agreement"
        persistent
    >
        <v-form ref="form" v-model="isValid">
            <v-row dense>
                <v-col cols="12">
                    <Alert
                        type="info"
                        message="Please attach signed partner agreement. You can attach multiple files if required."
                    />
                </v-col>
                <v-col cols="12">
                    <v-file-input
                        ref="files"
                        filled
                        label="Attach Files *"
                        v-model="files"
                        required
                        multiple
                        :disabled="busy || bypass"
                        hint="Click here to choose file(s) to upload"
                        persistent-hint
                    ></v-file-input>
                </v-col>
                <v-col cols="12">
                    <v-checkbox
                        class="pa-0 ma-0"
                        v-model="bypass"
                        label="I DO NOT want to upload any files - I want to bypass this workflow step entirely"
                    ></v-checkbox>
                </v-col>
            </v-row>
        </v-form>
        <template v-slot:actions>
            <v-btn text v-on:click="visible = false">
                <v-icon left>mdi-cancel</v-icon>
                Cancel
            </v-btn>
            <v-btn
                v-if="!bypass"
                :loading="busy"
                text
                v-on:click="send"
                color="primary"
                :disabled="!files || busy"
            >
                <v-icon left>mdi-upload</v-icon>
                Upload
            </v-btn>
            <v-btn
                v-if="bypass"
                :loading="busy"
                text
                v-on:click="skip"
                color="primary"
                :disabled="busy"
            >
                <v-icon left>mdi-forward</v-icon>
                Bypass
            </v-btn>
        </template>
    </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import Alert from '../../pieces/Alert';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
export default {
    name: 'UploadPartnerAgreement',
    components: { ComplexDialog, Alert },
    mixins: [apiMixin, dialogMixin],
    data() {
        return {
            busy: false,
            formData: JSON.parse(JSON.stringify(this.data)),
            display: true,
            isValid: false,
            bypass: false,
            files: null,
        };
    },
    props: {
        data: {},
    },
    created() {
        this.bypass = false;
    },
    methods: {
        async send(event) {
            event.preventDefault();
            this.busy = true;
            //let files = this.$refs.files.files;
            let formData = new FormData();
            for (var i = 0; i < this.files.length; i++) {
                let file = this.files[i];
                formData.append('files[' + i + ']', file);
            }
            const response = await this.Api.send('post',
                'signup/' + this.formData.signup_id + '/upload',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            if (response.success) {
                this.busy = false;
                this.$emit('save');
                this.visible = false;
            } else {
                this.busy = false;
            }
        },
        async skip(event) {
            event.preventDefault();
            this.busy = true;
            const response = await this.Api.send('post',
                'signup/' + this.formData.signup_id + '/sign',
                {}
            );
            if (response.success) {
                this.busy = false;
                this.$emit('save');
                this.visible = false;
            } else {
                this.busy = false;
            }
        },
    },
    watch: {
        visible: function (value) {
            if (this.visible!==value) this.visible = value;
            if (value) {
                this.formData = JSON.parse(JSON.stringify(this.data));
                this.bypass = false;
                this.files = null;
            }
        },
    },
};
</script>
