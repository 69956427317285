<template>
  <v-container fluid>
    <v-card class="pa-2 mb-2">
      <v-row>
        <v-col cols="12" md="3">
          <DatePicker
            v-model="filter.start_date"
            label="Invoice issued start date"
            show-clear
            hide-details
            dense
            :loading="busy"
            type="date"
          />
        </v-col>
        <v-col cols="12" md="3">
          <DatePicker
            v-model="filter.end_date"
            label="Invoice issued end date"
            show-clear
            hide-details
            dense
            :loading="busy"
            type="date"
          />
        </v-col>
        <v-col cols="12" md="3" >
          <v-text-field
            hide-details
            outlined
            clearable
            dense
            label="Customer name or i_customer"
            :disabled="busy"
            v-model="filter.name_keyword"
            @keydown.enter="runReport"
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-btn
            outlined
            block
            height="40"
            :loading="busy"
            @click="runReport"
          >Run</v-btn>
        </v-col>
      </v-row>
    </v-card>

    <v-card class="pa-2 mb-2" v-if="apiResponse != null && apiResponse.customer != null">
      <strong>Customer: </strong> {{apiResponse.customer.name}} ({{apiResponse.customer.companyname}} | {{apiResponse.customer.i_customer}})
    </v-card>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          fixed-header
          height="100vh"
          :items="(apiResponse != null) ? apiResponse : []"
          :items-per-page="50"
          class="elevation-1"
          item-key="i_customer"
          :loading="busy"
          loading-text="Processing results... Please wait"
        >
          <template v-slot:[`item.i_customer`]="{ item }">
            <strong v-if="item['i_customer'] == 0">{{item['i_customer']}}</strong>
            <span v-else>{{item['i_customer']}}</span>
          </template>
          <template v-slot:[`item.customer-name`]="{ item }">
            <strong v-if="item['i_customer'] == 0">{{item['customer-name']}}</strong>
            <span v-else>{{item['customer-name']}}</span>
          </template>
          <template v-slot:[`item.invoice-count`]="{ item }">
            <strong v-if="item['i_customer'] == 0">{{item['invoice-count']}}</strong>
            <span v-else>{{item['invoice-count']}}</span>
          </template>
          <template v-slot:[`item.paid-before-due-count`]="{ item }">
            <strong v-if="item['i_customer'] == 0">{{item['paid-before-due-count']}}</strong>
            <span v-else>{{item['paid-before-due-count']}}</span>
          </template>
          <template v-slot:[`item.paid-before-suspension-count`]="{ item }">
            <strong v-if="item['i_customer'] == 0">{{item['paid-before-suspension-count']}}</strong>
            <span v-else>{{item['paid-before-suspension-count']}}</span>
          </template>
          <template v-slot:[`item.paid-after-suspension-count`]="{ item }">
            <strong v-if="item['i_customer'] == 0">{{item['paid-after-suspension-count']}}</strong>
            <span v-else>{{item['paid-after-suspension-count']}}</span>
          </template>
          <template v-slot:[`item.graph`]="{ item }">
            <v-sparkline
              v-if="item['graph'] != null && item['graph'].length > 1"
              :value="item['graph']"
              width="400"
              height="70"
              padding="20"
              line-width="2"
              stroke-linecap="round"
              smooth
            >
            </v-sparkline>
            <span v-else>N/A</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import DatePicker from "../../../components/pieces/Forms/DatePicker.vue";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import AdminReportMixin from "../../../mixins/AdminReportMixin";
export default {
  name: 'PaymentAnalysisReport',
  mixins: [GlobalHelperMixin,AdminReportMixin],
  data: () => ({
    filter: {
      start_date:null,
      end_date:null,
      name_keyword:null,
    },
    busy: false,
    apiResponse:null,
    headers: [
      {
        text: 'i_customer',
        align: 'start',
        sortable: true,
        value: 'i_customer',
      },
      {
        text: 'Name',
        sortable: true,
        value: 'customer-name',
      },
      {
        text: 'Trend (seconds before/after due)',
        sortable: false,
        value: 'graph',
      },
      {
        text: 'Invoice count',
        sortable: true,
        value: 'invoice-count',
      },
      {
        text: 'Paid before due count',
        sortable: true,
        value: 'paid-before-due-count',
      },
      {
        text: 'Paid before suspension count',
        sortable: true,
        value: 'paid-before-suspension-count',
      },
      {
        text: 'Paid after suspension count',
        sortable: true,
        value: 'paid-after-suspension-count',
      },
    ],
  }),
  components: {
    DatePicker,
  },
  methods: {
    runReport( ){
      if(this.filter.start_date == null || this.filter.end_date == null){
        this.showGlobalErrorMessage('Invalid Start/End dates');
        return;
      }
      this.updateRouteWithFilters( );
      this.update( );
    },
    async update() {
      this.busy = true;
      this.apiResponse = null;
      this.items = []; //reset the values to support timeout. Else same values are shown on timeout.
      const params = {
        start_date:this.filter.start_date,
        end_date:this.filter.end_date,
      };
      if(this.filter.name_keyword != null){
        params.name_keyword=this.filter.name_keyword
      }

      let queryString = Object.keys(params).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
      }).join('&');

      const response = await this.Api.send('get', 'admin/reporting/payment-analysis-report?'+queryString);
      this.busy = false;
      if (response.success) {
        this.apiResponse = response.data.customers;
        if(response.data.totals) {
          this.apiResponse.unshift({
            "i_customer": 0,
            "customer-name": "Totals",
            "invoice-count": response.data.totals['invoice-count'],
            "paid-before-due-count": response.data.totals['paid-before-due-count'],
            "paid-before-suspension-count": response.data.totals['paid-before-suspension-count'],
            "paid-after-suspension-count": response.data.totals['paid-after-suspension-count'],
          });
        }
      } else {
        //this.updateFailed(response.error, response.errorDetail);
      }
    },
    updateStartDate(data) {
      this.filter.start_date = data;
    },
    updateEndDate(data) {
      this.filter.end_date = data;
    },

    runReportOnNewPage( ){
      if(!this.$route.query.start_date || !this.$route.query.end_date){
        let end_date = new Date( );
        end_date = this.subtractMonth(end_date);
        end_date = this.subtractMonth(end_date);

        let start_date = new Date( );
        start_date = this.subtractMonth(start_date);
        start_date = this.subtractMonth(start_date);
        start_date = this.subtractMonth(start_date);

        this.updateStartDate(this.formatDate(start_date,true));
        this.updateEndDate(this.formatDate(end_date,true));
      }
      this.runReport();
    }
  },
  computed:{
    start_date( ){
      if(this.filter.start_date){
        return this.filter.start_date;
      }else{
        return null;
      }
    }
  },
};
</script>
