<template>
    <BasicCard :title="(this.repId ? 'Partner ' : '') + 'Customer Stats'" :loading="loading || busy">
        <p class="display-1 text--primary">
            <FormattedNumber :value="data.total" hide-tooltip />
            <small> customers</small>
        </p>
        <v-row dense>
            <v-col cols="6">
                <span class="font-weight-bold">Active</span>
            </v-col>
            <v-col cols="6">
                <FormattedNumber :value="data.active" hide-tooltip />
            </v-col>
            <v-col cols="6">
                <span class="font-weight-bold">Suspended</span>
            </v-col>
            <v-col cols="6">
                <FormattedNumber :value="data.suspended" hide-tooltip />
            </v-col>
            <v-col cols="12">
                <v-alert v-if="data.suspended" type="error" text dense >
                    <v-row align="center">
                        <v-col class="grow">
                            {{ data.suspended }} customer<span v-if="data.suspended>1">s are</span><span v-else> is</span> suspended!
                        </v-col>
                        <v-col class="shrink pa-1" v-if="toSuspended">
                            <v-btn
                                :to="toSuspended"
                                color="error"
                                class="ma-2"
                            >View</v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-col>
        </v-row>

        <template v-slot:actions>
            <ButtonWithTooltip
                v-if="$route.name==='AdminHome'"
                tooltip="Refresh data"
                icon="mdi-refresh"
                text="Refresh"
                flat
                @click="update"
            />
            <ButtonWithTooltip
                    v-if="$route.name!=='AdminHome'"
                    tooltip="View Customers"
                    icon="mdi-eye"
                    text="View"
                    flat
                    :to="$store.state.user.user_type === 'admin' ? 'AdminPartnerCustomers' : 'PartnerCustomers'"
            />
        </template>
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import FormattedNumber from '../../pieces/FormattedNumber';
import apiMixin from '../../../mixins/apiMixin';
import refreshListenerMixin from '../../../mixins/refreshListenerMixin';
export default {
    name: 'CustomerStatsCard',
    mixins: [apiMixin, refreshListenerMixin],
    components: { FormattedNumber, ButtonWithTooltip, BasicCard },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        repId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            busy: false,
            data: [],
        };
    },
    methods: {
        async update() {
            this.data = [];
            this.busy = true;
            let params = this.repId ? { 'i_rep' : this.repId } : {}
            const response = await this.Api.send('post','customers/totals', params);
            if (response.success) {
                this.data = response.data;
            } else {
                this.data = [];
            }
            this.busy = false;
        },
    },
    watch: {
        repId() {
            this.update();
        }
    },
    computed: {
        toSuspended: function() {

            switch (this.$route.name) {
                case 'AdminHome':
                    return { name: 'CustomerList', query: { status: 'S' } };
                case 'AdminPartnerSummary':
                    return { name: 'AdminPartnerCustomers', query: { status: 'S' } };
                case 'PartnerHome':
                    return { name: 'PartnerCustomers', query: { status: 'S' } };
                default:
                    return null;
            }
        }
    },
    created() {
        this.update();
    },
};
</script>
