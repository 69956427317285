<template>
    <ComplexDialog
        v-model="visible"
        :busy="busy"
        title="Complete New Partner Sign-Up"
        persistent
    >
        <v-form ref="form" v-model="isValid">
            <v-row dense>
                <v-col cols="12">
                    <v-text-field
                        filled
                        label="Email Address *"
                        v-model="formData.email"
                        required
                        :disabled="busy"
                    ></v-text-field>
                    <v-text-field
                        filled
                        label="Subject *"
                        v-model="subject"
                        required
                        :disabled="busy"
                    ></v-text-field>
                    <v-textarea
                        filled
                        label="Message *"
                        v-model="messageBody"
                        required
                        :disabled="busy"
                    ></v-textarea>
                </v-col>
                <v-col cols="12" v-if="formData.contact_mobile">
                    <v-checkbox
                        :disabled="busy"
                        class="pa-0 ma-0"
                        v-model="sms"
                        :label="
                            'Also send a welcome SMS to ' +
                            formData.contact_mobile
                        "
                    ></v-checkbox>
                </v-col>
                <v-col cols="12" v-if="sms">
                    <v-text-field
                        filled
                        label="SMS *"
                        v-model="smsBody"
                        required
                        height="2"
                        :disabled="busy"
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-form>
        <template v-slot:actions>
            <v-btn text v-on:click="visible = false">
                <v-icon left>mdi-cancel</v-icon>
                Cancel
            </v-btn>
            <v-btn
                :loading="busy"
                text
                v-on:click="send"
                color="primary"
                :disabled="busy"
            >
                <v-icon left>mdi-send</v-icon>
                Complete and Send
            </v-btn>
        </template>
    </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
export default {
    name: 'CompleteSignup',
    components: { ComplexDialog },
    mixins: [apiMixin, dialogMixin],
    data() {
        return {
            busy: false,
            formData: JSON.parse(JSON.stringify(this.data)),
            display: true,
            isValid: false,
            sms: true,
            smsBody: '',
            messageBody: '',
            subject: '',
        };
    },
    props: {
        data: {},
    },
    created() {
        if (this.data.i_commission_plan) {
            this.form.i_commission_plan =
                this.data.i_commission_plan.toString();
        }
    },
    methods: {
        async send(event) {
            event.preventDefault();
            this.busy = true;
            const response = await this.Api.send('post',
                'signup/' + this.formData.signup_id + '/complete',
                {
                    email: this.formData.email,
                    subject: this.subject,
                    message: this.messageBody,
                    sms: this.sms ? this.smsBody : null,
                }
            );
            if (response.success) {
                this.busy = false;
                this.$emit('save');
                this.visible = false;
            } else {
                this.busy = false;
            }
        },
    },
    watch: {
        visible: function (value) {
            if (this.visible!==value) this.visible = value;
            if (value) {
                this.formData = JSON.parse(JSON.stringify(this.data));
                this.sms = !!this.formData.contact_mobile;
                this.subject = 'Welcome to Breeze Connect';
                this.messageBody =
                    'Dear ' +
                    this.data.firstname +
                    ',\n\n' +
                    'Thanks for your recent application to become a Breeze Connect Partner. We have now processed and approved your application!\n\n' +
                    'In order to complete the sign up you will need to conduct the Onboarding process which can be found at \n\n' +
                    'https://portal.breezeconnect.com.au/onboarding?obt={{token}}\n\n' +
                    'If you have questions or difficulties, please contact us on 1300 127 339 or at help@breezeconnect.com.au.\n\n' +
                    'Kind regards,\n\nBreeze Connect';
                this.smsBody =
                    'Hi ' +
                    this.data.firstname +
                    '. We have processed your Breeze Connect partner application! Please check your e-mail for more info. Breeze Connect';
            }
        },
    },
};
</script>
