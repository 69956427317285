<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12">
                <PaginationCard
                    :page="filter.page"
                    :data="data"
                    :limit="filter.limit"
                    :loading="loading"
                    v-on:updateLimit="updateLimit"
                    v-on:updatePage="updatePage"
                >
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">ID</th>
                                    <th class="text-left">Detail</th>
                                    <th class="text-left">URL</th>
                                    <th class="text-center">Response</th>
                                    <th class="text-center">Date/Time</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(item, i) in data.data"
                                    :key="i"
                                    @click="showDialog(item)"
                                >
                                    <td>{{ item.porta_log_id }}</td>
                                    <td>{{ item.detail }}</td>
                                    <td>
                                        <span>{{ item.path }}</span
                                        ><br />
                                    </td>
                                    <td class="text-center">
                                        <v-chip
                                            v-if="item.success"
                                            small
                                            color="green"
                                            text-color="white"
                                        >
                                            OK ({{ item.response_code }})
                                        </v-chip>
                                        <v-chip
                                            v-if="!item.success"
                                            small
                                            color="red"
                                            text-color="white"
                                        >
                                            <span v-if="item.response_code > 0"
                                                >Error ({{
                                                    item.response_code
                                                }})</span
                                            >
                                            <span
                                                v-if="item.response_code === 0"
                                                >Error (unknown)</span
                                            >
                                        </v-chip>
                                    </td>
                                    <td class="text-center">
                                        <LocalDateTime
                                            :value="item.datetime"
                                            relative
                                        />
                                    </td>
                                    <td class="text-right">
                                        <ButtonWithTooltip
                                            text="Details"
                                            persistent
                                            flat
                                            small
                                            tooltip="Show full API transaction details"
                                            @click="showDetail(item)"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </PaginationCard>
            </v-col>
        </v-row>
        <SimpleDialogOld
            ok-only
            title="Porta API Transaction Details"
            @close="showDialog = false"
            :visible="showDialog"
            max-width="750"
        >
            <v-divider />
            <v-row>
                <v-col cols="4" class="font-weight-bold"> Log ID </v-col>
                <v-col cols="8">
                    {{ dialogData.porta_log_id }}
                </v-col>
                <v-col cols="4" class="font-weight-bold"> Detail </v-col>
                <v-col cols="8">
                    {{ dialogData.detail }}
                </v-col>
                <v-col cols="4" class="font-weight-bold"> Date/Time </v-col>
                <v-col cols="8">
                    <LocalDateTime :value="dialogData.datetime" />
                </v-col>
            </v-row>
            <v-divider />
            <v-row>
                <v-col cols="4" class="font-weight-bold"> Base URL </v-col>
                <v-col cols="8">
                    {{ dialogData.base }}
                </v-col>
                <v-col cols="4" class="font-weight-bold"> Path </v-col>
                <v-col cols="8">
                    {{ dialogData.path }}
                </v-col>
                <v-col cols="4" class="font-weight-bold">
                    Request Params
                </v-col>
                <v-col cols="8">
                    {{ dialogData.params }}
                </v-col>
            </v-row>
            <v-divider />
            <v-row>
                <v-col cols="4" class="font-weight-bold"> Response Code </v-col>
                <v-col cols="8">
                    <span v-if="dialogData.response_code">{{
                        dialogData.response_code
                    }}</span>
                    <span v-if="!dialogData.response_code"
                        ><IconWithTooltip small tooltip="No response received"
                    /></span>
                </v-col>
                <v-col
                    cols="4"
                    class="font-weight-bold"
                    v-if="!dialogData.success"
                >
                    Error Detail
                </v-col>
                <v-col cols="8" v-if="!dialogData.success">
                    {{ dialogData.error_detail }}
                </v-col>
            </v-row>
        </SimpleDialogOld>
    </v-container>
</template>

<script>
// @ is an alias to /src
import LocalDateTime from '@/components/pieces/LocalDateTime';
import PaginationCard from '@/components/cards/templates/PaginationCard';
import SimpleDialogOld from '../../../components/dialogs/templates/SimpleDialogOld';
import IconWithTooltip from '../../../components/pieces/IconWithTooltip';
import ButtonWithTooltip from '../../../components/pieces/ButtonWithTooltip';
import apiMixin from '../../../mixins/apiMixin';
export default {
    name: 'PortaLogs',
    mixins: [apiMixin],
    data: () => ({
        filter: {
            limit: 25,
            page: 1,
        },
        loading: false,
        data: [],
        dialogData: [],
        showDialog: false,
    }),
    components: {
        ButtonWithTooltip,
        IconWithTooltip,
        SimpleDialogOld,
        PaginationCard,
        LocalDateTime,
    },
    methods: {
        async update() {
            this.loading = true;
            const response = await this.Api.send('post','syslog/porta', {
                limit: this.filter.limit,
                page: this.filter.page,
            });
            this.loading = false;
            if (response.success) {
                this.updateData(response.data);
            } else {
                this.updateFailed(response.error, response.errorDetail);
            }
        },
        updatePage(data) {
            this.filter.page = data;
            this.update();
        },
        updateLimit(data) {
            this.filter.page = 1;
            this.filter.limit = data;
            this.update();
        },
        updateData(data) {
            this.data = data;
        },
        updateFailed() {
            this.data = [];
        },
        showDetail(data) {
            this.dialogData = data;
            this.showDialog = true;
        },
    },
    created() {
        this.update();
    },
};
</script>
