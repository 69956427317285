<template>
    <ComplexDialog
        v-model="visible"
        :busy="busy"
        title="Review Signed Partner Agreement"
        persistent
    >
        <Alert
            type="info"
            message="Once you complete this step you will not be able to edit any other details."
            class="mb-4"
        />
        <v-form ref="form" v-model="isValid">
            <v-row dense>
                <v-col cols="12">
                    <v-checkbox
                        :disabled="busy"
                        class="pa-0 ma-0"
                        v-model="confirmed1"
                        :label="
                            'The agreement is signed by ' +
                            data.firstname +
                            ' ' +
                            data.lastname
                        "
                    ></v-checkbox>
                </v-col>
                <v-col cols="12">
                    <v-checkbox
                        :disabled="busy"
                        class="pa-0 ma-0"
                        v-model="confirmed2"
                        :label="
                            'The agreement is the ' +
                            (data.legacy_customer ? 'legacy' : 'standard') +
                            ' partner agreement'
                        "
                    ></v-checkbox>
                </v-col>
                <v-col cols="12">
                    <v-checkbox
                        :disabled="busy"
                        class="pa-0 ma-0"
                        v-model="confirmed3"
                        :label="'There are no alterations made to the contract'"
                    ></v-checkbox>
                </v-col>
            </v-row>
        </v-form>
        <template v-slot:actions>
            <v-btn text v-on:click="visible = false">
                <v-icon left>mdi-cancel</v-icon>
                Cancel
            </v-btn>
            <v-btn
                :loading="busy"
                text
                v-on:click="send"
                color="success"
                :disabled="!confirmed1 || !confirmed2 || !confirmed3 || busy"
            >
                <v-icon left>mdi-check</v-icon>
                Approve
            </v-btn>
        </template>
    </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import Alert from '../../pieces/Alert';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
export default {
    name: 'ReviewPartnerAgreement',
    components: { Alert, ComplexDialog },
    mixins: [apiMixin, dialogMixin],
    data() {
        return {
            busy: false,
            formData: JSON.parse(JSON.stringify(this.data)),
            display: true,
            isValid: false,
            confirmed1: false,
            confirmed2: false,
            confirmed3: false,
        };
    },
    props: {
        data: {},
    },
    created() {
        if (this.data.i_commission_plan) {
            this.form.i_commission_plan =
                this.data.i_commission_plan.toString();
        }
    },
    methods: {
        async send(event) {
            event.preventDefault();
            this.busy = true;
            const response = await this.Api.send('post',
                'signup/' + this.formData.signup_id + '/review',
                {}
            );
            if (response.success) {
                this.busy = false;
                this.$emit('save');
                this.visible = false;
            } else {
                this.busy = false;
            }
        },
    },
    watch: {
        visible: function (value) {
            if (this.visible!==value) this.visible = value;
            if (value) {
                this.formData = JSON.parse(JSON.stringify(this.data));
                this.confirmed1 = false;
                this.confirmed2 = false;
                this.confirmed3 = false;
                this.files = null;
            }
        },
    },
};
</script>
