<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-row class="px-4">
                        <v-col cols="12" md="6">
                            <v-text-field
                                :loading="loading"
                                hide-details
                                outlined
                                dense
                                label="Description"
                                v-model="filter.description"
                                clearable
                                @click:clear="clearDescription"
                                @keydown.enter="update"
                            />
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <v-menu offset-y>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-on="on"
                                        outlined
                                        block
                                        :loading="loading"
                                    >
                                        Status: {{ filter.status }}
                                        <v-icon>mdi-chevron-down</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list>
                                        <v-list-item
                                            v-for="(
                                                item, index
                                            ) in filter.statusOptions"
                                            :key="index"
                                            @click="updateStatus(item)"
                                        >
                                            <v-list-item-title>{{
                                                item.option
                                            }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-list>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <v-btn
                                class="secondary"
                                block
                                outlined
                                @click="update"
                                :loading="loading"
                                >Search</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <PaginationCard
                    :page="filter.page"
                    :data="data"
                    :limit="filter.limit"
                    :loading="loading"
                    v-on:updateLimit="updateLimit"
                    v-on:updatePage="updatePage"
                >
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>PAF ID</th>
                                    <th>Description</th>
                                    <th v-if="$vuetify.breakpoint.smAndUp">
                                        Port Type
                                    </th>
                                    <th>Status</th>
                                    <th v-if="$vuetify.breakpoint.smAndUp">
                                        Port Date
                                    </th>
                                    <th>Last Updated</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, i) in data.data" :key="i">
                                    <td class="font-weight-medium">
                                        {{ item.id }}
                                    </td>
                                    <td>
                                        <router-link
                                            link
                                            :to="'/admin/porting/carrier/' + item.id"
                                            class="font-weight-medium"
                                        >
                                            {{ item.description }}
                                        </router-link>
                                    </td>

                                    <td v-if="$vuetify.breakpoint.smAndUp">
                                        <span v-if="item.port_type === 'A'"
                                            >Cat A</span
                                        >
                                        <span v-else-if="item.port_type === 'C'"
                                            >Cat C</span
                                        >
                                        <span v-if="item.port_type === 'S'"
                                            >Special</span
                                        >
                                    </td>
                                    <td>
                                        {{ item.current_status }}
                                        <span v-if="!item.current_status"
                                            >Unknown</span
                                        >
                                    </td>
                                    <td v-if="$vuetify.breakpoint.smAndUp">
                                        <span
                                            v-if="
                                                contains(
                                                    'Date Assigned',
                                                    item.current_status
                                                )
                                            "
                                        >
                                            <LocalDateTime
                                                :value="item.port_date"
                                                show-time
                                            />
                                        </span>
                                        <span
                                            v-else-if="
                                                !contains(
                                                    'Date Assigned',
                                                    item.current_status
                                                )
                                            "
                                            >N/A</span
                                        >
                                    </td>
                                    <td>
                                        <LocalDateTime
                                            :value="item.last_updated"
                                            relative
                                        />
                                    </td>
                                </tr>
                                <tr v-if="!data.data">
                                    <td colspan="6" class="text-center">
                                        No items found.
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </PaginationCard>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import PaginationCard from '@/components/cards/templates/PaginationCard';
import LocalDateTime from '../../../components/pieces/LocalDateTime';
import apiMixin from '../../../mixins/apiMixin';
export default {
    name: 'PortingList',
    mixins: [apiMixin],
    data: () => ({
        filter: {
            limit: 25,
            page: 1,
            statusOptions: [
                { option: 'Active', value: 'ACTIVE' },
                { option: 'Date Booked', value: 'BOOKED' },
                { option: 'Complete', value: 'COMPLETE' },
                { option: 'Inactive', value: 'INACTIVE' },
                { option: 'All', value: 'ALL' },
            ],
            status: 'ACTIVE',
            description: '',
        },
        loading: false,
        data: [],
    }),
    components: {
        LocalDateTime,
        PaginationCard,
    },
    filters: {
        localdate: function (value) {
            if (!value) return '';
            value = value.toString();
            return new Date(value + 'Z');
        },
    },
    methods: {
        async update() {
            this.loading = true;
            this.updateRouteWithFilters();
            let params = { limit: this.filter.limit, page: this.filter.page };
            if (this.filter.status !== null) {
                params.status = this.filter.status;
            }
            if (
                this.filter.description !== null &&
                this.filter.description !== ''
            ) {
                params.description = this.filter.description;
            }
            const response = await this.Api.send('post','admin/porting/list', params);
            this.loading = false;
            if (response.success) {
                this.updateData(response.data);
            } else {
                this.updateFailed(response.error, response.errorDetail);
            }
        },
        clearDescription() {
            this.filter.description = '';
            this.update();
        },
        contains(needle, haystack) {
            return haystack.toString().includes(needle);
        },
        updatePage(data) {
            this.filter.page = data;
            this.update();
        },
        updateLimit(data) {
            this.filter.limit = data;
            this.filter.page = 1;
            this.update();
        },
        updateHidden(data) {
            this.filter.hidden = data;
            this.filter.page = 1;
            this.update();
        },
        updateData(data) {
            this.data = data;
        },
        updateStatus(data) {
            this.filter.status = data.value;
            this.filter.page = 1;
            this.update();
        },
        updateFailed() {
            this.data = [];
        },
        updateRouteWithFilters() {
            let query = {};
            let update = false;
            if (
                (this.filter.description === '' &&
                    this.$route.query.description) ||
                (this.filter.status === 'ACTIVE' && this.$route.query.status)
            ) {
                update = true;
            }
            if (this.filter.description !== '') {
                query.description = this.filter.description;
                if (this.$route.query.description !== this.filter.description)
                    update = true;
            }
            if (this.filter.status !== 'ACTIVE') {
                query.status = this.filter.status;
                if (this.$route.query.status !== this.filter.status)
                    update = true;
            }
            if (update) {
                this.$router.push({ name: 'PortingList', query: query });
            }
        },
    },
    watch: {
        $route(to) {
            this.filter.description = to.query.description
                ? to.query.description
                : '';
            this.filter.status = to.query.status ? to.query.status : 'ACTIVE';
            this.update();
        },
    },
    created() {
        this.filter.description = this.$route.query.description
            ? this.$route.query.description
            : '';
        this.filter.status = this.$route.query.status
            ? this.$route.query.status
            : 'ACTIVE';
        this.update();
    },
};
</script>
