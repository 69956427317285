<template>
    <span>
        <span v-if="!data.completed && !data.deleted">
            <v-chip v-if="!data.approved" small color="warning">
                Pending Review
            </v-chip>
            <v-chip
                v-if="data.approved && !data.agreement_sent"
                small
                color="primary"
            >
                Approved
            </v-chip>
            <v-chip
                v-if="data.agreement_sent && !data.agreement_signed"
                small
                color="primary"
            >
                Agreement Sent
            </v-chip>
            <v-chip
                v-if="data.agreement_signed && !data.agreement_reviewed"
                small
                color="info"
            >
                Agreement Signed
            </v-chip>
            <v-chip
                v-if="data.agreement_reviewed && !data.completed"
                small
                color="info"
            >
                Pending Creation
            </v-chip>
        </span>
        <v-chip v-if="data.completed" small color="success"> Completed </v-chip>
        <v-chip v-if="data.deleted" small color="error"> Rejected </v-chip>
    </span>
</template>

<script>
export default {
    name: 'SignupStatus',
    props: ['data'],
};
</script>
