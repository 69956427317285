<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12">
                <FaxToMailListCard
                    :small="$vuetify.breakpoint.xsOnly"
                    :filter="filter"
                    @update="updateFilter"
                    show-filters
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import FaxToMailListCard from "../../components/cards/FaxToMail/FaxToMailListCard";
import filterMixin from '../../mixins/filterMixin';
export default {
    name: 'FaxToMailList',
    mixins: [filterMixin],
    data: () => ({
        filter: {
            active: 'Y',
            id: null,
            note:null,
            limit: 10,
            page: 1,
        },
    }),
    components: {
        FaxToMailListCard,
    }
};
</script>
