<template>
    <v-card
        :loading="loading"
        :disabled="loading"
        height="100%"
        class="flexcard"
    >
        <v-card-text class="pb-0">
            <p class="overline mb-2">Attached Files</p>
        </v-card-text>

        <v-simple-table class="grow">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">ID</th>
                        <th class="text-left">Filename</th>
                        <th class="text-left">Size</th>
                        <th class="text-left">Uploaded</th>
                        <th class="text-left">Actions</th>
                    </tr>
                </thead>
                <tbody v-if="data.files">
                    <tr v-for="(item, i) in data.files" :key="i">
                        <td>{{ item.file_id }}</td>
                        <td>
                            <a
                                :href="
                                    linkBase +
                                    'signup/files/' +
                                    item.file_id +
                                    '/download?session-token=' +
                                    $store.state.session.token
                                "
                                >{{ item.original_filename }}</a
                            >
                        </td>
                        <td><Bytes :value="item.size" /></td>
                        <td>
                            <LocalDateTime :value="item.uploaded" relative />
                        </td>
                        <td>
                            <ButtonWithTooltip
                                icon="mdi-delete"
                                color="error"
                                tooltip="Delete this file"
                                @click="deleteFile(item.file_id)"
                            />
                        </td>
                    </tr>
                </tbody>
                <tbody v-if="!data.files">
                    <tr>
                        <td class="text-center" colspan="5">No files found</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-divider />
        <SimpleDialogOld
            :loading="busy"
            :visible="del.visible"
            @close="del.visible = false"
            @click="confirmDelete"
            title="Delete file?"
        >
            Are you sure you want to delete this file?
        </SimpleDialogOld>
    </v-card>
</template>

<script>
import LocalDateTime from '@/components/pieces/LocalDateTime';
import Bytes from '@/components/pieces/Bytes';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import SimpleDialogOld from '../../dialogs/templates/SimpleDialogOld';
import apiMixin from '../../../mixins/apiMixin';
export default {
    name: 'SignupFilesCard',
    mixins: [apiMixin],
    components: { SimpleDialogOld, ButtonWithTooltip, Bytes, LocalDateTime },
    props: ['data', 'loading'],
    data() {
        return {
            del: {
                id: null,
                visible: false,
            },
            uploadVisible: false,
            linkBase: process.env.VUE_APP_API_BASE || '/api/',
            busy: false,
        };
    },
    methods: {
        deleteFile(id) {
            this.del.id = id;
            this.del.visible = true;
        },
        async confirmDelete() {
            this.busy = true;
            const response = await this.Api.send('post',
                'signup/' +
                    this.data.signup_id +
                    '/file/' +
                    this.del.id +
                    '/delete',
                {}
            );
            if (response.success) {
                this.busy = false;
                this.$emit('save');
                this.del.visible = false;
            } else {
                this.busy = false;
            }
        },
        update(refresh) {
            this.showDialog = false;
            if (refresh) {
                this.$emit('update');
            }
        },
    },
};
</script>
