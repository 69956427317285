<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12">
                <UserSessionsCard
                    :user-id="parseInt(this.$route.params.userId)"
                    :filter="filter"
                    @update="updateFilter"
                    show-filters
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import filterMixin from '../../../mixins/filterMixin';
import UserSessionsCard from '../../../components/cards/User/UserSessionsCard';
export default {
    name: 'UserSessions',
    mixins: [filterMixin],
    data: () => ({
        loading: true,
        filter: {
            page: 1,
            limit: 10,
            active: null
        },
    }),
    components: {
        UserSessionsCard,
    },

};
</script>
