<template>
    <v-container fluid>
        <v-col cols="12">
            <PaginationCard
                :page="filter.page"
                :data="data"
                :limit="filter.limit"
                :loading="loading"
                v-on:updateLimit="updateLimit"
                v-on:updatePage="updatePage"
            >
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">To</th>
                                <th class="text-left">Body</th>
                                <th class="text-left">From</th>
                                <th class="text-left">Sent</th>
                                <th class="text-left">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, i) in data.data" :key="i">
                                <td>{{ item.to_number }}</td>
                                <td>{{ item.body }}</td>
                                <td>{{ item.from_number }}</td>
                                <td>
                                    <LocalDateTime
                                        :value="item.datetime"
                                        relative
                                    />
                                </td>
                                <td>
                                    <v-btn
                                        text
                                        small
                                        v-bind:href="
                                            'https://www.twilio.com/console/sms/logs/' +
                                            item.twilio_sms_id
                                        "
                                        target="_blank"
                                        >Twilio Log</v-btn
                                    >
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </PaginationCard>
        </v-col>
    </v-container>
</template>
<script>
import LocalDateTime from '../../../components/pieces/LocalDateTime';
import PaginationCard from '../../../components/cards/templates/PaginationCard';
import apiMixin from '../../../mixins/apiMixin';
export default {
    name: 'SMSLogs',
    mixins: [apiMixin],
    data: () => ({
        filter: {
            limit: 10,
            page: 1,
            limitOptions: [10, 20, 50, 100],
        },
        loading: false,
        data: [],
    }),
    components: {
        PaginationCard,
        LocalDateTime,
    },
    methods: {
        async update() {
            this.loading = true;
            const response = await this.Api.send('post','syslog/sms', {
                limit: this.filter.limit,
                page: this.filter.page,
            });
            this.loading = false;
            if (response.success) {
                this.updateData(response.data);
            } else {
                this.updateFailed(response.error, response.errorDetail);
            }
        },
        updatePage(data) {
            this.filter.page = data;
            this.update();
        },
        updateLimit(data) {
            this.filter.limit = data;
            this.filter.page = 1;
            this.update();
        },
        updateData(data) {
            this.data = data;
        },
        updateFailed() {
            this.data = [];
        },
    },
    created() {
        this.update();
    },
};
</script>
