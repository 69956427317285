<template>
    <BasicCard title="DID Statistics" :loading="loading || busy">
        <p class="display-1 text--primary">
            <FormattedNumber :value="data.used" hide-tooltip />
            <small> active DIDs</small>
        </p>
        <v-row dense>
            <v-col cols="6">
                <span class="font-weight-bold">Available</span>
            </v-col>
            <v-col cols="6">
                <router-link
                    :to="{ name: 'DIDInventory', query: { usage: 'I' } }"
                >
                    <FormattedNumber :value="data.idle" hide-tooltip />
                </router-link>
            </v-col>
            <v-col cols="6">
                <span class="font-weight-bold">Frozen</span>
            </v-col>
            <v-col cols="6">
                <router-link
                    :to="{ name: 'DIDInventory', query: { usage: 'Z' } }"
                >
                    <FormattedNumber :value="data.frozen" hide-tooltip />
                </router-link>
            </v-col>
            <v-col cols="6">
                <span class="font-weight-bold">Total</span>
            </v-col>
            <v-col cols="6">
                <router-link :to="{ name: 'DIDInventory' }">
                    <FormattedNumber :value="data.total" hide-tooltip />
                </router-link>
            </v-col>
        </v-row>

        <template v-slot:actions>
            <ButtonWithTooltip
                tooltip="Refresh data"
                icon="mdi-refresh"
                text="Refresh"
                flat
                @click="update"
            />
        </template>
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import FormattedNumber from '../../pieces/FormattedNumber';
import apiMixin from '../../../mixins/apiMixin';
export default {
    name: 'DIDStatsCard',
    mixins: [apiMixin],
    components: { FormattedNumber, ButtonWithTooltip, BasicCard },
    props: ['loading'],
    data() {
        return {
            busy: false,
            data: [],
        };
    },
    methods: {
        async update() {
            this.data = [];
            this.busy = true;
            const response = await this.Api.send('post','dids/totals');
            if (response.success) {
                this.data = response.data;
            } else {
                this.data = [];
            }
            this.busy = false;
        },
    },
    created() {
        this.update();
    },
};
</script>
