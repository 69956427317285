var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-row',{staticClass:"px-4"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('DatePicker',{attrs:{"label":"Period Start After","clearable":"","dense":"","loading":_vm.isBusy},model:{value:(_vm.searchFilters.start),callback:function ($$v) {_vm.$set(_vm.searchFilters, "start", $$v)},expression:"searchFilters.start"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('DatePicker',{attrs:{"label":"Period End Before","clearable":"","dense":"","loading":_vm.isBusy},model:{value:(_vm.searchFilters.end),callback:function ($$v) {_vm.$set(_vm.searchFilters, "end", $$v)},expression:"searchFilters.end"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"hide-details":"","outlined":"","dense":"","label":"Status","disabled":_vm.isBusy,"items":_vm.statusOptions,"item-text":"option","item-value":"value"},model:{value:(_vm.searchFilters.status),callback:function ($$v) {_vm.$set(_vm.searchFilters, "status", $$v)},expression:"searchFilters.status"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{staticClass:"secondary",attrs:{"block":"","outlined":"","loading":_vm.isBusy},on:{"click":_vm.updateSearch}},[_vm._v("Update")])],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"secondary",attrs:{"block":"","outlined":"","loading":_vm.isBusy},on:{"click":_vm.create}},on),[_vm._v("+")])]}}])},[_c('span',[_vm._v("Create next report")])])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('PaginationCardV2',{attrs:{"page":_vm.searchFilters.page,"totalRecords":_vm.listData.total_records,"totalPages":_vm.listData.total_pages,"limit":_vm.searchFilters.limit,"currentPageTotal":(_vm.listData.results) ? _vm.listData.results.length : 0,"loading":_vm.isBusy,"no-skeleton":false},on:{"updatedPagination":_vm.updatedPagination}},[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v("Id")]),_c('th',{staticClass:"text-center"},[_vm._v("Period")]),_c('th',{staticClass:"text-center"},[_vm._v("Validated")]),_c('th',{staticClass:"text-center"},[_vm._v("Paid Out")]),_c('th',{staticClass:"text-center"})])]),_c('v-slide-x-transition',{attrs:{"tag":"tbody","hide-on-leave":"","group":""}},[(_vm.listData.results && _vm.listData.results.length === 0)?_c('tr',{key:"nodata"},[_c('td',{staticClass:"text-center",attrs:{"colspan":"100%"}},[_vm._v(" No results to show. ")])]):_vm._e(),_vm._l((_vm.listData.results),function(item){return _c('tr',{key:item.id},[_c('td',{staticClass:"text-center"},[_c('router-link',{staticClass:"font-weight-medium",attrs:{"to":{
                                      name: 'PartnerCommissionReportView',
                                      params: {
                                          id: item.id,
                                      },
                                  }}},[_vm._v(_vm._s(item.id))])],1),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.started_at)+" - "+_vm._s(item.ended_at)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.validated_at)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.completed_paid_out_at)+" ")]),_c('td',{staticClass:"text-center"},[_c('router-link',{attrs:{"to":{
                                      name: 'PartnerCommissionReportView',
                                      params: {
                                          id: item.id,
                                      },
                                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                  var href = ref.href;
                                  var navigate = ref.navigate;
return [_c('v-btn',{attrs:{"href":href,"block":"","outlined":"","loading":_vm.isBusy},on:{"click":navigate}},[_vm._v("Open")])]}}],null,true)})],1)])})],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }