<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12">
                <TrunkListCard
                    :small="$vuetify.breakpoint.xsOnly"
                    :filter="filter"
                    @update="updateFilter"
                    show-filters
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import TrunkListCard from '@/components/cards/Trunk/TrunkListCard';
import filterMixin from '../../mixins/filterMixin';
export default {
    name: 'TrunkList',
    mixins: [filterMixin],
    data: () => ({
        filter: {
            active: 'Y',
            id: null,
            note:null,
            limit: 10,
            page: 1,
        },
    }),
    components: {
        TrunkListCard,
    }
};
</script>
