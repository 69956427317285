<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12">
                <PaginationCard
                    :page="filter.page"
                    :data="data"
                    :limit="filter.limit"
                    :loading="loading"
                    v-on:updateLimit="updateLimit"
                    v-on:updatePage="updatePage"
                >
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th v-if="$vuetify.breakpoint.smAndUp">
                                        Cron ID
                                    </th>
                                    <th>Script Name</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, i) in data.data" :key="i">
                                    <td v-if="$vuetify.breakpoint.smAndUp">
                                        {{ item.cron_id }}
                                    </td>
                                    <router-link
                                        tag="td"
                                        link
                                        :to="
                                            '/admin/system/cron/' + item.cron_id
                                        "
                                        style="cursor: pointer"
                                    >
                                        <span class="font-weight-medium">{{
                                            item.scriptname
                                        }}</span>
                                    </router-link>
                                    <td>
                                        <span v-if="item.running">
                                            <v-progress-circular
                                                class="mr-2"
                                                :width="3"
                                                :size="20"
                                                color="primary"
                                                indeterminate
                                            />Running (started
                                            <LocalDateTime
                                                :value="item.last_executed"
                                                relative
                                            />)
                                        </span>
                                        <span
                                            v-if="
                                                item.disabled && !item.running
                                            "
                                        >
                                            <v-icon color="error" class="mr-2"
                                                >mdi-alert-octagon</v-icon
                                            >Disabled
                                        </span>
                                        <span
                                            v-if="
                                                !item.disabled &&
                                                !item.running &&
                                                item.last_completed
                                            "
                                        >
                                            <v-icon color="success" class="mr-2"
                                                >mdi-check-circle</v-icon
                                            >Last completed
                                            <LocalDateTime
                                                v-if="item.last_completed"
                                                :value="item.last_completed"
                                                relative
                                            />
                                        </span>
                                        <span
                                            v-if="
                                                !item.disabled &&
                                                !item.running &&
                                                !item.last_completed
                                            "
                                        >
                                            <v-icon color="warning" class="mr-2"
                                                >mdi-alert</v-icon
                                            >Never completed
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </PaginationCard>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import PaginationCard from '@/components/cards/templates/PaginationCard';
import LocalDateTime from '@/components/pieces/LocalDateTime';
import apiMixin from '../../../mixins/apiMixin';
export default {
    name: 'CronList',
    mixins: [apiMixin],
    data: () => ({
        filter: {
            limit: 25,
            page: 1,
            statusOptions: [
                { option: 'All', value: null },
                { option: 'Active', value: 0 },
                { option: 'Disabled', value: 1 },
            ],
            status: { option: 'All', value: null },
        },
        loading: false,
        data: [],
    }),
    components: {
        LocalDateTime,
        PaginationCard,
    },
    methods: {
        async update() {
            this.loading = true;
            let params = { limit: this.filter.limit, page: this.filter.page };
            if (this.filter.status.value !== null) {
                params.status = this.filter.status.value;
            }
            const response = await this.Api.send('post','admin/cron/list', params);
            this.loading = false;
            if (response.success) {
                this.updateData(response.data);
            } else {
                this.updateFailed(response.error, response.errorDetail);
            }
        },
        updatePage(data) {
            this.filter.page = data;
            this.update();
        },
        updateLimit(data) {
            this.filter.limit = data;
            this.filter.page = 1;
            this.update();
        },
        updateHidden(data) {
            this.filter.hidden = data;
            this.filter.page = 1;
            this.update();
        },
        updateData(data) {
            this.data = data;
        },
        updateStatus(data) {
            this.filter.status = data;
            this.filter.page = 1;
            this.update();
        },
        updateFailed() {
            this.data = [];
        },
    },
    created() {
        this.update();
    },
};
</script>
