<template>
    <BasicCard title="Sign-up Process Step" :loading="loading">
        <p class="title text--primary mt-1">Create Account and Send Welcome</p>
        <p class="text--primary">
            A new representative will be created and a portal login will be
            generated and sent via e-mail.
        </p>
        <p class="text--primary">
            All uploaded files will be attached to the new partner record.
        </p>
        <p class="text--primary">This request will be marked as completed.</p>

        <template v-slot:actions>
            <ButtonWithTooltip
                text="Complete"
                tooltip="Complete this sign-up request"
                icon="mdi-check"
                flat
                color="success"
                @click="$emit('complete')"
            />
        </template>
    </BasicCard>
</template>

<script>
import BasicCard from '@/components/cards/templates/BasicCard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
export default {
    name: 'SignupCompletionCard',
    components: { ButtonWithTooltip, BasicCard },
    props: ['data', 'loading'],
    data() {
        return {
            showDialog: false,
        };
    },
    methods: {
        closeModal(refresh) {
            this.showDialog = false;
            if (refresh) {
                this.$emit('update');
            }
        },
    },
};
</script>
