<template>
  <v-container fluid>
    <v-card class="pa-2 mb-2">
      <v-row>
        <v-col cols="12" md="4" lg="1">
          <DatePicker
            v-model="filter.start_date"
            label="Start date"
            show-clear
            hide-details
            dense
            :loading="busy"
            type="month"
          />
        </v-col>
        <v-col cols="12" md="4" lg="1">
          <DatePicker
            v-model="filter.end_date"
            label="End date"
            show-clear
            hide-details
            dense
            :loading="busy"
            type="month"
            :allowedDates="validEndDates"
          />
        </v-col>
        <v-col cols="12" md="4" lg="2">
          <v-select
            v-model="filter.user_type"
            :items="userTypeSelect"
            label="User Type"
            outlined
            dense
            hint=""
          />
        </v-col>
        <v-col cols="12"  md="4" lg="2">
          <v-text-field
            hide-details
            outlined
            clearable
            dense
            label="Customer name or i_customer"
            :disabled="busy"
            v-model="filter.name_keyword"
            @keydown.enter="csvTog = false; runReport"
          />
        </v-col>
        <v-col cols="12" md="4" lg="2">
          <v-checkbox
            v-model="filter.nbn_subscription"
            label="NBN Subscription"
            :value="true"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="4" lg="2">
          <v-checkbox
            v-model="filter.team_subscription"
            label="Teams Subscription"
            :value="true"
          ></v-checkbox>
        </v-col>
        <v-col cols="6" md="2" lg="1">
          <v-btn
            outlined
            block
            height="40"
            :loading="busy"
            @click="csvTog = false; runReport()"
          >Run</v-btn>
        </v-col>
        <v-col cols="6" md="2" lg="1">
          <v-btn
            outlined
            block
            height="40"
            :loading="busy"
            @click="csvTog = true; runReport()"
          >CSV</v-btn>
        </v-col>
      </v-row>
    </v-card>

    <v-card class="pa-2 mb-2">
      <div style="font-size:smaller;">
        Customers are included in the results if they were created within the timeframe.
      </div>
    </v-card>

    <v-card class="pa-2 mb-2" v-if="responseData != null">
      <strong>Total Customers: </strong> {{responseData.total_customers}} | <strong>Total Revenue: </strong> <Currency :value="responseData.total_revenue"/>
    </v-card>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          fixed-header
          height="100vh"
          :items="responseData != null ? responseData.items : []"
          :items-per-page="50"
          class="elevation-1"
          item-key="i_customer"
          :loading="busy"
          loading-text="Processing results... Please wait"
        >
          <template v-slot:[`item.i_customer`]="{ item }">
            <router-link :to="{name: 'CustomerSummary', params: {customerId: item.i_customer}}">{{item.i_customer}}</router-link>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <router-link :to="{name: 'CustomerSummary', params: {customerId: item.i_customer}}">{{item.name}}</router-link>
          </template>
          <template v-slot:[`item.user_type`]="{ item }">
            <span v-if="item['user_type'] == 'sip-trunk'">Sip Trunk</span>
            <span v-if="item['user_type'] == 'hosted-extension'">Hosted Extension</span>
          </template>
          <template v-slot:[`item.total`]="{ item }">
            <Currency
              :value="item['total']"
            />
          </template>
          <template v-slot:[`item.nbn_subscription_count`]="{ item }">
            <v-chip
              class="ma-2"
              color="primary"
              v-if="item.nbn_subscription_count>0"
            >
              NBN
            </v-chip>
            <v-chip
              class="ma-2"
              color="primary"
              v-if="item.teams_subscription_count>0"
            >
              Teams
            </v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import DatePicker from "../../../components/pieces/Forms/DatePicker.vue";
import Currency from "../../../components/pieces/Currency.vue";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import AdminReportMixin from "../../../mixins/AdminReportMixin";
export default {
  name: 'NewCustomerGrowthReport',
  mixins: [GlobalHelperMixin,AdminReportMixin],
  data: () => ({
    filter: {
      start_date:null,
      end_date:null,
      team_subscription:false,
      nbn_subscription:false,
      user_type:null,
    },
    validEndDates:[],
    busy: false,
    userTypeSelect: [
      { value: null, text: 'Any' },
      { value: 'sip-trunk', text: 'SIP TRUNK' },
      { value: 'hosted-extension', text: 'HOSTED EXTENSION' },
    ],
    responseData:null,
    headers: [
      {
        text: 'i_customer',
        align: 'start',
        sortable: true,
        value: 'i_customer',
      },
      {
        text: 'Customer Name',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Partner Name',
        sortable: true,
        value: 'partner_name',
      },
      {
        text: 'User Type',
        sortable: true,
        value: 'user_type',
      },
      {
        text: 'Creation Timestamp',
        sortable: true,
        value: 'creation_date',
      },
      {
        text: 'Total Revenue',
        sortable: true,
        value: 'total',
      },
      //this column is to show the badges. Its a bit of a hack to get an empty col to show badges.
      {
        text: 'Badges',
        sortable: false,
        value:'nbn_subscription_count',
      },
    ],
    booleanFilterKeys:['team_subscription','nbn_subscription']
  }),
  components: {
    Currency,
    DatePicker,
  },

  methods: {
    runReport( ){
      this.apiResponse = null;
      if(this.filter.start_date == null || this.filter.end_date == null){
        this.showGlobalErrorMessage('Invalid Start/End dates');
        return;
      }
      this.updateRouteWithFilters( );
      this.update( );
    },
    update( ){
      const params = {
        start_date:this.filter.start_date,
        end_date:this.filter.end_date,
      };
      if(this.filter.team_subscription != null){
        params.team_subscription = this.filter.team_subscription;
      }
      if(this.filter.nbn_subscription != null){
        params.nbn_subscription = this.filter.nbn_subscription;
      }
      if(this.filter.user_type != null){
        params.user_type = this.filter.user_type;
      }
      if(this.filter.name_keyword != null){
        params.name_keyword=this.filter.name_keyword
      }
      this.updateReport(params,'admin/reporting/new-customer-growth');
    },
    updateStartDate(data) {
      this.filter.start_date = data;
    },
    updateEndDate(data) {
      this.filter.end_date = data;
    },

    runReportOnNewPage( ){
      if(!this.$route.query.start_date || !this.$route.query.end_date){
        let end_date = new Date( );
        // end_date = this.subtractMonth(end_date);
        // end_date = this.subtractMonth(end_date);

        let start_date = new Date( );
        // start_date = this.subtractMonth(start_date);
        // start_date = this.subtractMonth(start_date);
        // start_date = this.subtractMonth(start_date);

        this.updateStartDate(this.formatDate(start_date));
        this.updateEndDate(this.formatDate(end_date));
      }
      this.runReport();
    }
  },
  computed:{
    start_date( ){
      if(this.filter.start_date){
        return this.filter.start_date;
      }else{
        return null;
      }
    }
  },
  watch: {
    start_date(value) {
      if(value != null){
        //process the value dates for the end date.
        let startDate = new Date(value+"-01");
        let startYear = startDate.getFullYear();
        let startMonth = startDate.getMonth();
        startMonth++;
        let endMonth = 12;
        let endYear = startYear;

        if(startMonth != 1){
          endYear++;
          endMonth = startMonth - 1;
        }

        let workingMonth = startMonth;
        let workingYear = startYear;

        let ret = [];

        while(workingYear < endYear || (workingYear == endYear && workingMonth <= endMonth)){
          if(workingMonth < 10){
            ret.push(workingYear+'-0'+workingMonth);
          }else{
            ret.push(workingYear+'-'+workingMonth);
          }
          if(workingMonth == 12){
            workingMonth = 1;
            workingYear++;
          }else{
            workingMonth++;
          }
        }

        this.validEndDates = ret;
      }
    },
  },
};
</script>
