<template>
    <BasicCard title="Representative Portal Login" :loading="loading">
        <v-row dense class="body-1 text--primary">
            <v-col cols="4">
                <span class="font-weight-bold">Username</span>
            </v-col>
            <v-col cols="8">
              <span v-if="data.login">
                {{ data.login }}
                <CopyToClipboard :value="data.login" small />
              </span>
              <span v-else v-text="'DISABLED'" />
            </v-col>
            <v-col cols="4">
                <span class="font-weight-bold">Password</span>
            </v-col>
            <v-col cols="8">
                <Password :value="data.password" v-if="data.password" />
                <span v-else v-text="'DISABLED'" />
            </v-col>
        </v-row>
        <p>
            Login at
            <a href="https://admin.breezeconnect.com.au/" target="_blank"
                >https://admin.breezeconnect.com.au/</a
            >
        </p>

        <template v-slot:actions>
            <ButtonWithTooltip
                disabled
                text="Send"
                icon="mdi-email-outline"
                tooltip="Send login details via e-mail"
                flat
            />
            <ButtonWithTooltip
                disabled
                text="Edit"
                icon="mdi-pencil"
                tooltip="Change login details"
                flat
            />
        </template>
    </BasicCard>
</template>

<script>
import BasicCard from '@/components/cards/templates/BasicCard';
import Password from '../../pieces/Password';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import CopyToClipboard from '../../pieces/CopyToClipboard';
export default {
    name: 'PartnerLoginCard',
    components: { CopyToClipboard, ButtonWithTooltip, Password, BasicCard },
    props: ['data', 'loading'],
};
</script>
