<template>
    <BasicCard :loading="loading" title="Customer Details">
        <p class="title text--primary">
            <span v-if="data.customer_name">{{ data.customer_name }}</span>
        </p>
        <v-row dense v-if="data">
            <v-col cols="4">
                <span class="font-weight-bold">Address</span>
            </v-col>
            <v-col cols="8">
                {{ data.address }}
            </v-col>
            <v-col cols="4">
                <span class="font-weight-bold">Suburb</span>
            </v-col>
            <v-col cols="8">
                {{ data.suburb }}
            </v-col>
            <v-col cols="4">
                <span class="font-weight-bold">State</span>
            </v-col>
            <v-col cols="8">
                {{ data.state }}
            </v-col>
            <v-col cols="4">
                <span class="font-weight-bold">Postcode</span>
            </v-col>
            <v-col cols="8">
                {{ data.pcode }}
            </v-col>
            <v-col cols="4">
                <span class="font-weight-bold">ABN</span>
            </v-col>
            <v-col cols="8">
                {{ data.abn }}
            </v-col>
        </v-row>
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
export default {
    name: 'PortingCustomerCard',
    components: { BasicCard },
    props: ['data', 'loading'],
    data() {
        return {
            showDialog: false,
        };
    },
    methods: {
        closeModal(refresh) {
            this.showDialog = false;
            if (refresh) {
                this.$emit('update');
            }
        },
    },
};
</script>
