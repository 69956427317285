<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12">
                <PaginationCard
                    :page="filter.page"
                    :data="data"
                    :limit="filter.limit"
                    :loading="loading"
                    v-on:updateLimit="updateLimit"
                    v-on:updatePage="updatePage"
                >
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Username</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Status</th>
                                    <th>2FA</th>
                                    <th>User Type</th>
                                    <th>Created</th>
                                    <th>Last Password Change</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, i) in data.data" :key="i">
                                    <td>
                                        <router-link :to="{ name: 'AdminUserDetails', params: { userId: item.id }}"><span class="font-weight-medium">{{
                                            item.username
                                        }}</span></router-link>
                                    </td>
                                    <td>{{ item.firstname }}</td>
                                    <td>{{ item.lastname }}</td>
                                    <td>
                                        {{
                                            item.active_at ? 'Active' : 'Disabled'
                                        }}
                                    </td>
                                    <td>
                                        <v-icon
                                            v-if="item.twofactor_enabled_at != null"
                                            color="success"
                                            class="mr-2"
                                            >mdi-check</v-icon
                                        >
                                        <v-icon
                                            v-if="item.twofactor_enabled_at == null"
                                            color="error"
                                            class="mr-2"
                                            >mdi-cancel</v-icon
                                        >
                                    </td>
                                    <td>{{ item.user_type }}</td>
                                    <td>
                                        <LocalDateTime
                                            :value="item.created_at"
                                            hide-tooltip
                                        />
                                    </td>
                                    <td>
                                        <LocalDateTime
                                            :value="item.password_updated_at"
                                            hide-tooltip
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </PaginationCard>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import PaginationCard from '@/components/cards/templates/PaginationCard';
import LocalDateTime from '@/components/pieces/LocalDateTime';
import apiMixin from '../../../mixins/apiMixin';
export default {
    name: 'UserList',
    mixins: [apiMixin],
    data: () => ({
        filter: {
            limit: 25,
            page: 1,
            statusOptions: [
                { option: 'All', value: null },
                { option: 'Active', value: 0 },
                { option: 'Disabled', value: 1 },
            ],
            status: { option: 'All', value: null },
        },
        loading: false,
        data: [],
    }),
    components: {
        LocalDateTime,
        PaginationCard,
    },
    methods: {
        async update() {
            this.loading = true;
            let params = { limit: this.filter.limit, page: this.filter.page };
            if (this.filter.status.value !== null) {
                params.status = this.filter.status.value;
            }
            const response = await this.Api.send('post','admin/users/list', params);
            this.loading = false;
            if (response.success) {
                this.updateData(response.data);
            } else {
                this.updateFailed(response.error, response.errorDetail);
            }
        },
        updatePage(data) {
            this.filter.page = data;
            this.update();
        },
        updateLimit(data) {
            this.filter.limit = data;
            this.filter.page = 1;
            this.update();
        },
        updateHidden(data) {
            this.filter.hidden = data;
            this.filter.page = 1;
            this.update();
        },
        updateData(data) {
            this.data = data;
        },
        updateStatus(data) {
            this.filter.status = data;
            this.filter.page = 1;
            this.update();
        },
        updateFailed() {
            this.data = [];
        },
    },
    created() {
        this.update();
    },
};
</script>
