<template>
    <v-container fluid>
        <CustomerListCard
            :filter="filter"
            @update="updateFilter"
            show-filters
            get-rep-names
            show-new-customer
        />
      <FloatingButton
          tooltip="New Customer"
          icon="mdi-account-plus"
          @click="showNewCustomerAccountType=true"
      />

      <NewCustomerAccountTypeDialog v-model="showNewCustomerAccountType" @type-selected="function(type){customerAccountType=type; showNewCustomer=true;}"/>
      <NewCustomerDialog v-model="showNewCustomer" :customer-account-type="customerAccountType"/>
    </v-container>
</template>

<script>
import CustomerListCard from '@/components/cards/Customer/CustomerListCard';
import filterMixin from '../../mixins/filterMixin';
import FloatingButton from '../../components/pieces/FloatingButton';
import NewCustomerDialog from '../../components/dialogs/Customer/NewCustomerDialog';
import NewCustomerAccountTypeDialog from '../../components/dialogs/Customer/NewCustomerAccountTypeDialog';
export default {
    name: 'CustomerList',
    mixins: [filterMixin],
    data: () => ({
        filter: {
            limit: 10,
            page: 1,
            status: null,
            name: null,
            accountType: null,
        },
        showNewCustomer: false,
        showNewCustomerAccountType:false,
        customerAccountType:'n/a'
    }),
    components: {
        NewCustomerDialog,
        NewCustomerAccountTypeDialog,
        FloatingButton,
        CustomerListCard,
    },
    beforeRouteLeave(to, from, next){
        return (this.showNewCustomer) ? next(false) : next();
    }
};
</script>
