<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" sm="6" lg="4">
                <SupportCard />
            </v-col>
            <v-col cols="12" sm="6" lg="4" v-if="false">
                <RevenueStatsCard />
            </v-col>
            <v-col cols="12" sm="6" lg="4">
                <PartnerStatsCard />
            </v-col>
            <v-col cols="12" sm="6" lg="4">
                <CustomerStatsCard />
            </v-col>
            <v-col cols="12" sm="6" lg="4">
                <DIDStatsCard />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// @ is an alias to /src
import SupportCard from '@/components/cards/SupportCard';
import DIDStatsCard from '@/components/cards/Home/DIDStatsCard';
import PartnerStatsCard from '@/components/cards/Home/PartnerStatsCard';
import CustomerStatsCard from '@/components/cards/Home/CustomerStatsCard';
export default {
    name: 'home',
    components: {
        CustomerStatsCard,
        PartnerStatsCard,
        DIDStatsCard,
        SupportCard,
    },
};
</script>
