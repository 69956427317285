<template>
    <ComplexDialog
        v-model="visible"
        title="Edit Partner Contact"
        persistent
    >
        <v-form ref="form" v-model="isValid">
            <v-row dense>
                <v-col cols="12">
                    <Alert
                        type="info"
                        message="This should be the authorised person in the business (whoever signs the partner agreement)."
                    />
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        label="First Name *"
                        v-model="formData.firstname"
                        required
                        :disabled="busy"
                        :rules="rules.requiredOnly"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        label="Last Name *"
                        v-model="formData.lastname"
                        required
                        :disabled="busy"
                        :rules="rules.requiredOnly"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        label="Position *"
                        v-model="formData.position"
                        required
                        :disabled="busy"
                        hint="Position within the business (Director)"
                        persistent-hint
                        :rules="rules.requiredOnly"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        label="Email *"
                        v-model="formData.email"
                        required
                        :disabled="busy"
                        :rules="rules.email"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        label="Business Phone *"
                        v-model="formData.contact_business_phone"
                        :required="!!formData.contact_mobile"
                        :disabled="busy"
                        persistent-hint
                        :rules="rules.phone"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        label="Mobile Phone *"
                        v-model="formData.contact_mobile"
                        :required="!!formData.contact_business_phone"
                        :disabled="busy"
                        :rules="rules.mobile"
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-form>
        <template v-slot:actions>
            <v-btn
                text
                v-on:click="update"
                color="success"
                :disabled="
                    !isValid ||
                    (!formData.contact_mobile &&
                        !formData.contact_business_phone)
                "
            >
                <v-icon>mdi-check</v-icon>
                Save Changes
            </v-btn>
        </template>
    </ComplexDialog>
</template>

<script>
import Alert from '@/components/pieces/Alert';
import ComplexDialog from '../templates/ComplexDialog';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
export default {
    name: 'EditContactSignup',
    components: { ComplexDialog, Alert },
    mixins: [apiMixin, dialogMixin],
    data() {
        return {
            busy: false,
            formData: JSON.parse(JSON.stringify(this.data)),
            display: true,
            isValid: false,
            confirmed_bus: false,
            confirmed_abn: false,
            legacy: null,
            rules: {
                email: [
                    (v) => !!v || 'Required',
                    (v) => /^.+@.+\..+$/.test(v) || 'Invalid email',
                ],
                phone: [
                    (v) =>
                        /^(?:0[2,3,5-9]\d{8})?$/.test(v) ||
                        'Invalid fixed number',
                ],
                mobile: [
                    (v) => /^(?:04\d{8})?$/.test(v) || 'Invalid mobile number',
                ],
                requiredOnly: [(v) => !!v || 'Required'],
            },
        };
    },
    props: {
        data: {},
    },
    created() {
        if (this.data.i_commission_plan) {
            this.form.i_commission_plan =
                this.data.i_commission_plan.toString();
        }
    },
    methods: {
        async update(event) {
            event.preventDefault();
            this.busy = true;
            const response = await this.Api.send('post',
                'signup/' + this.formData.signup_id + '/updatecontact',
                {
                    firstname: this.formData.firstname,
                    lastname: this.formData.lastname,
                    position: this.formData.position,
                    contact_business_phone:
                        this.formData.contact_business_phone,
                    contact_mobile: this.formData.contact_mobile,
                    email: this.formData.email,
                }
            );
            if (response.success) {
                this.$emit('save');
                this.visible = false;
                this.busy = false;
            } else {
                this.busy = false;
            }
        },
    },
    watch: {
        visible: function (value) {
            if (this.visible!==value) this.visible = value;
            if (value) {
                this.formData = JSON.parse(JSON.stringify(this.data));
                this.confirmed_bus = false;
                this.confirmed_abn = false;
                this.legacy = null;
            }
        },
    },
    computed: {
        isDirty: function () {
            if (this.formData.companyname !== this.data.companyname)
                return true;
            if (this.formData.refnum !== this.data.refnum) return true;
            if (this.formData.tax_id !== this.data.tax_id) return true;
            if (this.legacy === 'Y' && this.formData.legacy_customer !== 1)
                return true;
            if (this.legacy === 'N' && this.formData.legacy_customer !== 0)
                return true;
            return false;
        },
    },
};
</script>
