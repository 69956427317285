<template>
    <BasicCard title="Sign-Up Process Overview" :loading="loading">
        <p class="subtitle-1 text--primary">New Partner Workflow</p>
        <p class="text--primary">
            <v-icon small left color="green">mdi-check-circle</v-icon
            >Application submitted
            <br />
            <v-icon
                v-if="!data.approved && !data.deleted"
                small
                left
                color="blue"
                >mdi-arrow-right-drop-circle</v-icon
            >
            <v-icon
                v-if="data.approved && !data.deleted"
                small
                left
                color="green"
                >mdi-check-circle</v-icon
            >
            <v-icon v-if="data.deleted" small left color="red"
                >mdi-close-circle-outline</v-icon
            >Review and approve
            <br />
            <v-icon v-if="!data.approved && !data.sent" small left
                >mdi-checkbox-blank-circle-outline</v-icon
            >
            <v-icon
                v-if="data.approved && !data.agreement_sent"
                small
                left
                color="blue"
                >mdi-arrow-right-drop-circle</v-icon
            >
            <v-icon v-if="data.agreement_sent" small left color="green"
                >mdi-check-circle</v-icon
            >Send partner agreement
            <br />
            <v-icon
                v-if="!data.agreement_sent && !data.agreement_signed"
                small
                left
                >mdi-checkbox-blank-circle-outline</v-icon
            >
            <v-icon
                v-if="data.agreement_sent && !data.agreement_signed"
                small
                left
                color="blue"
                >mdi-arrow-right-drop-circle</v-icon
            >
            <v-icon v-if="data.agreement_signed" small left color="green"
                >mdi-check-circle</v-icon
            >Partner signs & returns agreement
            <br />
            <v-icon
                v-if="!data.agreement_signed && !data.agreement_reviewed"
                small
                left
                >mdi-checkbox-blank-circle-outline</v-icon
            >
            <v-icon
                v-if="data.agreement_signed && !data.agreement_reviewed"
                small
                left
                color="blue"
                >mdi-arrow-right-drop-circle</v-icon
            >
            <v-icon v-if="data.agreement_reviewed" small left color="green"
                >mdi-check-circle</v-icon
            >Review signed agreement
            <br />
            <v-icon v-if="!data.agreement_reviewed" small left
                >mdi-checkbox-blank-circle-outline</v-icon
            >
            <v-icon
                v-if="data.agreement_reviewed && !data.completed"
                small
                left
                color="blue"
                >mdi-arrow-right-drop-circle</v-icon
            >
            <v-icon v-if="data.completed" small left color="green"
                >mdi-check-circle</v-icon
            >Create partner and send welcome e-mail
        </p>
    </BasicCard>
</template>

<script>
import BasicCard from '@/components/cards/templates/BasicCard';
export default {
    name: 'NewPartnerProcessCard',
    components: { BasicCard },
    props: ['data', 'loading'],
    data() {
        return {
            showDialog: false,
            documentDropdown: false,
            linkBase: process.env.VUE_APP_API_BASE || '/api/',
        };
    },
    methods: {
        closeModal(refresh) {
            this.showDialog = false;
            if (refresh) {
                this.$emit('update');
            }
        },
    },
};
</script>
