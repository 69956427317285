<template>
    <v-row dense>
        <v-col cols="12">
            <PaginationCard
                :page="filter.page ? filter.page : 1"
                :data="data"
                :limit="filter.limit ? filter.limit : 10"
                :loading="isLoading"
                :no-skeleton="!firstLoad"
                @updateLimit="updateLimit"
                @updatePage="updatePage"
            >
                <v-simple-table v-if="!!data">
                    <thead>
                    <tr>
                        <th>Session ID</th>
                        <th>Created</th>
                        <th>Expiry</th>
                        <th>Last Seen IP</th>
                        <th>User Agent</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <!-- tbody -->
                    <v-slide-x-transition tag="tbody" hide-on-leave group>
                        <tr v-if="data.data.length === 0" key="nodata">
                            <td colspan="100%" class="text-center">
                                No sessions found
                            </td>
                        </tr>
                        <tr
                                v-for="item in data.data"
                                :key="item.id"
                        >
                            <td class="pa-0">
                                <router-link
                                    :to="{name: 'AdminUserHistory', query: { session_id: item.id.toString() }}"
                                    style="cursor: pointer"
                                    class="pa-4"
                                    disabled
                                >
                                    <span class="font-weight-medium">{{ item.id }}</span>
                                </router-link>
                            </td>

                            <td>
                                <LocalDateTime :value="item.session_created" show-time />
                            </td>
                            <td>
                                <LocalDateTime v-if="Date.parse(item.token_expires_at)>Date.parse(item.session_expires_at)" :value="item.session_expires_at" show-time />
                                <LocalDateTime v-else :value="item.token_expires_at" show-time />
                            </td>
                            <td>{{item.last_ip_address}}</td>
                            <td>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on" class="d-inline-block text-truncate" style="max-width: 250px;">{{item.http_user_agent}}</span>
                                    </template>
                                    {{item.http_user_agent}}
                                </v-tooltip>

                            </td>
                            <td>
                                <v-chip v-html="item.is_valid ? 'Active' : 'Ended'" :color="item.is_valid ? 'success' : 'error'" small />
                            </td>
                        </tr>
                    </v-slide-x-transition>
                    <!-- /tbody -->
                </v-simple-table>
            </PaginationCard>
        </v-col>
    </v-row>
</template>

<script>
import PaginationCard from '../templates/PaginationCard';
import paginationComponentMixin from '../../../mixins/paginationComponentMixin';
import apiMixin from '../../../mixins/apiMixin';
import refreshListenerMixin from '../../../mixins/refreshListenerMixin';
import LocalDateTime from '../../pieces/LocalDateTime';
export default {
    name: 'UserSessionsCard',
    mixins: [apiMixin, paginationComponentMixin, refreshListenerMixin],
    components: { LocalDateTime, PaginationCard },
    data: () => ({
        localFilter: {
            status: null
        },
        statusOptions: [
            { option: 'All', value: null },
            { option: 'Active', value: 'Y' },
            { option: 'Terminated', value: 'N' },
        ],
        data: null
    }),
    methods: {
        async get(id) {
            this.isLoading = true;
            this.getLocalFilters();
            let params = { limit: this.filter.limit, page: this.filter.page };
            if (this.filter.active)
                params.active = this.filter.active;
            const response = await this.Api.send('post','admin/users/' + id + '/sessions', params);
            this.data = response.success ? response.data : [];
            this.isLoading = false;
        },
        update() {
            this.get(this.userId)
        }
    },
    props: {
        userId: {
            type: Number,
            default: null,
        }
    },
    watch: {
        userId(val) {
            //this.get(val);
        }
    },
};
</script>