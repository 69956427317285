<template>
    <BasicCard title="Sign-up Process Step" :loading="loading">
        <p class="title text--primary mt-1">
            <span v-if="!data.agreement_sent">Send Partner Agreement</span>
            <span v-if="data.agreement_sent && !data.agreement_signed"
                >Upload Signed Agreement</span
            >
            <span v-if="data.agreement_signed">Review Signed Agreement</span>
        </p>
        <p class="text--primary" v-if="!data.agreement_sent">
            The partner agreement needs to be generated and sent for signing.
        </p>
        <p
            class="text--primary"
            v-if="data.agreement_sent && !data.agreement_signed"
        >
            A partner agreement has already been sent.
        </p>
        <p
            class="text--primary"
            v-if="data.agreement_sent && !data.agreement_signed"
        >
            Once the partner has signed the agreement it should be uploaded
            here.
        </p>
        <p
            class="text--primary"
            v-if="data.agreement_sent && !data.agreement_signed"
        >
            You can also re-send the agreement if required.
        </p>
        <p
            class="text--primary"
            v-if="data.agreement_signed && !data.agreement_reviewed"
        >
            The partner agreement has been signed and returned.
        </p>
        <p
            class="text--primary"
            v-if="data.agreement_signed && !data.agreement_reviewed"
        >
            Please check the attached file to ensure the agreement is signed
            correctly.
        </p>
        <p class="text--primary">
            This is a
            <span v-if="data.legacy_customer" class="font-weight-bold"
                >legacy partner</span
            >
            <span v-if="!data.legacy_customer" class="font-weight-bold"
                >new partner</span
            >
            so a
            <span v-if="data.legacy_customer" class="font-weight-bold"
                >legacy partner agreement</span
            >
            <span v-if="!data.legacy_customer" class="font-weight-bold"
                >standard partner agreement</span
            >
            <span v-if="!data.agreement_sent"> will be generated.</span>
            <span v-if="data.agreement_sent"> is required.</span>
        </p>

        <template v-slot:actions>
            <ButtonWithTooltip
                v-if="!data.agreement_signed"
                text="save"
                tooltip="View/download partner agreement PDF"
                icon="mdi-download"
                flat
                :href="
                    linkBase +
                    'signup/' +
                    data.signup_id +
                    '/downloadagreement?session-token=' +
                    $store.state.session.token
                "
            />
            <ButtonWithTooltip
                v-if="!data.agreement_signed"
                text="Send"
                tooltip="Send partner agreement via e-mail"
                icon="mdi-email"
                flat
                @click="$emit('send')"
                color="primary"
            />
            <ButtonWithTooltip
                v-if="!data.agreement_sent"
                text="Sent"
                tooltip="Mark agreement as sent manually"
                icon="mdi-check"
                flat
                color="success"
                @click="$emit('sent')"
            />

            <ButtonWithTooltip
                v-if="data.agreement_sent && !data.agreement_signed"
                text="Upload"
                tooltip="Upload signed partner agreement"
                icon="mdi-upload"
                flat
                color="success"
                @click="$emit('upload')"
            />
            <ButtonWithTooltip
                v-if="data.agreement_signed && !data.agreement_reviewed"
                text="Paperwork"
                tooltip="Go back to paperwork step"
                icon="mdi-undo"
                color="warning"
                @click="$emit('unsign')"
                flat
            />

            <ButtonWithTooltip
                v-if="data.agreement_signed && !data.agreement_reviewed"
                text="Review and Approve"
                tooltip="Review and approve signed partner agreement"
                icon="mdi-check"
                flat
                color="success"
                @click="$emit('review')"
            />
        </template>
    </BasicCard>
</template>

<script>
import BasicCard from '@/components/cards/templates/BasicCard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
export default {
    name: 'SignupReviewAgreementCard',
    components: { ButtonWithTooltip, BasicCard },
    props: ['data', 'loading'],
    data() {
        return {
            showDialog: false,
            linkBase: process.env.VUE_APP_API_BASE || '/api/',
        };
    },
    methods: {
        closeModal(refresh) {
            this.showDialog = false;
            if (refresh) {
                this.$emit('update');
            }
        },
    },
};
</script>
