var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-row',{staticClass:"px-4"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"loading":_vm.loading,"hide-details":"","outlined":"","dense":"","label":"Description","clearable":""},on:{"click:clear":_vm.clearDescription,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.update($event)}},model:{value:(_vm.filter.description),callback:function ($$v) {_vm.$set(_vm.filter, "description", $$v)},expression:"filter.description"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"outlined":"","block":"","loading":_vm.loading}},on),[_vm._v(" Status: "+_vm._s(_vm.filter.status)+" "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',[_c('v-list',_vm._l((_vm.filter.statusOptions),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateStatus(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.option))])],1)}),1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-btn',{staticClass:"secondary",attrs:{"block":"","outlined":"","loading":_vm.loading},on:{"click":_vm.update}},[_vm._v("Search")])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('PaginationCard',{attrs:{"page":_vm.filter.page,"data":_vm.data,"limit":_vm.filter.limit,"loading":_vm.loading},on:{"updateLimit":_vm.updateLimit,"updatePage":_vm.updatePage}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("PAF ID")]),_c('th',[_vm._v("Description")]),(_vm.$vuetify.breakpoint.smAndUp)?_c('th',[_vm._v(" Port Type ")]):_vm._e(),_c('th',[_vm._v("Status")]),(_vm.$vuetify.breakpoint.smAndUp)?_c('th',[_vm._v(" Port Date ")]):_vm._e(),_c('th',[_vm._v("Last Updated")])])]),_c('tbody',[_vm._l((_vm.data.data),function(item,i){return _c('tr',{key:i},[_c('td',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.id)+" ")]),_c('td',[_c('router-link',{staticClass:"font-weight-medium",attrs:{"link":"","to":'/admin/porting/carrier/' + item.id}},[_vm._v(" "+_vm._s(item.description)+" ")])],1),(_vm.$vuetify.breakpoint.smAndUp)?_c('td',[(item.port_type === 'A')?_c('span',[_vm._v("Cat A")]):(item.port_type === 'C')?_c('span',[_vm._v("Cat C")]):_vm._e(),(item.port_type === 'S')?_c('span',[_vm._v("Special")]):_vm._e()]):_vm._e(),_c('td',[_vm._v(" "+_vm._s(item.current_status)+" "),(!item.current_status)?_c('span',[_vm._v("Unknown")]):_vm._e()]),(_vm.$vuetify.breakpoint.smAndUp)?_c('td',[(
                                            _vm.contains(
                                                'Date Assigned',
                                                item.current_status
                                            )
                                        )?_c('span',[_c('LocalDateTime',{attrs:{"value":item.port_date,"show-time":""}})],1):(
                                            !_vm.contains(
                                                'Date Assigned',
                                                item.current_status
                                            )
                                        )?_c('span',[_vm._v("N/A")]):_vm._e()]):_vm._e(),_c('td',[_c('LocalDateTime',{attrs:{"value":item.last_updated,"relative":""}})],1)])}),(!_vm.data.data)?_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"6"}},[_vm._v(" No items found. ")])]):_vm._e()],2)]},proxy:true}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }