<template>
  <SimpleDialog
      v-model="visible"
      :title="'Add Exemption'"
      yes-text="Add"
      :loading="busy"
      :yes-disabled="!valid || (form.refnum == null && form.i_rep == null)"
      :max-width="400"
      no-text="Cancel"
      @click="submit"
      persistent
      styledHeader
  >
    <p>Note that by default if a Partner does not have a 'refnum' value they will be excluded from the report.
    However if a Warning or Ignore exemption is applied to the Partner they will be added to the report.
    This is because the manually added exemption overwrites the default exemption that would normally cause them to be removed.</p>
    <v-form v-model="valid">
      <v-row>
        <v-col cols="5"><v-text-field v-model="form.refnum" label="Refnum" outlined/></v-col>
        <v-col cols="2"><div class="pt-4" style="font-size:larger;text-align: center;"> OR </div></v-col>
        <v-col cols="5"><v-text-field v-model="form.i_rep" label="i_rep" outlined/></v-col>
      </v-row>
      <br />
      <v-select outlined label="Exemption Type" :items="selectTypes" v-model="form.type" :rules="rules.required"/>
    </v-form>
  </SimpleDialog>
</template>

<script>
import dialogMixin from '../../../mixins/dialogMixin';
import apiMixin from '../../../mixins/apiMixin';
import SimpleDialog from "../templates/SimpleDialog";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
export default {
    name: 'AddPartnerCommissionReportExemptionDialog',
    components: {SimpleDialog},
    mixins: [apiMixin, dialogMixin,GlobalHelperMixin],
    data: () => ({
        busy: false,
        valid: false,
        selectTypes:[
          { text: 'Warning', value: 'warning' },
          { text: 'Ignore', value: 'ignore' },
          { text: 'Remove', value: 'remove' },
      ],
        form:{},
      rules: {
        required: [
          (v) => !!v || 'Required',
        ]
      }
    }),
    props: {

    },
    methods: {
      setup( ){
        this.form.refnum = null;
        this.form.i_rep = null;
        this.form.type = null;
      },

      async submit( ){
        this.busy = true;
        const response = await this.Api.send('post','partner-commission-report-exemptions',this.form, false);
        this.busy = false;
        if (response.success) {
          this.visible = false;
          this.form.refnum = null;
          this.form.i_rep = null;
          this.showGlobalSuccessMessage('Exemption added successfully.');
          document.dispatchEvent(new CustomEvent('refreshRequested'));
        }
      },
    },

};
</script>