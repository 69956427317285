<template>
    <v-container fluid>
        <v-row dense>
            <!-- non-dismissable alert only shows if status is blocked -->
            <v-col cols="12" v-if="data.disabled === 'Y'">
                <Alert
                    type="error"
                    message="This cron is currently disabled."
                />
            </v-col>
            <!-- Cron Details Card -->
            <v-col cols="12" sm="6">
                <BasicCard
                    :loading="loading"
                    v-bind:class="{
                        'blue-grey darken-5': $vuetify.theme.dark,
                        'blue-grey lighten-5': !$vuetify.theme.dark,
                    }"
                >
                    <template v-slot:feature>
                        <v-chip class="primary ml-2">Cron</v-chip>
                        <v-icon right x-large>mdi-calendar-check</v-icon>
                    </template>
                    <p class="title text--primary">{{ data.scriptname }}</p>
                    <v-row dense>
                        <v-col cols="5">
                            <span class="font-weight-bold">Cron ID</span>
                        </v-col>
                        <v-col cols="7">
                            {{ data.cron_id }}
                        </v-col>
                        <v-col cols="5">
                            <span class="font-weight-bold">Script name</span>
                        </v-col>
                        <v-col cols="7">
                            {{ data.scriptname }}
                        </v-col>
                        <v-col cols="5">
                            <span class="font-weight-bold">Created</span>
                        </v-col>
                        <v-col cols="7">
                            <LocalDateTime :value="data.created" />
                        </v-col>
                    </v-row>
                    <template v-slot:actions> </template>
                </BasicCard>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
                <BasicCard>
                    {{ data }}
                </BasicCard>
            </v-col>
            <v-col cols="12">
                <CronHistoryCard :loading="loading" :id="data.cron_id" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Alert from '@/components/pieces/Alert';
import BasicCard from '../../../components/cards/templates/BasicCard';
import LocalDateTime from '../../../components/pieces/LocalDateTime';
import CronHistoryCard from '../../../components/cards/Cron/CronHistoryCard';
import apiMixin from '../../../mixins/apiMixin';
export default {
    name: 'CronDetail',
    mixins: [apiMixin],
    data: () => ({
        loading: true,
        data: {
            data: [],
        },
    }),
    components: {
        CronHistoryCard,
        LocalDateTime,
        BasicCard,
        Alert,
    },
    methods: {
        async get(id) {
            this.loading = true;
            const response = await this.Api.send('post','admin/cron/' + id + '/info');
            this.loading = false;
            if (response.success) {
                this.updateData(response.data);
            } else {
                this.updateFailed(response.error, response.errorDetail);
            }
        },
        update() {
            this.get(this.$route.params.id);
        },
        updateData(data) {
            this.data = data;
        },
        updateFailed() {
            this.data = [];
        },
    },
    created() {
        this.update();
    },
};
</script>
