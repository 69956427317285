<template>
    <v-container fluid>
      <v-card class="pa-2 mb-2">
        <v-row>
          <v-col cols="12" md="4" lg="2">
            <DatePicker
              v-model="searchFilters.from_date"
              label="Issued after"
              show-clear
              hide-details
              dense
              :loading="busy"
            />
          </v-col>
          <v-col cols="12" md="4" lg="2">
            <DatePicker
              v-model="searchFilters.to_date"
              label="Issued before"
              show-clear
              hide-details
              dense
              :loading="busy"
            />
          </v-col>
          <v-col cols="12" md="4" lg="2">
            <v-text-field
              hide-details
              outlined
              clearable
              dense
              label="i_customer"
              :disabled="busy"
              v-model="searchFilters.i_customer"
            />
          </v-col>
          <v-col cols="12" md="4" lg="2">
            <v-select
              hide-details
              outlined
              dense
              label="Status"
              v-model="searchFilters.status"
              :disabled="busy"
              :items="statusOptions"
              item-text="option"
              item-value="value"
            />
          </v-col>
          <v-col cols="6" md="2" lg="1">
            <v-btn
              class="secondary"
              outlined
              block
              height="40"
              :loading="busy"
              @click="update"
            >Run</v-btn>
          </v-col>
          <v-col cols="6" md="2" lg="1">
            <v-btn
              class="secondary"
              outlined
              block
              height="40"
              :loading="busy"
              @click="downloadCSV"
            >CSV</v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-row>
        <v-col cols="12">
          <PaginationCardV2
            :page="searchFilters.page"
            :totalRecords="listData.count"
            :totalPages="listData.pages"
            :limit="searchFilters.limit"
            :currentPageTotal="(listData.data) ? listData.data.length : 0"
            :loading="busy"
            @updatedPagination="updatedPagination"
            :no-skeleton="false"
          >
            <v-simple-table fixed-header>
              <thead>
              <tr>
                <th class="text-left">ID</th>
                <th class="text-left">Customer</th>
                <th class="text-left">Transaction ID</th>
                <th class="text-left">Amount</th>
                <th class="text-left">Card</th>
                <th class="text-left">Message</th>
                <th class="text-left">Date/Time</th>
                <th class="text-left">Status</th>
              </tr>
              </thead>
              <v-slide-x-transition tag="tbody" hide-on-leave group>
                <tr v-if="listData.data && listData.data.length === 0" key="nodata">
                  <td colspan="100%" class="text-center">
                    No Credit Card Transactions Found
                  </td>
                </tr>

                <tr
                  v-for="item in listData.data"
                  :key="item.i_payment_transaction"
                >
                  <td>{{ item.i_payment_transaction }}</td>
                  <td>
                    <router-link
                      :to="'/customers/' + item.i_customer"
                    >{{item.customer_name}}</router-link>
                  </td>
                  <td>{{ item.x_transaction_id }}</td>
                  <td>
                    <Currency :value="item.amount" />
                  </td>
                  <td>{{ item.payment_method_info }}</td>
                  <td>{{ item.result_message }}</td>
                  <td>
                    <LocalDateTime
                      :value="item.timestamp"
                    />
                  </td>
                  <td>
                    <v-chip
                      v-if="item.status == 'FAILED'"
                      color="red"
                      text-color="white"
                      label
                    >FAILED</v-chip>
                    <v-chip
                      v-else-if="item.status == 'COMPLETED'"
                      color="green"
                      text-color="white"
                      label
                    >COMPLETED</v-chip>
                    <v-chip
                      v-else
                      color="orange"
                      text-color="white"
                      label
                    >{{ item.status }}</v-chip>
                  </td>
                </tr>
              </v-slide-x-transition>
            </v-simple-table>
          </PaginationCardV2>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import PaginationCard from '../../../components/cards/templates/PaginationCard.vue';
import LocalDateTime from '../../../components/pieces/LocalDateTime.vue';
import Currency from '../../../components/pieces/Currency.vue';
import apiMixin from '../../../mixins/apiMixin';
import DatePicker from "../../../components/pieces/Forms/DatePicker.vue";
import PaginationCardV2 from "../../../components/cards/templates/PaginationCardV2.vue";
import ButtonWithTooltip from "../../../components/pieces/ButtonWithTooltip.vue";
export default {
    name: 'CreditCardCharges',
    mixins: [apiMixin],
    data: () => ({
      linkBase: process.env.VUE_APP_API_BASE || '/api/',
      busy:false,
      searchFilters: {
        page:1,
        limit: 10,
        from_date: null,
        to_date: null,
        status: 'COMPLETED',
        i_customer: null,
      },
      listData: {
        total_records:0,
        total_pages:0
      },
      statusOptions: [
        { option: 'Any', value: null },
        { option: 'Started', value: 'STARTED' },
        { option: 'Authorized', value: 'AUTHORIZED' },
        { option: 'Completed', value: 'COMPLETED' },
        { option: 'Failed', value: 'FAILED' },
        { option: 'Cancelled', value: 'CANCELLED' },
        { option: 'Voided', value: 'VOIDED' },
      ],
    }),
    components: {
      PaginationCardV2,
      DatePicker,
      Currency,
      LocalDateTime,
    },
    methods: {
      updatedPagination(data){
        this.searchFilters.page = data.page;
        this.searchFilters.limit = data.limit;
        this.update( );
      },

      getParams( ){
        let params = { ...this.searchFilters }; //shallow clone

        if(params.from_date == ''){
          params.from_date = null;
        }
        if(params.to_date == ''){
          params.to_date = null;
        }
        if(params.from_date != null){
          params.from_date = params.from_date+' 00:00:00';
        }
        if(params.to_date != null){
          params.to_date = params.to_date+' 23:59:59';
        }
        return params;
      },

      async update() {
        this.busy = true;
        let params = this.getParams();
        let queryString = Object.keys(params)
          .filter(key => params[key] !== null && params[key] !== undefined)  // Remove null or undefined values
          .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
          .join('&');
        const response = await this.Api.send('get','admin/finances/credit-card-transactions?' + queryString);
        this.busy = false;
        if (response.success) {
          this.listData = response.data;
          this.paginationData = {
            data: response.data.data,
            count: response.data.count,
            pages: response.data.pages
          };
        } else {
          this.listData = [];
        }
      },
      downloadCSV( )
      {
        this.update( );
        let params = this.getParams();
        params['session-token'] = this.$store.state.session.token;
        let queryString = Object.keys(params)
          .filter(key => params[key] !== null && params[key] !== undefined)  // Remove null or undefined values
          .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
          .join('&');
        //const url = this.linkBase + 'admin/finances/credit-card-transactions?session-token=' + this.$store.state.session.token;
        const url = this.linkBase + 'admin/finances/credit-card-transactions/csv?' + queryString;
        window.location.href = url;
      },
    },
    created() {
        this.update();
    },
};
</script>
