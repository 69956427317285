<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12">
              <CallQueueListCard
                  :small="$vuetify.breakpoint.xsOnly"
                  :filter="filter"
                  @update="updateFilter"
                  show-filters
                  />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import filterMixin from '../../mixins/filterMixin';
import CallQueueListCard from "../../components/cards/CallQueues/CallQueueListCard";
export default {
    name: 'CallQueueList',
    mixins: [filterMixin],
    data: () => ({
        filter: {
            active: 'Y',
            id: null,
            limit: 10,
            page: 1,
        },
    }),
    components: {
      CallQueueListCard,
    }
};
</script>
