<template>
    <v-container fluid>
      <BasicCard :loading="isBusy || !exemptionData" no-skeleton title="Partner Commission Report Exemptions">
        <v-card-title>
          <v-row>
            <v-col cols="11">
              Exemptions
            </v-col>
            <v-col>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn  v-on="on"
                          class="secondary"
                          block
                          outlined
                          @click="createDialog = true"
                          :loading="isBusy"
                  >+</v-btn>
                </template>
                <span>Add new exemption</span>
              </v-tooltip>
          </v-col>
          </v-row>
        </v-card-title>
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-data-table
                  :headers="headers"
                  :items="items"
                  :items-per-page="5"
                  class="elevation-1"
                  item-key="i_rep"
                  v-model="selected"
                  :show-select="false"
              >
                <template v-slot:top></template>
                <template v-slot:[headerSlot]=""></template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                      small
                      @click="deleteExemption(item.id)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </BasicCard>
      <AddPartnerCommissionReportExemptionDialog
          v-model="createDialog"
      />
    </v-container>
</template>

<script>
import BasicCard from '../../../components/cards/templates/BasicCard';
import apiMixin from '../../../mixins/apiMixin';
import AddPartnerCommissionReportExemptionDialog
  from "../../../components/dialogs/PartnerCommissionReports/AddPartnerCommissionReportExemptionDialog";
import refreshListenerMixin from "../../../mixins/refreshListenerMixin";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
export default {
    name: 'PartnerCommissionReportExemptionView',
    mixins: [apiMixin,refreshListenerMixin],
    components: {AddPartnerCommissionReportExemptionDialog, BasicCard },
    data: () => ({
      loading: false,
      busy: false,
      headerSlot: 'header.data-table-select',
      exemptionData: null,
      createDialog:false,
      items:[],
      selected:[],
      headers: [
        {
          text: 'Code/refnum',
          align: 'start',
          sortable: true,
          value: 'refnum',
        },
        {
          text: 'iRep',
          sortable: true,
          value: 'i_rep',
        },
        {
          text: 'Type',
          sortable: true,
          value: 'type_tag',
        },
        {
          text: 'Company',
          sortable: true,
          value: 'company',
        },
        {
          text: 'Email',
          sortable: true,
          value: 'email',
        },
        { text: '', value: 'actions', sortable: false },
      ],
    }),

    created: function( ){
      this.update( );
    },
    methods: {
      search: function( ){
        this.items = this.exemptionData;
      },
      async update() {
        this.busy = true;

        const response = await this.Api.send('get','partner-commission-report-exemptions');

        this.busy = false;
        if (response.success) {
          this.exemptionData = response.data
          this.search( );
        } else {
          this.exemptionData = null;
        }
      },
      async deleteExemption(id) {
        this.busy = true;

        const response = await this.Api.send('delete','partner-commission-report-exemptions/'+id);

        this.busy = false;
        if (response.success) {
          this.showGlobalSuccessMessage('Exemption deleted successfully.');
          this.update();
        }
      },
    },
    computed: {
      isBusy: function( ){
        return this.loading || this.busy;
      },
    },
};
</script>
