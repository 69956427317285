<template>
  <v-container fluid>
    <v-card class="pa-2 mb-2">
      <v-row>
        <v-col cols="12" md="4" lg="2">
          <DatePicker
            v-model="filter.start_date"
            label="Start date"
            show-clear
            hide-details
            dense
            :loading="busy"
            type="month"
          />
        </v-col>
        <v-col cols="12" md="4" lg="2">
          <DatePicker
            v-model="filter.end_date"
            label="End date"
            show-clear
            hide-details
            dense
            :loading="busy"
            type="month"
          />
        </v-col>
        <v-col cols="12" md="4" lg="2">
          <v-text-field
            outlined
            clearable
            dense
            label="Minimum Loss Percent"
            :disabled="busy || filter.zero_last_bill === true "
            v-model="filter.reduction_threshold"
            @keydown.enter="runReport"
            hint="Return records with Loss Percent >= Minimum Loss Percent."
            persistent-hint
            append-icon="mdi-percent"
            type="number"
            min="0"
            max="100"
          />
        </v-col>
        <v-col cols="12" md="4" lg="2">
          <v-switch
            v-model="filter.zero_last_bill"
            inset
            :label="filter.zero_last_bill === true ? 'Zero last bill' : 'Non zero last bill'"
          ></v-switch>
        </v-col>
        <v-col cols="12"  md="4" lg="2">
          <v-text-field
            hide-details
            outlined
            clearable
            dense
            label="Customer name or i_customer"
            :disabled="busy"
            v-model="filter.name_keyword"
            @keydown.enter="runReport"
          />
        </v-col>
        <v-col cols="6" md="2" lg="1">
          <v-btn
            outlined
            block
            height="40"
            :loading="busy"
            @click="csvTog = false; runReport()"
          >Run</v-btn>
        </v-col>
        <v-col cols="6" md="2" lg="1">
          <v-btn
            outlined
            block
            height="40"
            :loading="busy"
            @click="csvTog = true; runReport()"
          >CSV</v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="pa-2 mb-2" v-if="responseData != null">
      <div style="font-size:smaller;">Note: If a customer has a revenue loss percent less than zoro the value is replace with 'NA'. A negative loss is considered a gain.</div>
    </v-card>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            fixed-header
            height="100vh"
            :items="responseData == null ? [] : responseData"
            :items-per-page="50"
            class="elevation-1"
            item-key="i_customer"
            :loading="busy"
            loading-text="Processing results... Please wait"
          >
            <template v-slot:[`item.i_customer`]="{ item }">
              <router-link :to="{name: 'CustomerSummary', params: {customerId: item.i_customer}}">{{item.i_customer}}</router-link>
            </template>
            <template v-slot:[`item.partner_name`]="{ item }">
              <router-link v-if="item.i_rep!=null && item.i_rep!=''" :to="{name: 'AdminPartnerSummary', params: {partnerId: item.i_rep}}">{{item.partner_name}}</router-link>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <router-link :to="{name: 'CustomerSummary', params: {customerId: item.i_customer}}">{{item.name}}</router-link>
            </template>
            <template v-slot:[`item.average-cal`]="{ item }">
              <Currency
                :value="item['average-cal']"
              />
            </template>
            <template v-slot:[`item.last-bill-total`]="{ item }">
              <Currency
                :value="item['last-bill-total']"
              />
            </template>
            <template v-slot:[`item.revenue-loss-difference-cal`]="{ item }">
              <Currency
                :value="item['revenue-loss-difference-cal']"
              />
            </template>
            <template v-slot:[`item.revenue-loss-percent-cal`]="{ item }">
              <span v-if="item['revenue-loss-percent-cal'] == null">N/A</span>
              <span v-else>{{item['revenue-loss-percent-cal']}}%</span>
            </template>
            <template v-slot:[`item.graph-revenue`]="{ item }">
              <v-sparkline
                :value="item['graph-revenue']"
                width="200"
                height="70"
                padding="20"
                stroke-linecap="round"
                smooth
              >
              </v-sparkline>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

</template>

<script>
import DatePicker from "../../../components/pieces/Forms/DatePicker.vue";
import Currency from "../../../components/pieces/Currency.vue";
import AdminReportMixin from "../../../mixins/AdminReportMixin";
export default {
  name: 'CustomerChurnReport',
  mixins: [AdminReportMixin],
  data: () => ({
    //linkBase: process.env.VUE_APP_API_BASE || '/api/',
    filter: {
      start_date:null,
      end_date:null,
      reduction_threshold:null,
      zero_last_bill:null,
      name_keyword:null,
    },
    //previousQueryString:null,
    busy: false,
    csvTog: false,
    headers: [
      {
        text: 'Period Revenue',
        sortable: false,
        value: 'graph-revenue',
        width: '200px'
      },
      {
        text: 'i_customer',
        align: 'start',
        sortable: true,
        value: 'i_customer',
      },
      {
        text: 'Partner Name',
        sortable: true,
        value: 'partner_name',
      },
      {
        text: 'Customer Name',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Previous Months Average Revenue',
        sortable: true,
        value: 'average-cal',
      },
      {
        text: 'Last Month Revenue',
        sortable: true,
        value: 'last-bill-total',
      },
      {
        text: 'Difference',
        sortable: true,
        value: 'revenue-loss-difference-cal',
      },
      {
        text: 'Loss Percent',
        sortable: true,
        value: 'revenue-loss-percent-cal',
      },
    ],
    booleanFilterKeys:['zero_last_bill']
  }),
  components: {
    Currency,
    DatePicker,
  },
  methods: {
    runReport(){
      if(this.filter.start_date == null || this.filter.end_date == null){
        this.showGlobalErrorMessage('Invalid Start/End dates');
        return;
      }
      this.updateRouteWithFilters();
      this.update( );
    },
    update( ){
      const params = {
        start_date:this.filter.start_date,
        end_date:this.filter.end_date,
        zero_last_bill:this.filter.zero_last_bill,
      };

      // Default the zero_last_bill to false
      if(params.zero_last_bill == null){
        params.zero_last_bill = false;
      }

      if(this.filter.reduction_threshold != null){
        params.reduction_threshold = this.filter.reduction_threshold;
      }
      if(this.filter.name_keyword != null){
        params.name_keyword = this.filter.name_keyword
      }
      this.updateReport(params,'admin/reporting/customer-churn');
    },
    updateStartDate(data) {
      this.filter.start_date = data;
    },
    updateEndDate(data) {
      this.filter.end_date = data;
    },
    formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month].join('-');
    },
    subtractMonth(date) {
      let d = date.getDate();
      date.setMonth(date.getMonth() - 1);
      if (date.getDate() != d) {
        date.setDate(0);
      }
      return date;
    },
    runReportOnNewPage( ){
      if(!this.$route.query.start_date || !this.$route.query.end_date){
        let end_date = new Date( );
        end_date = this.subtractMonth(end_date);
        end_date = this.subtractMonth(end_date);

        let start_date = new Date( );
        start_date = this.subtractMonth(start_date);
        start_date = this.subtractMonth(start_date);
        start_date = this.subtractMonth(start_date);

        this.updateStartDate(this.formatDate(start_date));
        this.updateEndDate(this.formatDate(end_date));

        this.filter.zero_last_bill = true;
      }

      this.runReport();
    }
  },
};
</script>
