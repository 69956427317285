<template>
  <v-container fluid>
    <v-card class="pa-2 mb-2">
      <v-row>
        <v-col cols="12" md="4" lg="2">
          <DatePicker
            v-model="filter.start_date"
            label="Start date"
            show-clear
            hide-details
            dense
            :loading="busy"
            type="month"
          />
        </v-col>
        <v-col cols="12" md="4" lg="2">
          <DatePicker
            v-model="filter.end_date"
            label="End date"
            show-clear
            hide-details
            dense
            :loading="busy"
            type="month"
            :allowedDates="validEndDates"
          />
        </v-col>
        <v-col cols="12"  md="4" lg="2">
          <v-text-field
            hide-details
            outlined
            clearable
            dense
            label="Partner name or i_rep"
            :disabled="busy"
            v-model="filter.name_keyword"
            @keydown.enter="runReport"
          />
        </v-col>
        <v-col cols="6" md="2" lg="2">
          <v-checkbox
            v-model="show_extra_columns"
            label="Show Extra columns"
            :value="true"
          ></v-checkbox>
        </v-col>
        <v-col cols="6" md="2" lg="2">
          <v-checkbox
            v-model="show_graph_points"
            label="Show Graph points"
            :value="true"
          ></v-checkbox>
        </v-col>
        <v-col cols="6" md="2" lg="1">
          <v-btn
            outlined
            block
            height="40"
            :loading="busy"
            @click="csvTog = false; runReport()"
          >Run</v-btn>
        </v-col>
        <v-col cols="6" md="2" lg="1">
          <v-btn
            outlined
            block
            height="40"
            :loading="busy"
            @click="csvTog = true; runReport()"
          >CSV</v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="pa-2 mb-2">
      <div style="font-size:smaller;">
        Note that negative values indicate a credit, hence a negative revenue for Breeze Connect. Credit is treated as a reduction of revenue. EG -300 (credit) to zero is counted as 300 revenue.
        <span v-if="show_extra_columns">
          The Min and Max percent values do not necessarily corresponds with the Min and Max values. For example its possible the Minimum growth value could provide the Maximum percent growth.
          EG 100 to 500 is 400 growth and 400% growth. Then 500 to 1000 is 500 growth and yet 100% growth.
        </span>
      </div>
    </v-card>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          fixed-header
          height="100vh"
          :items="responseData != null ? responseData : []"
          :items-per-page="50"
          class="elevation-1"
          item-key="i_customer"
          :loading="busy"
          loading-text="Processing results... Please wait"
        >
          <template v-slot:[`item.min-monthly-growth`]="{ item }"><Currency :value="item['min-monthly-growth']" /></template>
          <template v-slot:[`item.max-monthly-growth`]="{ item }"><Currency :value="item['max-monthly-growth']" /></template>
          <template v-slot:[`item.average-monthly-growth`]="{ item }"><Currency :value="item['average-monthly-growth']" /></template>
          <template v-slot:[`item.total-growth`]="{ item }"><Currency :value="item['total-growth']" /></template>
          <template v-slot:[`item.total-revenue`]="{ item }"><Currency :value="item['total-revenue']" /></template>

          <template v-slot:[`item.min-monthly-growth-percent`]="{ item }">{{item['min-monthly-growth-percent']}}%</template>
          <template v-slot:[`item.max-monthly-growth-percent`]="{ item }">{{item['max-monthly-growth-percent']}}%</template>
          <template v-slot:[`item.average-monthly-growth-percent`]="{ item }">{{item['average-monthly-growth-percent']}}%</template>

          <template v-slot:[`item.i_rep`]="{ item }">
            <router-link :to="{name: 'AdminPartnerSummary', params: {partnerId: item.i_rep}}">{{item.i_rep}}</router-link>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <router-link :to="{name: 'AdminPartnerSummary', params: {partnerId: item.i_rep}}">{{item.name}}</router-link>
          </template>
          <template v-slot:[`item.graph-growth`]="{ item }">
            <div :style="show_graph_points ? 'width:300px' : ''">
              <v-sparkline
                v-if="item['graph-growth'].length > 1"
                :labels="show_graph_points ? item['graph-growth-labels'] : []"
                label-size="11"
                :value="item['graph-growth']"
                width="300"
                height="70"
                padding="20"
                stroke-linecap="round"
                smooth
              >
              </v-sparkline>
            </div>
            <span v-if="item['graph-growth'].length < 2">N/A</span>
          </template>
          <template v-slot:[`item.graph-revenue`]="{ item }">
            <div :style="show_graph_points ? 'width:300px' : ''">
              <v-sparkline
                :labels="show_graph_points ? item['graph-revenue-labels'] : []"
                label-size="11"
                :value="item['graph-revenue']"
                width="300"
                height="70"
                padding="20"
                stroke-linecap="round"
                color="grey"
                smooth
              >
              </v-sparkline>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import DatePicker from "../../../components/pieces/Forms/DatePicker.vue";
import Currency from "../../../components/pieces/Currency.vue";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import AdminReportMixin from "../../../mixins/AdminReportMixin";
export default {
  name: 'FastestPartnerRevenueGrowthReport',
  mixins: [GlobalHelperMixin,AdminReportMixin],
  data: () => ({
    filter: {
      start_date:null,
      end_date:null,
      name_keyword:null,
    },
    show_extra_columns:false,
    show_graph_points:false,
    validEndDates:[],
    busy: false,
    responseData:null,
  }),
  components: {
    Currency,
    DatePicker,
  },
  methods: {
    runReport( ){
      if(this.filter.start_date == null || this.filter.end_date == null){
        this.showGlobalErrorMessage('Invalid Start/End dates');
        return;
      }
      this.updateRouteWithFilters( );
      this.update( );
    },
    update( ){
      const params = {
        start_date:this.filter.start_date,
        end_date:this.filter.end_date,
      };
      if(this.filter.name_keyword != null){
        params.name_keyword=this.filter.name_keyword
      }
      this.updateReport(params,'admin/reporting/fastest-representative-growth-report');
    },
    updateStartDate(data) {
      this.filter.start_date = data;
    },
    updateEndDate(data) {
      this.filter.end_date = data;
    },

    runReportOnNewPage( ){
      if(!this.$route.query.start_date || !this.$route.query.end_date){
        let end_date = new Date( );
        end_date = this.subtractMonth(end_date);
        end_date = this.subtractMonth(end_date);

        let start_date = new Date( );
        start_date = this.subtractMonth(start_date);
        start_date = this.subtractMonth(start_date);
        start_date = this.subtractMonth(start_date);

        this.updateStartDate(this.formatDate(start_date));
        this.updateEndDate(this.formatDate(end_date));
      }
      this.runReport();
    }
  },
  computed:{
    start_date( ){
      if(this.filter.start_date){
        return this.filter.start_date;
      }else{
        return null;
      }
    },
    headers(){
      let ret = [
        {
          text: 'i_rep',
          align: 'start',
          sortable: true,
          value: 'i_rep',
        },
        {
          text: 'Name',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Period Growth',
          sortable: false,
          value: 'graph-growth',
          width: '200px'
        },
        {
          text: 'Period Revenue',
          sortable: false,
          value: 'graph-revenue',
          width: '200px'
        }
      ];

      if(this.show_extra_columns) {
        ret.push(
          {
            text: 'Minimum Monthly Growth',
            sortable: true,
            value: 'min-monthly-growth',
          });
        ret.push({
          text: 'Minimum Monthly Growth Percent',
          sortable: true,
          value: 'min-monthly-growth-percent',
        });
        ret.push(
          {
            text: 'Maximum Monthly Growth',
            sortable: true,
            value: 'max-monthly-growth',
          });
        ret.push({
          text: 'Maximum Monthly Growth Percent',
          sortable: true,
          value: 'max-monthly-growth-percent',
        });
        ret.push({
          text: 'Average Monthly Growth',
          sortable: true,
          value: 'average-monthly-growth',
        });

      }
      ret.push({
        text: 'Average Monthly Growth Percent',
        sortable: true,
        value: 'average-monthly-growth-percent',
      });
      ret.push({
        text: 'Total Growth',
        sortable: true,
        value: 'total-growth',
      });
      ret.push({
        text: 'Total Revenue',
        sortable: true,
        value: 'total-revenue',
      });
      return ret;
    }
  },
  watch: {
    start_date(value) {
      if(value != null){
        //process the value dates for the end date.
        let startDate = new Date(value+"-01");
        let startYear = startDate.getFullYear();
        let startMonth = startDate.getMonth();
        startMonth++;
        let endMonth = 12;
        let endYear = startYear;

        if(startMonth != 1){
          endYear++;
          endMonth = startMonth - 1;
        }

        let workingMonth = startMonth + 1;
        let workingYear = startYear;
        if(workingMonth == 13){
          workingMonth = 1;
          workingYear++;
        }

        let ret = [];

        while(workingYear < endYear || (workingYear == endYear && workingMonth <= endMonth)){
          if(workingMonth < 10){
            ret.push(workingYear+'-0'+workingMonth);
          }else{
            ret.push(workingYear+'-'+workingMonth);
          }
          if(workingMonth == 12){
            workingMonth = 1;
            workingYear++;
          }else{
            workingMonth++;
          }
        }

        this.validEndDates = ret;
      }
    },
  },
};
</script>
