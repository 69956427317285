<template>
    <BasicCard title="Business Details" :loading="loading">
        <p class="title text--primary mt-1">{{ data.companyname }}</p>
        <v-row dense>
            <v-col cols="4">
                <span class="font-weight-bold">Company Name</span>
            </v-col>
            <v-col cols="8">
                {{ data.companyname }}
            </v-col>
            <v-col cols="4" v-if="data.enquiry_type === 1">
                <span class="font-weight-bold">Dealer Code</span>
            </v-col>
            <v-col cols="8" v-if="data.enquiry_type === 1">
                <IconWithTooltip
                    v-if="!data.refnum"
                    small
                    tooltip="No dealer code!"
                />
                {{ data.refnum }}
            </v-col>
            <v-col cols="4">
                <span class="font-weight-bold">ABN / ACN</span>
            </v-col>
            <v-col cols="8">
                <IconWithTooltip
                    v-if="!data.tax_id"
                    small
                    tooltip="No ABN/ACN!"
                />
                {{ data.tax_id }}
            </v-col>
            <v-col cols="12">
                <Alert
                    class="mt-4"
                    v-if="!data.refnum"
                    message="No dealer code provided!"
                    type="warning"
                />
            </v-col>
        </v-row>
        <template v-slot:actions>
            <ButtonWithTooltip
                text="ABN Search"
                tooltip="Search abr.business.gov.au for ABN"
                icon="mdi-magnify"
                flat
                href-new-window
                :href="'https://abr.business.gov.au/ABN/View?id=' + data.tax_id"
            />
        </template>
    </BasicCard>
</template>

<script>
import BasicCard from '@/components/cards/templates/BasicCard';
import IconWithTooltip from '../../pieces/IconWithTooltip';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import Alert from '../../pieces/Alert';
export default {
    name: 'SignupBusinessDetailsCard',
    components: { Alert, ButtonWithTooltip, IconWithTooltip, BasicCard },
    props: ['data', 'loading'],
    data() {
        return {
            showDialog: false,
        };
    },
    methods: {
        closeModal(refresh) {
            this.showDialog = false;
            if (refresh) {
                this.$emit('update');
            }
        },
    },
};
</script>
