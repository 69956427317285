var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('PaginationCard',{attrs:{"page":_vm.filter.page,"data":_vm.data,"limit":_vm.filter.limit,"loading":_vm.loading},on:{"updateLimit":_vm.updateLimit,"updatePage":_vm.updatePage}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[(_vm.$vuetify.breakpoint.smAndUp)?_c('th',[_vm._v(" Cron ID ")]):_vm._e(),_c('th',[_vm._v("Script Name")]),_c('th',[_vm._v("Status")])])]),_c('tbody',_vm._l((_vm.data.data),function(item,i){return _c('tr',{key:i},[(_vm.$vuetify.breakpoint.smAndUp)?_c('td',[_vm._v(" "+_vm._s(item.cron_id)+" ")]):_vm._e(),_c('router-link',{staticStyle:{"cursor":"pointer"},attrs:{"tag":"td","link":"","to":'/admin/system/cron/' + item.cron_id}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(item.scriptname))])]),_c('td',[(item.running)?_c('span',[_c('v-progress-circular',{staticClass:"mr-2",attrs:{"width":3,"size":20,"color":"primary","indeterminate":""}}),_vm._v("Running (started "),_c('LocalDateTime',{attrs:{"value":item.last_executed,"relative":""}}),_vm._v(") ")],1):_vm._e(),(
                                            item.disabled && !item.running
                                        )?_c('span',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error"}},[_vm._v("mdi-alert-octagon")]),_vm._v("Disabled ")],1):_vm._e(),(
                                            !item.disabled &&
                                            !item.running &&
                                            item.last_completed
                                        )?_c('span',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success"}},[_vm._v("mdi-check-circle")]),_vm._v("Last completed "),(item.last_completed)?_c('LocalDateTime',{attrs:{"value":item.last_completed,"relative":""}}):_vm._e()],1):_vm._e(),(
                                            !item.disabled &&
                                            !item.running &&
                                            !item.last_completed
                                        )?_c('span',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"warning"}},[_vm._v("mdi-alert")]),_vm._v("Never completed ")],1):_vm._e()])],1)}),0)]},proxy:true}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }