<template>
    <BasicCard
        :loading="loading"
        class="background_accent"
    >
        <template v-slot:feature>
            <v-chip class="primary ml-2">Sign-Up</v-chip>
            <v-icon right x-large>mdi-plus-circle</v-icon>
        </template>
        <p class="title text--primary mt-1">
            <span v-if="data.enquiry_type === 1">New Partner Request</span>
            <span v-if="data.enquiry_type === 2">New Customer Referral</span>
            <span v-if="data.enquiry_type !== 1 && data.enquiry_type !== 2"
                >Unknown Request Type</span
            >
        </p>
        <v-row dense>
            <v-col cols="4">
                <span class="font-weight-bold">Sign-up ID</span>
            </v-col>
            <v-col cols="8">
                {{ data.signup_id }}
            </v-col>
            <v-col cols="4">
                <span class="font-weight-bold">Type</span>
            </v-col>
            <v-col cols="8">
                <span v-if="data.enquiry_type === 1">Partner</span>
                <span v-if="data.enquiry_type === 2">Customer</span>
                <span v-if="data.enquiry_type !== 1 && data.enquiry_type !== 2"
                    >Unknown</span
                >
            </v-col>
            <v-col cols="4">
                <span class="font-weight-bold">Created</span>
            </v-col>
            <v-col cols="8">
                <LocalDateTime :value="data.submitted" />
            </v-col>
            <v-col cols="4" v-if="data.approved">
                <span class="font-weight-bold">Approved</span>
            </v-col>
            <v-col cols="8" v-if="data.approved">
                <LocalDateTime :value="data.approved" />
            </v-col>
            <v-col cols="4" v-if="data.completed">
                <span class="font-weight-bold">Completed</span>
            </v-col>
            <v-col cols="8" v-if="data.completed">
                <LocalDateTime :value="data.completed" />
            </v-col>
            <v-col cols="4" v-if="data.deleted">
                <span class="font-weight-bold">Deleted</span>
            </v-col>
            <v-col cols="8" v-if="data.deleted">
                <LocalDateTime :value="data.deleted" />
            </v-col>
            <v-col cols="4">
                <span class="font-weight-bold">Status</span>
            </v-col>
            <v-col cols="8">
                <SignupStatus :data="data" />
            </v-col>
        </v-row>
        <Alert
            class="mt-4"
            v-if="!data.approved"
            message="This application has just been submitted and needs to be reviewed."
        />

        <template v-slot:actions v-if="!data.deleted && !data.completed">
            <ButtonWithTooltip
                v-if="data.approved"
                text="Restart"
                tooltip="Reset this workflow and start again"
                icon="mdi-undo"
                color="warning"
                @click="$emit('unapprove')"
                flat
            />
            <ButtonWithTooltip
                text="Reject"
                tooltip="Reject this request"
                icon="mdi-delete"
                color="error"
                @click="$emit('reject')"
                flat
            />
        </template>
    </BasicCard>
</template>

<script>
import BasicCard from '@/components/cards/templates/BasicCard';
import LocalDateTime from '../../pieces/LocalDateTime';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import Alert from '../../pieces/Alert';
import SignupStatus from '../../pieces/Signup/SignupStatus';
export default {
    name: 'SignupCard',
    components: {
        SignupStatus,
        Alert,
        ButtonWithTooltip,
        LocalDateTime,
        BasicCard,
    },
    props: ['data', 'loading'],
    data() {
        return {
            showDialog: false,
        };
    },
    methods: {
        closeModal(refresh) {
            this.showDialog = false;
            if (refresh) {
                this.$emit('update');
            }
        },
    },
};
</script>
