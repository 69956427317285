<template>
    <PaginationCard
        :page="filter.page"
        :data="data"
        :limit="filter.limit"
        :loading="loading || isLoading"
        title="Cron Task History"
        v-on:updateLimit="updateLimit"
        v-on:updatePage="updatePage"
    >
        <v-simple-table>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th v-if="$vuetify.breakpoint.smAndUp">ID</th>
                        <th>Started</th>
                        <th>Ended</th>
                        <th>Duration</th>
                        <th>Success</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(item, i) in data.data"
                        :key="i"
                        v-bind:class="{
                            'red darken-4':
                                $vuetify.theme.dark && !item.success,
                            'red accent-1':
                                !$vuetify.theme.dark && !item.success,
                        }"
                    >
                        <td v-if="$vuetify.breakpoint.smAndUp">
                            {{ item.cron_history_id }}
                        </td>
                        <td>
                            <LocalDateTime
                                :value="item.started"
                                show-time
                                show-seconds
                                hide-tooltip
                            />
                        </td>
                        <td>
                            <LocalDateTime
                                v-if="item.ended"
                                :value="item.ended"
                                show-time
                                show-seconds
                                hide-tooltip
                            />
                            <span v-if="!item.ended">N/A</span>
                        </td>
                        <td>
                            <span v-if="item.ended">{{
                                item.started | differenceInSeconds(item.ended)
                            }}</span>
                            <span v-if="!item.ended">Currently running</span>
                        </td>
                        <td>{{ item.success ? 'Yes' : 'No' }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </PaginationCard>
</template>

<script>
import PaginationCard from '@/components/cards/templates/PaginationCard';
import LocalDateTime from '../../pieces/LocalDateTime';
import apiMixin from '../../../mixins/apiMixin';
export default {
    name: 'CronHistoryCard',
    mixins: [apiMixin],
    data: () => ({
        filter: {
            limit: 25,
            page: 1,
            status: null,
        },
        isLoading: false,
        data: [],
    }),
    filters: {
        differenceInSeconds: function (value, end) {
            if (!value || !end) return '';
            var diff =
                new Date(value + 'Z').getTime() - new Date(end + 'Z').getTime();
            return (
                Math.abs(diff / 1000)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' seconds'
            );
        },
    },
    components: {
        LocalDateTime,
        PaginationCard,
    },
    props: {
        limit: {
            type: String,
            default: '25',
        },
        id: {
            type: Number,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        async update() {
            this.isLoading = true;
            let params = { limit: this.filter.limit, page: this.filter.page };
            if (this.filter.status !== '') {
                params.status = this.filter.status;
            }
            if (this.id > 0) {
                params.id = this.id;
            }
            const response = await this.Api.send('post','admin/cron/history', params);
            this.isLoading = false;
            if (response.success) {
                this.updateData(response.data);
            } else {
                this.updateFailed(response.error, response.errorDetail);
            }
        },
        updatePage(data) {
            this.filter.page = data;
            this.update();
        },
        updateLimit(data) {
            this.filter.limit = data;
            this.filter.page = 1;
            this.update();
        },
        updateStatus(data) {
            this.filter.status = data;
            this.filter.page = 1;
            this.update();
        },
        updateData(data) {
            this.data = data;
        },
        updateFailed() {
            this.data = [];
        },
    },
    watch: {
        loading: function (value) {
            if (!value) {
                this.update();
            }
        },
        status: function (value) {
            this.filter.status = value;
            this.update();
        },
    },
    created() {
        this.filter.status = this.status;
        this.filter.limit = parseInt(this.limit);
        if (!this.loading) {
            this.update();
        }
    },
};
</script>
