<template>
    <v-container fluid>
        <v-row dense>
            <!-- non-dismissable alert only shows if user is inactive -->
            <v-col cols="12" v-if="data.active === 0">
                <v-alert type="error" class="mb-0">This user is inactive!</v-alert>
            </v-col>
          <v-col cols="12" md="3" sm="0" >

          </v-col>
            <!-- Cron Details Card -->
            <v-col cols="12" md="6" sm="12">
                <UserCard
                    :loading="loading"
                    :data="data"
                />
            </v-col>
            <v-col cols="12" md="3" sm="0">

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import BasicCard from '../../../components/cards/templates/BasicCard';
import apiMixin from '../../../mixins/apiMixin';
import refreshListenerMixin from '../../../mixins/refreshListenerMixin';
import UserCard from '../../../components/cards/User/UserCard';
export default {
    name: 'UserDetails',
    mixins: [apiMixin, refreshListenerMixin],
    data: () => ({
        loading: true,
        data: {},
    }),
    components: {
        UserCard,
    },
    methods: {
        async get(id) {
            this.loading = true;
            const response = await this.Api.send('post','admin/users/' + id + '/info');
            this.loading = false;
            this.data = response.success ? response.data : {};
            this.loading = false;
        },
        update() {
            this.get(this.$route.params.userId);
        },
    },
    created() {
        this.update();
    },
};
</script>
