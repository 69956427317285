<template>
    <ComplexDialog
        v-model="visible"
        title="Approve New Partner Application"
        persistent
    >
        <v-form ref="form" v-model="isValid">
            <v-row dense>
                <v-col cols="12">
                    <Alert
                        type="info"
                        message="Please check these details carefully. You cannot edit this information once approved."
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        label="Company Name *"
                        v-model="formData.companyname"
                        required
                        :disabled="busy"
                        :rules="rules.requiredOnly"
                        hint="This must be the full legal entity name, i.e. Breeze Connect Pty Ltd"
                        persistent-hint
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        label="Dealer Code *"
                        v-model="formData.refnum"
                        required
                        :disabled="busy"
                        :rules="rules.requiredOnly"
                        hint="You must confirm this using BUS"
                        persistent-hint
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        label="ABN *"
                        v-model="formData.tax_id"
                        required
                        :disabled="busy"
                        :rules="rules.abn"
                        hint="You must search ABN/ACN online"
                        persistent-hint
                    ></v-text-field>
                </v-col>
<!--                <v-col cols="12">-->
<!--                    Is this a legacy (existing Breeze Connect 1.0) partner? *-->
<!--                    <v-radio-group v-model="legacy" row>-->
<!--                        <v-radio label="Yes" value="Y"></v-radio>-->
<!--                        <v-radio label="No" value="N"></v-radio>-->
<!--                    </v-radio-group>-->
<!--                </v-col>-->
                <v-col cols="12">
                    <v-checkbox
                        class="pa-0 ma-0"
                        v-model="confirmed_bus"
                        label="I have checked this partner's details in BUS *"
                    ></v-checkbox>
                    <v-checkbox
                        class="pa-0 ma-0"
                        v-model="confirmed_abn"
                        label="I have confirmed this ABN/ACN by searching abr.gov.au *"
                    ></v-checkbox>
                </v-col>
            </v-row>
        </v-form>
        <template v-slot:actions>
            <v-btn
                text
                v-on:click="update"
                color="success"
                :disabled="
                    !isValid || !confirmed_abn || !confirmed_bus || !legacy
                "
            >
                <v-icon>mdi-check</v-icon>
                Approve
            </v-btn>
        </template>
    </ComplexDialog>
</template>

<script>
import Alert from '@/components/pieces/Alert';
import ComplexDialog from '../templates/ComplexDialog';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
export default {
    name: 'ApprovePartnerSignup',
    components: { ComplexDialog, Alert },
    mixins: [apiMixin, dialogMixin],
    data() {
        return {
            busy: false,
            formData: JSON.parse(JSON.stringify(this.data)),
            display: true,
            isValid: false,
            confirmed_bus: false,
            confirmed_abn: false,
            legacy: 'N', //hard coded after update
            rules: {
                abn: [
                    (v) => !!v || 'Required',
                    (v) =>
                        /^\d{9,11}$/.test(v) ||
                        'Invalid ABN/ACN - should be 9 or 11 digits',
                ],
                requiredOnly: [(v) => !!v || 'Required'],
            },
        };
    },
    props: {
        data: {},
    },
    created() {
        if (this.data.i_commission_plan) {
            this.form.i_commission_plan =
                this.data.i_commission_plan.toString();
        }
    },
    methods: {
        async update(event) {
            event.preventDefault();
            if (!this.isDirty) {
                this.approve();
                return;
            }
            this.busy = true;
            const response = await this.Api.send('post',
                'signup/' + this.formData.signup_id + '/updatepartner',
                {
                    companyname: this.formData.companyname,
                    refnum: this.formData.refnum,
                    tax_id: this.formData.tax_id,
                    legacy_customer: this.legacy === 'Y' ? 1 : 0,
                }
            );
            if (response.success) {
                this.busy = false;
                this.approve();
            } else {
                this.busy = false;
            }
        },
        async approve() {
            this.busy = true;
            const response = await this.Api.send('post',
                'signup/' + this.formData.signup_id + '/approve',
                {}
            );
            if (response.success) {
                this.busy = false;
                this.$emit('save');
                this.visible = false;
            } else {
                this.busy = false;
            }
        },
    },
    watch: {
        visible: function (value) {
            if (this.value!==value) this.$emit('input', value);
            if (value) {
                this.formData = JSON.parse(JSON.stringify(this.data));
                this.confirmed_bus = false;
                this.confirmed_abn = false;
                // this.legacy = null;
                this.legacy = 'N';
            }
        },
    },
    computed: {
        isDirty: function () {
            if (this.formData.companyname !== this.data.companyname)
                return true;
            if (this.formData.refnum !== this.data.refnum) return true;
            if (this.formData.tax_id !== this.data.tax_id) return true;
            //Note legacy options have been removed.
            if(this.legacy !== 'N'){
              alert("legacy logic needs updating.")
            }
            // if (this.legacy === 'Y' && this.formData.legacy_customer !== 1)
            //     return true;
            // if (this.legacy === 'N' && this.formData.legacy_customer !== 0)
            //     return true;
            return false;
        },
    },
};
</script>
