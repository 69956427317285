<template>
    <v-container fluid>
        <v-row dense>
            <v-col>
                <v-card class="pa-2 mb-2">
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                hide-details
                                outlined
                                clearable
                                dense
                                label="Name"
                                :disabled="loading"
                                v-model="localFilter.name"
                                @keydown.enter="updateLocalFilter"
                            />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-select
                                hide-details
                                outlined
                                dense
                                label="Status"
                                v-model="localFilter.hidden"
                                :disabled="loading"
                                :items="statusOptions"
                                item-text="option"
                                item-value="value"
                            />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-btn
                                outlined
                                block
                                height="40"
                                :loading="loading"
                                @click="updateLocalFilter"
                            >Search</v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12">
                <PaginationCard
                    :page="filter.page"
                    :data="data"
                    :limit="filter.limit"
                    :loading="loading"
                    v-on:updateLimit="updateLimit"
                    v-on:updatePage="updatePage"
                    :no-skeleton="!firstLoad"
                >
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Partner Name</th>
                                    <th>Company Name</th>
                                    <th v-if="$vuetify.breakpoint.smAndUp">
                                        Contact
                                    </th>
                                    <th
                                        v-if="$vuetify.breakpoint.smAndUp"
                                        class="text-center"
                                    >
                                        Dealer Code
                                    </th>
                                    <th
                                        v-if="$vuetify.breakpoint.smAndUp"
                                        class="text-center"
                                    >
                                        Commission Plan
                                    </th>
                                    <th class="text-center">Status</th>
                                </tr>
                            </thead>
                            <v-slide-x-transition
                                tag="tbody"
                                hide-on-leave
                                group
                            >
                                <tr
                                    v-for="item in data.data"
                                    :key="item.i_rep"
                                    v-bind:class="{
                                        'red darken-4':
                                            $vuetify.theme.dark &&
                                            item.hidden === 'Y',
                                        'red lighten-4':
                                            !$vuetify.theme.dark &&
                                            item.hidden === 'Y',
                                    }"
                                >
                                    <td>
                                        <router-link
                                            :to="{
                                                name: 'AdminPartnerSummary',
                                                params: {
                                                    partnerId: item.i_rep,
                                                },
                                            }"
                                            class="font-weight-medium"
                                            >{{ item.name }}</router-link
                                        >
                                    </td>
                                    <td>{{ item.companyname }}</td>
                                    <td v-if="$vuetify.breakpoint.smAndUp">
                                        <span v-if="item.salutation"
                                            >{{ item.salutation }}
                                        </span>
                                        <span v-if="item.firstname"
                                            >{{ item.firstname }}
                                        </span>
                                        <span v-if="item.lastname">{{
                                            item.lastname
                                        }}</span>
                                        <span
                                            v-if="
                                                !item.salutation &&
                                                !item.firstname &&
                                                !item.lastname
                                            "
                                            >-</span
                                        >
                                    </td>
                                    <td
                                        v-if="$vuetify.breakpoint.smAndUp"
                                        class="text-center"
                                    >
                                        <span v-if="item.refnum">{{
                                            item.refnum
                                        }}</span>
                                        <span v-if="!item.refnum">-</span>
                                    </td>
                                    <td
                                        v-if="$vuetify.breakpoint.smAndUp"
                                        class="text-center"
                                    >
                                        <CommissionPlan
                                            small
                                            :commission-plan="
                                                item.i_commission_plan
                                            "
                                        />
                                    </td>
                                    <td class="text-center">
                                        <v-chip
                                            v-if="item.hidden === 'Y'"
                                            small
                                            color="red"
                                            text-color="white"
                                        >
                                            Inactive
                                        </v-chip>
                                        <v-chip
                                            v-if="item.hidden === 'N'"
                                            small
                                            color="green"
                                            text-color="white"
                                        >
                                            Active
                                        </v-chip>
                                    </td>
                                </tr>
                            </v-slide-x-transition>
                        </template>
                    </v-simple-table>
                </PaginationCard>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import filterMixin from '../../mixins/filterMixin';
import PaginationCard from '../../components/cards/templates/PaginationCard';
import CommissionPlan from '../../components/pieces/Partner/CommissionPlan';
import refreshListenerMixin from '../../mixins/refreshListenerMixin';
import apiMixin from '../../mixins/apiMixin';
import commissionPlanDataMixin from '../../mixins/commissionPlanDataMixin';
export default {
    name: 'PartnerList',
    mixins: [apiMixin, commissionPlanDataMixin, filterMixin, refreshListenerMixin],
    data: () => ({
        firstLoad: true,
        filter: {
            limit: 10,
            page: 1,
            hidden: 'N',
            name: null
        },
        localFilter: {
            name: null,
            hidden: 'N',
        },
        statusOptions: [
            { option: 'Active', value: 'N' },
            { option: 'Hidden', value: 'Y' },
        ],
        loading: false,
        data: [],
    }),
    components: {
        CommissionPlan,
        PaginationCard,
    },
    methods: {
        async update() {
            this.loading = true;
            let params = { limit: this.filter.limit, page: this.filter.page };
            this.localFilter.hidden = this.filter.hidden;
            if (this.filter.hidden !== null) params.hidden = this.filter.hidden;
            this.localFilter.name = this.filter.name;
            if (this.filter.name !== null) params.name = this.filter.name;
            const response = await this.Api.send('post','partners/list', params);
            this.loading = false;
            if (response.success) {
                this.updateData(response.data);
                this.firstLoad = false;
            } else {
                this.updateFailed(response.error, response.errorDetail);
            }
        },
        updateLocalFilter() {
            this.updateFilter({ ...this.localFilter, ...{ page: 1 } });
            this.update();
        },
        updatePage(data) {
            this.filter.page = data;
            this.update();
        },
        updateLimit(data) {
            this.filter.limit = data;
            this.filter.page = 1;
            this.update();
        },
        updateHidden(data) {
            this.filter.hidden = data;
            this.filter.page = 1;
            this.update();
        },
        updateData(data) {
            this.data = data;
        },
        updateFailed() {
            this.data = [];
        },
    },
    watch: {
        filter: {
            handler(val) {
                this.update();
            },
            deep: true
        },
    },
    created() {
        this.update();
    },
};
</script>
