<template>
  <div>
    <v-card class="pa-2 mb-2">
      <v-row>
        <v-col cols="12" sm="2">
          <v-btn @click="sendToXero" :disabled="isBusy" block large>Send invoices to Xero</v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-container fluid>
      <v-card class="pa-2 mb-2">
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="15"
          class="elevation-1"
          item-key="i_invoice"
          :loading="isBusy"
          loading-text="Loading... Please wait"
        >
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
/**
 * This page is to show the list of invoice that will be sent to Xero.
 * This was originally created for tronic
 */

import partnerDataMixin from '../../../mixins/partnerDataMixin';
import SimpleDialog from "../../../components/dialogs/templates/SimpleDialog.vue";
import ButtonWithTooltip from "../../../components/pieces/ButtonWithTooltip.vue";
import globalHelperMixin from "../../../mixins/GlobalHelperMixin";
export default {
    name: 'PartnerSummary',
    mixins: [partnerDataMixin, globalHelperMixin],
    components: {

    },

    data: () => ({
      busy:false,
      items:[],
      headers: [
        {
          text: 'i_invoice',
          align: 'start',
          sortable: true,
          value: 'i_invoice',
        },
        {
          text: 'i_parent',
          sortable: true,
          value: 'i_parent',
        },
        {
          text: 'invoice_status',
          sortable: true,
          value: 'invoice_status',
        },
        {
          text: 'customer_name',
          sortable: true,
          value: 'customer_name',
        },
        {
          text: 'prior_balance_paid',
          sortable: true,
          value: 'prior_balance_paid',
        },
        {
          text: 'amount_net',
          sortable: true,
          value: 'amount_net',
        },
        {
          text: 'taxes',
          sortable: true,
          value: 'taxes',
        },
      ],
    }),

    methods: {
      async getPreview()
      {
        this.busy = true;
        let params = {
          "issued_after":this.issuedAfter,
          "issued_before":this.issuedBefore
        };
        let queryString = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');
        let response = await this.Api.send('get', '/admin/xero/tronic-customer-invoices/preview?'+queryString);

        if (response.success) {
          this.items = response.data;
        }
        this.busy = false;
      },

      async sendToXero()
      {
        this.busy = true;
        let params = { issued_after: this.issuedAfter, issued_before: this.issuedBefore };
        let response = await this.Api.send('post', '/admin/xero/tronic-customer-invoices/send',params);

        if (response.success) {
          this.showGlobalSuccessMessage('Invoice(s) sent to Xero successfully.');
        }
        this.busy = false;
      },

    },
    computed: {
      isBusy(){
        return this.loading || this.busy;
      },
      issuedAfter( ){
          return this.$route.query.issued_after;
      },
      issuedBefore( ){
        return this.$route.query.issued_before;
      }
    },
  watch: {
    // Watch the entire $route object to detect any changes in query parameters
    // AS watching computed values fo not work as they are not bound as reactive.
    '$route'(to, from) {

      if (to.query.issued_after !== from.query.issued_after ||
        to.query.issued_before !== from.query.issued_before) {
        if(this.$route.query.issued_after != null && this.$route.query.issued_before != null){
          this.getPreview();
        }
      }
    },

  },
  created() {
    // Check on page load if the necessary queries are present and not null
    if (this.$route.query.issued_after && this.$route.query.issued_before) {
      this.getPreview();
    }
  }
};
</script>
