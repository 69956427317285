<template>
    <BasicCard title="Authorised Person Details" :loading="loading">
        <p class="title text--primary mb-2">
            <span v-if="data.firstname">{{ data.firstname }} </span>
            <span v-if="data.lastname">{{ data.lastname }}</span>
        </p>
        <p class="subtitle-1 text--primary" v-if="data.position">
            {{ data.position }}
        </p>
        <v-row dense v-if="data.contact_business_phone">
            <v-col cols="4" class="font-weight-bold"> Business Phone </v-col>
            <v-col cols="8">
                <PhoneNumber :value="data.contact_business_phone" />
            </v-col>
        </v-row>
        <v-row dense v-if="data.contact_mobile">
            <v-col cols="4" class="font-weight-bold"> Mobile </v-col>
            <v-col cols="8">
                <PhoneNumber :value="data.contact_mobile" />
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="4" class="font-weight-bold"> Email </v-col>
            <v-col cols="8">
                <a :href="'mailto:' + data.email">{{ data.email }}</a>
                <CopyToClipboard :value="data.email" small />
            </v-col>
        </v-row>
        <template v-slot:actions>
            <ButtonWithTooltip
                @click="$emit('edit')"
                text="Edit"
                icon="mdi-pencil"
                tooltip="Edit contact details"
                flat
            />
        </template>
    </BasicCard>
</template>

<script>
import BasicCard from '@/components/cards/templates/BasicCard';
import PhoneNumber from '../../pieces/PhoneNumber';
import CopyToClipboard from '../../pieces/CopyToClipboard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
export default {
    name: 'SignupContactCard',
    components: { ButtonWithTooltip, CopyToClipboard, PhoneNumber, BasicCard },
    props: {
        data: {
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped></style>
