<template>
    <NavMenu
        :hide="hide"
        v-if="$store.state.user.isLoggedIn"
        v-model="show"
        :main-menu="mainMenu"
        @menu="$emit('menu')"
    >
        <v-list class="py-0">
            <v-list-item class="py-2">
                <v-list-item-content>
                    <v-list-item-title class="subtitle-1 font-weight-bold mb-1"
                        >Porting Admin</v-list-item-title
                    >
                </v-list-item-content>
            </v-list-item>

            <v-divider />
            <v-list-item disabled>
                <v-list-item-content>
                    <v-list-item-title>Summary</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item disabled>
                <v-list-item-content>
                    <v-list-item-title>Port Requests</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link :to="{ name: 'AdminPortingList' }">
                <v-list-item-content>
                    <v-list-item-title>Ports</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link :to="{ name: 'AdminPortingCalendar' }">
                <v-list-item-content>
                    <v-list-item-title>Porting Calendar</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </NavMenu>
</template>

<script>
import NavMenu from '../templates/NavMenu';
export default {
    name: 'AdminPortingMenu',
    components: { NavMenu },
    data: () => ({
        show: false,
    }),
    props: {
        value: {
            default: false,
        },
        hide: {
            type: Boolean,
            default: false,
        },
        mainMenu: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        partnerData() {
            return null;
        },
    },
    watch: {
        show: function (val) {
            this.$emit('input', val);
        },
        value: function (val) {
            this.show = val;
        },
    },
    mounted() {
        this.show = this.value;
    },
};
</script>
