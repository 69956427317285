<template>
    <Toolbar
        :title="title"
        :back-to="userView ? { name: 'AdminUserList' } : null"
        refresh-button
    >
        <template v-if="userView" v-slot:extension>
            <v-tabs
                    v-if="userView"
                    fixed-tabs
                    show-arrows
                    center-active
            >
                <v-tab :to="{ name: 'AdminUserDetails' }">Summary</v-tab>
                <v-tab :to="{ name: 'AdminUserSessions' }">Sessions</v-tab>
                <v-tab :to="{ name: 'AdminUserHistory' }">History</v-tab>
            </v-tabs>
        </template>
    </Toolbar>
</template>

<script>
import Toolbar from '../templates/Toolbar';
import NewCustomerDialog from '../../dialogs/Customer/NewCustomerDialog';
import AddDIDDialog from '../../dialogs/DID/AddDIDDialog';
export default {
    name: 'AdminToolbar',
    components: { Toolbar },
    data: () => ({
    }),
    computed: {
        title() {
            let title = '';
            if (this.userView) title += 'User - ';
            title += this.$route.meta.title ? this.$route.meta.title : '';
            return title;
        },
        userView() {
            return this.$route.matched.some((m) => m.name === 'AdminUserParent');
        },
    },
};
</script>
