<template>
    <BasicCard title="Partner Statistics" :loading="loading || busy">
        <p class="display-1 text--primary">
            <FormattedNumber :value="data.active" hide-tooltip />
            <small> active partners</small>
        </p>
        <v-row dense>
            <v-col cols="6">
                <span class="font-weight-bold">Blocked</span>
            </v-col>
            <v-col cols="6">
                {{ data.hidden }}
            </v-col>
            <v-col cols="6">
                <span class="font-weight-bold">Total</span>
            </v-col>
            <v-col cols="6">
                {{ data.total }}
            </v-col>
        </v-row>

        <template v-slot:actions>
            <ButtonWithTooltip
                tooltip="Refresh data"
                icon="mdi-refresh"
                text="Refresh"
                flat
                @click="update"
            />
        </template>
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import FormattedNumber from '../../pieces/FormattedNumber';
import apiMixin from '../../../mixins/apiMixin';
export default {
    name: 'PartnerStatsCard',
    mixins: [apiMixin],
    components: { FormattedNumber, ButtonWithTooltip, BasicCard },
    props: ['loading'],
    data() {
        return {
            busy: false,
            data: [],
        };
    },
    methods: {
        async update() {
            this.data = [];
            this.busy = true;
            const response = await this.Api.send('post','partners/totals');
            if (response.success) {
                this.data = response.data;
            } else {
                this.data = [];
            }
            this.busy = false;
        },
    },
    created() {
        this.update();
    },
};
</script>
