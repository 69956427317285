<template>
  <v-container fluid>
    <v-card class="pa-2 mb-2">
      <v-row>
        <v-col cols="12" md="3">
          <DatePicker
            v-model="filter.start_date"
            label="Start date"
            show-clear
            hide-details
            dense
            :loading="busy"
            type="month"
          />
        </v-col>
        <v-col cols="12" md="3">
          <DatePicker
            v-model="filter.end_date"
            label="End date"
            show-clear
            hide-details
            dense
            :loading="busy"
            type="month"
          />
        </v-col>
        <v-col cols="6" md="2" lg="1">
          <v-btn
            outlined
            block
            height="40"
            :loading="busy"
            @click="csvTog = false; runReport()"
          >Run</v-btn>
        </v-col>
        <v-col cols="6" md="2" lg="1">
          <v-btn
            outlined
            block
            height="40"
            :loading="busy"
            @click="csvTog = true; runReport()"
          >CSV</v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          fixed-header
          height="100vh"
          :items="(responseData != null && responseData.records != null) ? responseData.records : []"
          :items-per-page="50"
          class="elevation-1"
          item-key="i_customer"
          :loading="busy"
          loading-text="Processing results... Please wait"
        >
          <template v-slot:[`item.call-revenue`]="{ item }">
            <Currency
              :value="item['call-revenue']"
            />
          </template>
          <template v-slot:[`item.subscription-revenue`]="{ item }">
            <Currency
              :value="item['subscription-revenue']"
            />
          </template>
          <template v-slot:[`item.did-revenue`]="{ item }">
            <Currency
              :value="item['did-revenue']"
            />
          </template>
          <template v-slot:[`item.total-revenue`]="{ item }">
            <Currency
              :value="item['total-revenue']"
            />
          </template>
          <template v-slot:[`item.graph`]="{ item }">
            <v-sparkline
              :value="item['graph']"
              width="400"
              height="70"
              padding="20"
              line-width="2"
              stroke-linecap="round"
              smooth
            >
            </v-sparkline>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import DatePicker from "../../../components/pieces/Forms/DatePicker.vue";
import Currency from "../../../components/pieces/Currency.vue";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import AdminReportMixin from "../../../mixins/AdminReportMixin";
export default {
  name: 'RevenueByServiceReport',
  mixins: [GlobalHelperMixin,AdminReportMixin],
  data: () => ({
    filter: {
      start_date:null,
      end_date:null,
      name_keyword:null,
    },
    busy: false,
    responseData:null,
    headers: [
      {
        text:'i_rep',
        align: 'start',
        sortable: true,
        value: 'i_rep',
      },
      {
        text: 'Name',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Calls',
        sortable: true,
        value: 'call-revenue',
      },
      {
        text: 'Subscriptions',
        sortable: true,
        value: 'subscription-revenue',
      },
      {
        text: 'DIDs',
        sortable: true,
        value: 'did-revenue',
      },
      {
        text: 'Total',
        sortable: true,
        value: 'total-revenue',
      },
      {
        text: 'Graph(monthy)',
        sortable: true,
        value: 'graph',
      },
    ],
  }),
  components: {
    Currency,
    DatePicker,
  },
  methods: {
    runReport( ){
      if(this.filter.start_date == null || this.filter.end_date == null){
        this.showGlobalErrorMessage('Invalid Start/End dates');
        return;
      }
      this.updateRouteWithFilters( );
      this.update( );
    },
    update( ){
      const params = {
        start_date:this.filter.start_date,
        end_date:this.filter.end_date,
      };
      this.updateReport(params,'admin/reporting/revenue-by-service-per-partner-report');
    },
    updateStartDate(data) {
      this.filter.start_date = data;
    },
    updateEndDate(data) {
      this.filter.end_date = data;
    },

    runReportOnNewPage( ){
      if(!this.$route.query.start_date || !this.$route.query.end_date){
        let end_date = new Date( );
        end_date = this.subtractMonth(end_date);
        end_date = this.subtractMonth(end_date);

        let start_date = new Date( );
        start_date = this.subtractMonth(start_date);
        start_date = this.subtractMonth(start_date);
        start_date = this.subtractMonth(start_date);

        this.updateStartDate(this.formatDate(start_date));
        this.updateEndDate(this.formatDate(end_date));
      }
      this.runReport();
    }
  },
  computed:{
    start_date( ){
      if(this.filter.start_date){
        return this.filter.start_date;
      }else{
        return null;
      }
    }
  },
};
</script>
