<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12">
                <HistoryCard
                    @update="updateFilter"
                    :filter="filter"
                    :user-id="parseInt(this.$route.params.userId)"
                    show-id
                    show-filters
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import filterMixin from '../../../mixins/filterMixin';
import HistoryCard from '../../../components/cards/shared/HistoryCard';
export default {
    name: 'UserHistory',
    mixins: [filterMixin],
    data: () => ({
        loading: true,
        filter: {
            page: 1,
            limit: 10,
            active: null,
            session_id: null
        },
    }),
    components: {
        HistoryCard
    },

};
</script>
