<template>
    <v-container fluid>
        <v-row dense>
          <v-col cols="4">
            <BasicCard :loading="isBusy || !reportData" no-skeleton :title="!reportData ? '' : new Date(this.reportData.started_at.replace(' ', 'T')).toLocaleString('default', { month: 'long' })+' Partner Commission Report'">
              <v-row v-if="reportData">
                <v-col cols="6">Start</v-col> <v-col cols="6">{{reportData.started_at}}</v-col>
                <v-col cols="6">End</v-col> <v-col cols="6">{{reportData.ended_at}}</v-col>
                <v-col cols="6">Validated</v-col> <v-col cols="6">{{reportData.validated_at}}</v-col>
                <v-col cols="6">Paid out</v-col> <v-col cols="6">{{reportData.completed_paid_out_at}}</v-col>
              </v-row>
              <template v-slot:actions>
                <ButtonWithTooltip
                    v-if="reportData && reportData.validated_at != null && reportData.completed_paid_out_at == null"
                    text="Payout"
                    flat
                    tooltip="Registered payment to PortaOne for all marked items."
                    icon="mdi-cash-usd"
                    @click="openPayoutDialog()"
                    color="green"
                />

                <ButtonWithTooltip
                    v-if="reportData && (reportData.validated_at == null || reportData.completed_paid_out_at != null)"
                    text="Payout"
                    flat
                    :tooltip="(reportData.validated_at == null ? 'Report must be validated' : 'Payout process completed.')"
                    icon="mdi-cash-usd"
                    disabled
                    color="green"
                />


                <ButtonWithTooltip
                    v-if="reportData && reportData.validated_at != null"
                    text="Tencia CSV Download"
                    flat
                    tooltip="Download CSV file for Tencia"
                    icon="mdi-download"
                    @click="downloadTenciaCSV( )"
                    color="primary"
                />

                <ButtonWithTooltip
                    v-if="reportData && reportData.validated_at == null"
                    text="Tencia CSV Download"
                    flat
                    tooltip="Report must first be validated in order to download the Tencia CSV file."
                    icon="mdi-download"
                    disabled
                    color="primary"
                />


                <ButtonWithTooltip
                    v-if="reportData && reportData.validated_at == null"
                    text="Validate"
                    flat
                    tooltip="Validate Report"
                    icon="mdi-check"
                    @click="validateReport( )"
                    color="success"
                />

                <ButtonWithTooltip
                    v-if="reportData && (reportData.validated_at != null && reportData.can_invalidate_msg == null)"
                    text="Invalidate"
                    flat
                    tooltip="Invalidate Report"
                    icon="mdi-cancel"
                    @click="invalidateReport( )"
                    color="warning"
                />

                <ButtonWithTooltip
                    v-if="reportData && (reportData.validated_at != null && reportData.can_invalidate_msg != null)"
                    text="Invalidate"
                    flat
                    :tooltip="reportData ? reportData.can_invalidate_msg : ''"
                    icon="mdi-cancel"
                    disabled
                    color="warning"
                />


                <ButtonWithTooltip
                    v-if="reportData && reportData.can_delete_msg == null"
                    text="Delete"
                    flat
                    tooltip="Delete Report"
                    icon="mdi-delete"
                    @click="deleteDialog = true"
                    color="error"
                />

                <ButtonWithTooltip  v-if="reportData && reportData.can_delete_msg != null"
                    text="Delete"
                    flat
                    disabled
                    :tooltip="reportData ? reportData.can_delete_msg : ''"
                    icon="mdi-delete"
                    color="error"
                />

              </template>
            </BasicCard>
          </v-col>
        </v-row>
      <v-row dense>
        <v-col cols="12">
          <PartnerCommissionReportPayoutList :reportData="reportData" :loading="isBusy" />
        </v-col>
      </v-row>

      <SimpleDialog v-model="deleteDialog" title="Delete Report?" @click="deleteReport( )" styledHeader>
        <v-row no-gutters>
          <v-col cols="12" class="text-center py-4">
        <v-icon size="120" color="error"
        >mdi-delete</v-icon>
            <p class="text-h6">Are you sure you wish to delete report?</p>
          </v-col>
        </v-row>
      </SimpleDialog>

      <SimpleDialog v-model="payoutConfirmDialog" title="Payout Report" @click="payout( )" styledHeader :yesDisabled="!formValid">
        <v-row no-gutters>
          <v-col cols="12" class="text-center py-4">
            <v-icon size="120" color="success"
            >mdi-cash-usd</v-icon>
            <p class="text-h6">Are you sure you wish to payout this report? </p>
            <p>Do so will record a payment in PortaOne for any checked items.</p>
            <v-form
                ref="payout-form"
                v-model="formValid"
                class="pa-8"
            >
              <v-text-field
                  v-model="payoutForm.transactionDate"
                  :counter="60"
                  label="Transaction Date"
                  required
                  :rules="payoutFormRules"
              ></v-text-field>

              <v-text-field
                  v-model="payoutForm.visibleComment"
                  :counter="60"
                  label="Visible Comment"
                  required
                  :rules="payoutFormRules"
              ></v-text-field>

            </v-form>
          </v-col>
        </v-row>
      </SimpleDialog>

      <SimpleDialog v-model="payoutFailDialog" title="Payout Report Failed" styledHeader :maxWidth="800" okOnly>
        <v-row no-gutters>
          <v-col cols="12" class="text-center py-4">
            <v-icon size="120" color="error">mdi-cash-usd</v-icon>
            <p class="text-h6">Report Payout failed.</p>
            <p v-if="payoutReturn"><strong>{{payoutReturn.successful_ids.length}} payout item have been paid out successfully.</strong></p>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" class="py-4">
            <p>Errors have been returned from the Payout process. Note that the payout process can be run again where it will trigger a payout
            on any payout items that are yet to be paid out. EG any successful payouts are ignored and any pending payout items are attempted to be paid out again.
            So the strategy for this would be to attend to all the issues in the errors below and run the payout process again.</p>
            <p>The following errors have been returned.</p>
            <ul v-if="payoutReturn">
              <li v-for="item in payoutReturn.failures" :key="item.id">
                {{item.formatted_message}}
              </li>
            </ul>

          </v-col>
        </v-row>
      </SimpleDialog>

    </v-container>
</template>

<script>
import BasicCard from '../../../components/cards/templates/BasicCard';
import apiMixin from '../../../mixins/apiMixin';
import refreshListenerMixin from "../../../mixins/refreshListenerMixin";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import PartnerCommissionReportPayoutList
  from "../../../components/cards/PartnerCommissionReport/PartnerCommisionReportPayoutList";
import ButtonWithTooltip from "../../../components/pieces/ButtonWithTooltip";
import SimpleDialog from "../../../components/dialogs/templates/SimpleDialog";
export default {
    name: 'PartnerCommissionReportView',
    mixins: [apiMixin,GlobalHelperMixin,refreshListenerMixin],
    components: {SimpleDialog, PartnerCommissionReportPayoutList, BasicCard,ButtonWithTooltip},
    data: () => ({
      formValid: false,
      loading: false,
      busy: false,
      reportData: null,
      id:null,
      deleteDialog: false,
      payoutConfirmDialog: false,
      payoutFailDialog: false,
      payoutReturn: null,
      linkBase: process.env.VUE_APP_API_BASE || '/api/',
      payoutForm:{
        transactionDate:null,
        visibleComment:null,
      },
      payoutFormRules: [
        v => !!v || 'Required'
      ]
    }),

    created: function( ){
      this.update( );
    },
    methods: {
      async update() {
        this.busy = true;

        const response = await this.Api.send('get','partner-commission-reports/'+this.$route.params.id);

        this.busy = false;
        if (response.success) {
          this.reportData = response.data
        } else {
          this.reportData = null;
        }
      },
      async deleteReport() {
        this.busy = true;

        const response = await this.Api.send('delete','partner-commission-reports/'+this.$route.params.id);

        this.busy = false;
        if (response.success) {
          this.showGlobalSuccessMessage('Report deleted successfully.');
          this.$router.push({ name: 'PartnerCommissionReports'});
        } else {
          this.showGlobalErrorMessage('Failed to delete report.');
        }
      },
      async validateReport() {
        this.busy = true;

        const response = await this.Api.send('put','partner-commission-reports/'+this.$route.params.id+'/validate');

        this.busy = false;
        if (response.success) {
          this.showGlobalSuccessMessage('Report validated successfully.');
          this.update( );
        } else {
          this.showGlobalErrorMessage('Failed to validate report.');
        }
      },
      async invalidateReport() {
        this.busy = true;

        const response = await this.Api.send('put','partner-commission-reports/'+this.$route.params.id+'/invalidate');

        this.busy = false;
        if (response.success) {
          this.showGlobalSuccessMessage('Report invalidated successfully.');
          this.update( );
        } else {
          this.showGlobalErrorMessage('Failed to invalidate report.');
        }
      },
      async payout() {
        this.busy = true;
        this.payoutReturn = null;
        this.payoutConfirmDialog = false;
        const response = await this.Api.send('put','partner-commission-reports/'+this.$route.params.id+'/payout',this.payoutForm);
        this.busy = false;

        if (!response.success) {
          this.showGlobalErrorMessage('Failed to payout report.');
        }else{
          this.payoutReturn = response.data;
          if(response.data.failures.length == 0){
            this.showGlobalSuccessMessage('Report paid out successfully. '+response.data.successful_ids.length+' Payout items paid successfully.');
            this.update( );
          }else{
            this.update( );
            this.payoutFailDialog = true;
          }
        }
      },
      openPayoutDialog( ){
        if(this.payoutForm.transactionDate == null){
          var currentDate = new Date();
          this.payoutForm.transactionDate = currentDate.getFullYear()+'-'+('0' + (currentDate.getMonth()+1)).slice(-2)+'-'+('0'+currentDate.getDate()).slice(-2)+' 00:00:00';
        }

        if(this.payoutForm.visibleComment == null){
          var reportDate = new Date(this.reportData.started_at.replace(' ', 'T'));
          this.payoutForm.visibleComment = reportDate.toLocaleString('default', { month: 'long' })+' '+reportDate.getFullYear()+' Commission Report';
        }

        this.payoutConfirmDialog = true;
      },
      downloadTenciaCSV( )
      {
        const url = this.linkBase + 'partner-commission-reports/' + this.$route.params.id + '/tencia-csv?session-token=' + this.$store.state.session.token;
        window.location.href = url;
      },
    },
    computed: {
      isBusy: function( ){
        return this.loading || this.busy;
      },
    },
};
</script>
