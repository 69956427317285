<template>
  <BasicCard :loading="isBusy || !reportData" no-skeleton :title="!reportData ? '' : new Date(this.reportData.started_at.replace(' ', 'T')).toLocaleString('default', { month: 'long' })+' Partner Commission Report Payouts'">
    <v-card-title>
      <v-row>
        <v-col>
      Payouts
        </v-col>
        <v-col>
      <v-text-field
          v-model="balanceLess"
          type="number"
          label="Balance less than"
      ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
              v-model="balanceGreater"
              type="number"
              label="Balance greater than"
          ></v-text-field>
        </v-col><v-col>
      <v-text-field
          v-model="keyword"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
      ></v-text-field>
      </v-col>
      </v-row>
    </v-card-title>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-data-table
              :headers="headers"
              :items="items"
              :items-per-page="5"
              :item-class="getRowClass"
              class="elevation-1"
              item-key="i_rep"
              selectable-key="isSelectable"
              v-model="selected"
              :show-select="true"
              @item-selected="itemSelected"
          >
            <template v-slot:top v-if="!isValidated"><a @click="payoutCheckAllOn( );">Check All*</a> / <a @click="payoutCheckAllOff( )">Uncheck All*</a></template>
            <template v-slot:[headerSlot]=""></template>
            <template v-slot:[`item.checked`]="{ item }">
              <v-simple-checkbox
                  v-model="item.checked"
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.data-table-select`]="{ isSelected, select,item }">
              <v-simple-checkbox v-if="!item.loading" :value="isSelected" @input="select($event)"></v-simple-checkbox>
              <v-progress-circular
                  v-if="item.loading"
                  size="20"
                  indeterminate
                  color="primary"
              ></v-progress-circular>
            </template>
            <template v-slot:[`item.exclusion_tag`]="{ item }">
              <v-chip color="orange" v-if="item.exclusion_tag == 'warning'">Warning</v-chip>
              <v-chip color="light-grey" v-else-if="item.exclusion_tag == 'ignore'">Ignore</v-chip>
              <v-chip color="light-grey" v-else-if="item.balance <= 0">Zero Balance</v-chip>
            </template>
          </v-data-table>
          <p>* Check all / uncheck all will adjust all checkboxes that are checkable. EG a balance of zero can never be checked.</p>
          <p>Note that payout items with the balance of zero do not have a checkbox. Payout itmes linked to a partner that has a 'ignore' exemption, do not have a checkbox.</p>
        </v-col>
      </v-row>
    </v-container>
  </BasicCard>
</template>

<script>
import apiMixin from "../../../mixins/apiMixin";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import BasicCard from "../templates/BasicCard";
export default {
  name: 'PartnerCommissionReportPayoutList',
  mixins: [apiMixin,GlobalHelperMixin],
  components: {
    BasicCard
  },
  data: () => ({
    headerSlot: 'header.data-table-select',
    keyword:null,
    balanceLess:null,
    balanceGreater:null,
    balance:null,
    items:[],
    selected: [],
    headers: [
      {
        text: 'Code/refnum',
        align: 'start',
        sortable: true,
        value: 'refnum',
      },
      {
        text: 'iRep',
        sortable: true,
        value: 'i_rep',
      },
      {
        text: 'Name',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Company',
        sortable: true,
        value: 'company',
      },
      {
        text: 'Email',
        sortable: true,
        value: 'email',
      },
      {
        text: 'Balance',
        sortable: true,
        value: 'balance',
      },
      {
        text: 'Exemption ',
        sortable: true,
        value: 'exclusion_tag',
      },
    ],
    busy: false,

  }),

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    reportData:{
      type: Object,
      default: null
    }
  },

  computed: {
    isBusy: function( ){
      return this.loading || this.busy;
    },
    isValidated: function( ){
      return this.reportData && this.reportData.validated_at != null;
    },
  },
  methods: {
    /**
     * Note that this search method is initiated via the watch when the data is loaded.
     * This method can be seen as managing the list of items.
     */
    search: function( ){
      if(!this.reportData){
        this.items = [];
        return;
      }
      let items = this.setupItems(this.reportData.payouts);
      if(this.balanceLess){
        items = items.filter(row => parseInt(row.balance) < parseInt(this.balanceLess));
      }
      if(this.balanceGreater){
        items = items.filter(row => parseInt(row.balance) > parseInt(this.balanceGreater));
      }

      if(this.keyword){
        items = items.filter(this.keywordSearchRow);
      }

      this.items = items;
    },
    /**
     * Here we setup the items and other relevant data before a search.
     * Such as setting which items are selected, selectable, etc.
     */
    setupItems: function(items){
      //we add in the isSelectable values to each
      let isSelectable = false;
      if(!this.isValidated){
        isSelectable = true;
      }

      items = items.map(row => {
        row.isSelectable = isSelectable;
        row.checked = row.checked_at != null;
        row.loading = false;
        if(row.exclusion_tag == 'ignore'){
          row.isSelectable = false;
        }else if(row.balance <= 0){
          row.isSelectable = false;
        }
        return row;
      });

      this.selected = [];
      for (var i = 0; i < items.length; i++) {
        if(items[i].checked_at != null){
          this.selected.push(items[i]);
        }
      }

      return items;
    },

    itemSelected: function(item){
      item.item.loading = true;
      if(item.value){
        this.payoutCheckOn(item.item);
      }else{
        this.payoutCheckOff(item.item);
      }

    },
    async payoutCheckOn(item) {
      const response = await this.Api.send('put','partner-commission-reports/'+this.reportData.id+'/payouts/'+item.id+'/check');
      this.busy = false;
      if (!response.success) {
        this.showGlobalErrorMessage('Failed to select payout item.');
      }
      this.items = this.items.map(row => {
        if(row.id == item.id){
          row.loading = false
        }
        return row;
      });
    },
    async payoutCheckOff(item) {
      const response = await this.Api.send('put','partner-commission-reports/'+this.reportData.id+'/payouts/'+item.id+'/uncheck');
      this.busy = false;
      if (!response.success) {
        this.showGlobalErrorMessage('Failed to de-select payout item.');
      }
      this.items = this.items.map(row => {
        if(row.id == item.id){
          row.loading = false
        }
        return row;
      });
    },
    async payoutCheckAllOn( ) {
      this.busy = true;
      const response = await this.Api.send('put','partner-commission-reports/'+this.reportData.id+'/payouts/check-all');
      this.busy = false;
      if (!response.success) {
        this.showGlobalErrorMessage('Failed to check all payout items.');
      }
      document.dispatchEvent(new CustomEvent('refreshRequested'));
    },
    async payoutCheckAllOff( ) {
      this.busy = true;
      const response = await this.Api.send('put','partner-commission-reports/'+this.reportData.id+'/payouts/uncheck-all');
      this.busy = false;
      if (!response.success) {
        this.showGlobalErrorMessage('Failed to uncheck all payout items.');
      }
      document.dispatchEvent(new CustomEvent('refreshRequested'));
    },

    keywordSearchRow: function(row) {
      if(row.refnum != null && row.refnum.toLowerCase().search(this.keyword.toLowerCase()) != -1){
        return true;
      }
      if(row.i_rep == this.keyword){
        return true;
      }
      if(row.name != null && row.name.toLowerCase().search(this.keyword.toLowerCase()) != -1){
        return true;
      }
      if(row.email != null && row.email.toLowerCase().search(this.keyword.toLowerCase()) != -1){
        return true;
      }
      if(row.company != null && row.company.toLowerCase().search(this.keyword.toLowerCase()) != -1){
        return true;
      }

      if(row.balance == this.keyword){
        return true;
      }
      return false;
    },
    getRowClass: function(row){
      if(row.porta_one_paid_out_at != null){
        return 'row-success';
      }else if(row.exclusion_tag == 'ignore' ){
        return 'row-ignore';
      }else if(row.exclusion_tag == 'warning'){
        return 'row-warning';
      }else if(row.balance <= 0){
        return 'row-ignore';
      }
    }
  },
  watch: {
    reportData(value) {
      if(value != null){
        this.search( );
      }
    },
    balanceLess(value) {
      this.search( );
    },
    balanceGreater(value) {
      this.search( );
    },
    keyword: {
      handler: function(val){
        this.search( );
      }
    }
  },

};
</script>

<style scoped>
::v-deep .row-ignore{
  color:darkgray;
}

::v-deep .row-warning{
  /*background-color: peachpuff;*/
  color: #fc6628;
}

::v-deep .row-success{
  color: green;
  font-weight:bold;
}

</style>
