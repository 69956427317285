<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-row class="px-4">
            <v-col cols="12" md="3">
              <DatePicker
                  label="Period Start After"
                  v-model="searchFilters.start"
                  clearable
                  dense
                  :loading="isBusy"
              />
            </v-col>
            <v-col cols="12" md="3">
              <DatePicker
                  label="Period End Before"
                  v-model="searchFilters.end"
                  clearable
                  dense
                  :loading="isBusy"
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                  hide-details
                  outlined
                  dense
                  label="Status"
                  v-model="searchFilters.status"
                  :disabled="isBusy"
                  :items="statusOptions"
                  item-text="option"
                  item-value="value"
              />
            </v-col>
            <v-col cols="12" md="2">
              <v-btn
                  class="secondary"
                  block
                  outlined
                  @click="updateSearch"
                  :loading="isBusy"
              >Update</v-btn>
            </v-col>
            <v-col cols="12" md="1">

              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn  v-on="on"
                      class="secondary"
                      block
                      outlined
                      @click="create"
                      :loading="isBusy"
                  >+</v-btn>
                </template>
                <span>Create next report</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <PaginationCardV2
            :page="searchFilters.page"
            :totalRecords="listData.total_records"
            :totalPages="listData.total_pages"
            :limit="searchFilters.limit"
            :currentPageTotal="(listData.results) ? listData.results.length : 0"
            :loading="isBusy"
            @updatedPagination="updatedPagination"
            :no-skeleton="false"
        >
          <v-simple-table>
            <thead>
            <tr>
              <th class="text-center">Id</th>
              <th class="text-center">Period</th>
              <th class="text-center">Validated</th>
              <th class="text-center">Paid Out</th>
              <th class="text-center"></th>
            </tr>
            </thead>

            <v-slide-x-transition tag="tbody" hide-on-leave group>
              <tr v-if="listData.results && listData.results.length === 0" key="nodata">
                <td colspan="100%" class="text-center">
                  No results to show.
                </td>
              </tr>
              <tr
                  v-for="item in listData.results"
                  :key="item.id"
              >
                <td class="text-center">
                  <router-link
                      :to="{
                                        name: 'PartnerCommissionReportView',
                                        params: {
                                            id: item.id,
                                        },
                                    }"
                      class="font-weight-medium"
                  >{{ item.id }}</router-link>
                </td>
                <td class="text-center">
                  {{item.started_at}} - {{item.ended_at}}
                </td>
                <td class="text-center">
                  {{item.validated_at}}
                </td>
                <td class="text-center">
                  {{item.completed_paid_out_at}}
                </td>
                <td class="text-center">
                  <router-link
                      :to="{
                                        name: 'PartnerCommissionReportView',
                                        params: {
                                            id: item.id,
                                        },
                                    }"
                      v-slot="{href, navigate}"
                  >
                    <v-btn
                        :href="href" @click="navigate"
                        block
                        outlined
                        :loading="isBusy"
                    >Open</v-btn>
                  </router-link>
                </td>
              </tr>
            </v-slide-x-transition>
          </v-simple-table>
        </PaginationCardV2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PaginationCardV2 from "../../../components/cards/templates/PaginationCardV2";
import apiMixin from "../../../mixins/apiMixin";
import DatePicker from "../../../components/pieces/Forms/DatePicker";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import refreshListenerMixin from "../../../mixins/refreshListenerMixin";
export default {
  name: 'PartnerCommissionReportList',
  mixins: [apiMixin,GlobalHelperMixin,refreshListenerMixin],
  components: {
    PaginationCardV2,DatePicker
  },
  data: () => ({
    searchFilters: {
      page:1,
      limit: 10,
    },
    busy: false,
    listData: {
      total_records:0,
      total_pages:0
    },
    paginationData:[],
    statusOptions: [
      { option: 'Any', value: 'any' },
      { option: 'Validated', value: 'validated' },
      { option: 'Paid Out', value: 'paid-out' },
    ],
  }),
  computed: {
    isBusy: function( ){
      return this.loading || this.busy;
    },
  },
  methods: {
    openReport(id){
      this.$router.push({
        name: 'PartnerCommissionReportView',
        params: {
          id: id,
        },
      })
    },
    updatedPagination(data){
      this.searchFilters.page = data.page;
      this.searchFilters.limit = data.limit;
      this.update( );
    },
    updateSearch(data){
      this.searchFilters.page = 1;
      this.update( );
    },

    async create() {
      this.busy = true;
      this.Api.setHttpObject({timeout:60000});
      const response = await this.Api.send('post','partner-commission-reports');

      this.busy = false;
      if (response.success) {
        this.showGlobalSuccessMessage('New report created successfully');
        //document.dispatchEvent(new CustomEvent('refreshRequested'));
        this.update( );
      } else {
        this.showGlobalErrorMessage('Failed to create new report.');
      }
      this.Api.setHttpObject({timeout:20000});
    },

    async update() {
      this.busy = true;
      let params = this.searchFilters;

      for (const [key, value] of Object.entries(params)) {
        if(value == null || value == undefined){
          delete params[key];
        }
      }

      let queryString = Object.keys(params).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
      }).join('&');

      const response = await this.Api.send('get','partner-commission-reports?'+queryString, params);

      this.busy = false;
      if (response.success) {
        this.listData = response.data;
        this.paginationData = {
          data: response.data.results,
          count: response.data.total_records,
          pages: response.data.total_pages
        };
      } else {
        this.listData = [];
      }
    },
  },
};
</script>

<style scoped></style>
